import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { fetchAPI } from '../../../../utils/fetch';
import { config, urlPaths } from '../../../../utils/config';
import { useNavigate } from 'react-router-dom';

const ListModalForPeople = ({ setShowModal, action, data, setData }) => {

  const [keywordData, setKeywordData] = useState({ name: "" });
  const [submitLoading, setsubmitLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setKeywordData({ name: data?.list_name });
    }
  }, [data])

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setsubmitLoading(true);
      let response;
      if (data) {
        // add api call for edit people list name
      }
      else {
        response = await fetchAPI.post(
          `${config.urlPlatform}/api/v2/unipile/linkedin/people/add`,
          { withCredentials: true },
          { list_name: keywordData.name, people: [] }
        );
      }

      if (response?.data?.success || action === "Edit") {
        if (action === "Edit") {
          setData({ ...data, list_name: keywordData.name });
          setShowModal(false);
        }
        else {
          if (response?.data?.data?.id) {
            navigate(urlPaths.peopleSingle.replace(":id", response?.data?.data?.id))
            // setLists((prevLists) => [{ id: response?.data?.data?.id, list_name: keywordData.name }, ...prevLists]);
          }
          setShowModal(false);
        }
      }
    } catch (error) {
      console.log("Failed to submit data", error);
    } finally {
      setsubmitLoading(false);
    }
  }

  return (
    <ModalWrapper>
      <ModalInner onSubmit={handleSubmit}>
        <div>
          <h3 style={{ margin: "0.5rem" }}>{action} your list</h3>
          <CloseButton onClick={() => setShowModal(false)}>&#10005;</CloseButton>
        </div>
        <input value={keywordData.name} onChange={(e) => setKeywordData({ ...keywordData, name: e.target.value })} placeholder='List Name' required />
        <button style={{ cursor: "pointer", opacity: submitLoading ? 0.5 : 1 }} type='submit' disabled={submitLoading} >{submitLoading ? "Submitting.." : action === "Edit" ? "Done" : "Create list"}</button>
        {action === "Edit" && <div style={{ textAlign: "center", fontSize: "12px" }}>Click on update list to save changes</div>}
      </ModalInner>
    </ModalWrapper>
  );
};

export default ListModalForPeople;

const ModalWrapper = styled.div`
  position: fixed;
  background: #00000038;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalInner = styled.form`
    padding: 20px;
    background: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 100%;
    min-height: 181px;
    gap: 4px;
    border-radius: 10px;
    position: relative;

  > input {
    height: 47px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border-color: #0000002e;

    &:focus-visible {
      outline: 0px;
    }
  }

  > button {
    background: #0050b2;
    padding: 15px;
    border: none;
    border-radius: 5px;
    color: #ffff;
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
  }

  > h3 {
    margin: 0px;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;

  &:hover {
    color: #0050b2; /* Change color on hover */
  }
`;
