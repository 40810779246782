import React, { useEffect, useRef, useState } from 'react'
import PostTextComponent from './PostsTextComponent';
import Like from "../../../assets/like.svg";
import Open from "../../../assets/open.svg";
import styled from 'styled-components';
import { formatPostDate, timeAgo } from '../../../utils/helpers';
import { config } from '../../../utils/config';
import { fetchAPI } from '../../../utils/fetch';
import ReactionWidget from './ReactionWidget';
import AttachmentCarousel from './AttachmentCrousel';
import socialIcon from "../../../assets/social-icon.png";
import CommentPopover from './Ai/Popup';
import pastIcon from "../../../assets/paste.png";
import PollComponent from './PollComponent';
import RepostedPost from './RepostedPost';
import PostCommentsPopup from './PostCommentsPopup';
import MentionComment from './MentionComment';


const PostS = ({ data, reactions, isCommentPopUpOpen, setIsCommentPopUpOpen, setOpenCommentPostId, commentsLeft, setCommentsLeft, openCommentPostId, getRemainingComments, customPrompt, userData, pasteButton, setPasteButton }) => {

  function getFormatedText(text) {
    if (typeof text !== 'string') {
      return '';
    }
    return text.replace(/\n/g, '<br>');
  }

  const [comment, setComment] = useState("");
  const [commentLoading, setCommentLoading] = useState(false);
  const [commentAddedSuccess, setCommentAddedSuccess] = useState(null);
  const [reactionWidgetOpen, setReactionWidgetOpen] = useState(false);
  const [mentions, setMentions] = useState([]);

  const textareaRef = useRef(null);
  const MAX_COMMENT_LENGTH = 1250;
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "35px";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [comment]);

  const [users, setUsers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(null);
  const [userSearchLoading, setuserSearchLoading] = useState(false);
  const dropdownRef = useRef(null);

  const fetchUsers = async (query) => {
    try {
      setuserSearchLoading(true);
      let url = `${config.urlPlatform}/api/v2/unipile/linkedin/search`;
      const body = {
        "category": "people"
      }
      if (query.trim() !== "") {
        body.keywords = query;
      }
      const response = await fetchAPI.post(
        url,
        { withCredentials: true },
        body
      );

      if (response?.data?.success) {
        setUsers(response?.data?.data?.items);
      }
    } catch (error) {
      console.log("Error fetching data");
    } finally {
      setuserSearchLoading(false);
    }
  };

  const handleCommentChange = (e) => {
    const value = e.target.value;
    if (typeof value === "string" && value.length <= MAX_COMMENT_LENGTH) {
      setComment(value);

      const lastWord = value.split(/\s+/).pop();
      if (lastWord.startsWith("@")) {
        setShowDropdown(true);
        fetchUsers(lastWord.substring(1));
        setCursorPosition(e.target.selectionStart);
      } else {
        setShowDropdown(false);
      }
    }
  };

  const handleSelectUser = (user) => {
    const beforeCursor = comment.slice(0, cursorPosition - 1);
    const afterCursor = comment.slice(cursorPosition);
    const updatedComment = `${beforeCursor}{{${mentions.length}}} ${afterCursor}`;

    setComment(updatedComment);
    setMentions([...mentions, { name: user.name, profile_id: user.profile_id }]);
    setShowDropdown(false);
  };

  const extractCommentData = () => {
    if (mentions.length === 0) {
      const output = { comment, mentions: [] };
      // console.log('Extracted Data:', output);
      return output;
    }
    const tokensPattern = mentions
      .map((m) => m.token.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
      .join('|');
    const regex = new RegExp(`(${tokensPattern})(\\s?)`, 'g');
    let index = 0;
    const finalMentions = [];
    const commentOutput = comment.replace(regex, (match, p1, p2) => {
      const mData = mentions.find((m) => m.token === p1);
      if (mData) {
        finalMentions.push({
          name: mData.name || mData.username,
          profile_id: mData.id,
        });
      }
      const placeholder = `{{${index++}}}`;
      return placeholder + (p2 || '');
    });
    const output = { comment: commentOutput, mentions: finalMentions };
    // console.log('Extracted Data:', output);
    return output;
  };

  const handleCommentSubmit = async () => {
    if (!comment || comment.trim() === "" || !data?.social_id) {
      return;
    }
    try {
      setCommentLoading(true);
      const commentData = extractCommentData();

      const body = {
        social_id: data?.social_id,
        comment: commentData?.comment ?? ""
      }
      if(Array.isArray(commentData?.mentions) && commentData?.mentions.length > 0) {
        body.mentions = commentData.mentions
      }
      const response = await fetchAPI.post(
        `${config.urlPlatform}/api/v2/unipile/linkedin/sendPostComment`,
        { withCredentials: true },
        body
      );

      if (response?.data?.success) {
        setComment("");
        setCommentAddedSuccess("Comment Added");
        const timer = setTimeout(() => {
          setCommentAddedSuccess(null);
        }, 1000)

        return () => clearTimeout(timer);
      }
    } catch (error) {
      console.log("Failed to add comment");
    } finally {
      setCommentLoading(false);
    }
  };

  const [reactionStatus, setReactionStatus] = useState(null);

  const handleLikePost = async (reaction) => {
    if (!reaction || !data?.social_id) {
      return;
    }
    try {
      const body = {
        social_id: data?.social_id,
        reaction
      }
      const response = await fetchAPI.post(
        `${config.urlPlatform}/api/v2/unipile/linkedin/sendPostReaction`,
        { withCredentials: true },
        body
      );

      if (!response?.data?.success) {
        setReactionStatus(null);
      }
    } catch (error) {
      console.log("Failed to react");
      setReactionStatus(null);
    }
  };

  const handlePasteClick = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setComment(text);
    } catch (error) {

    }
  };
  const [commentPopup, setcommentPopup] = useState(false);

  return (
    <Post style={{ position: "relative" }}>
      <PostHeader style={{ display: "flex", paddingLeft: "1rem", alignItems: "center" }}>

        <div style={{ width: "100%" }}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <div onClick={() => {
              if (data?.author?.public_identifier) {
                const url = "https://www.linkedin.com/in/" + data.author.public_identifier;
                window.open(url, "_blank");
              }
            }} style={{ cursor: data?.author?.public_identifier ? "pointer" : "default", fontSize: "1.11rem", fontWeight: "bold", color: "#0a66c2" }}>
              {data?.author?.name}
            </div>
            <div style={{ marginRight: "1.5rem", cursor: "pointer" }}>
              <div onClick={!isCommentPopUpOpen ? setIsCommentPopUpOpen : () => setOpenCommentPostId(null)}>
                <img style={{ height: "30px" }} src={socialIcon} alt="open icon" />
              </div>
              {
                isCommentPopUpOpen &&
                <CommentPopover setOpenCommentPostId={setOpenCommentPostId} commentsLeft={commentsLeft} setCommentsLeft={setCommentsLeft} openCommentPostId={openCommentPostId} postData={data} getRemainingComments={getRemainingComments} customPrompt={customPrompt} userData={userData} setPasteButton={setPasteButton} handleCommentChange={handleCommentChange} />
              }
            </div>
          </div>

          <div style={{ color: "gray", fontSize: "0.9rem" }}>
            <span style={{ overflow: "hidden", display: "-webkit-box", "WebkitBoxOrient": "vertical", "WebkitLineClamp": "1" }}>{data?.author?.headline}</span>
            <span>{timeAgo(data?.parsed_datetime)}</span>
            <span>  •  </span>
            <span>{formatPostDate(data?.parsed_datetime)}</span>
          </div>
        </div>
      </PostHeader>
      <PostBody>
        <PostTextComponent text={getFormatedText(data?.text)} />
        <PostMedia>
          <AttachmentCarousel attachments={data?.attachments} />
        </PostMedia>
        {data?.poll && <PollComponent poll={data?.poll} />}
        {data?.is_repost && <RepostedPost repost={data?.repost_content} />}
      </PostBody>
      <PostFooter>
        <div style={{ margin: "1rem 0", opacity: "0.4", display: "flex", justifyContent: "space-between", alignItems: "center", fontSize: "14px", padding: "0 1rem" }}>
          <LikeCounter>
            <div>
              <img style={{ height: "14px" }} src={Like} alt="like icon" />
              <span>&nbsp;{data?.reaction_counter}</span>
            </div>
          </LikeCounter>
          <CommentCounter>
            <span>{data?.comment_counter}&nbsp;</span>
            <span>comments</span>
          </CommentCounter>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", borderTop: "1px solid rgba(128, 128, 128, 0.5)", borderBottom: "1px solid rgba(128, 128, 128, 0.5)", padding: "1rem 2rem", margin: "1rem 0", fontSize: "14px" }}>
          <div style={{ position: "relative" }}>
            <LikeIcon
              style={{
                cursor: "pointer",
                padding: "5px",
                borderRadius: "5px"
              }}
              onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.1)")}
              onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
              onClick={() => {
                setReactionWidgetOpen(!reactionWidgetOpen);
              }}
            >
              <img style={{ height: "15px" }} src={reactionStatus ? reactions.find((reaction) => reaction.name === reactionStatus)?.image : Like} alt="like icon" />
              <span style={reactionStatus ? { color: "#0a66c2" } : { color: "black" }}>&nbsp;{reactionStatus ? reactionStatus.substring(0, 1).toUpperCase() + reactionStatus.substring(1) : "Like"}</span>
            </LikeIcon>
            {reactionWidgetOpen && <ReactionWidget handleLikePost={handleLikePost} setReactionWidgetOpen={setReactionWidgetOpen} reactions={reactions} selectedReaction={reactionStatus} setReactionStatus={setReactionStatus} />}
          </div>

          <OpenIcon
            style={{
              cursor: "pointer",
              padding: "5px",
              borderRadius: "5px",
            }}
            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.1)")}
            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
            onClick={() => { window.open(data?.share_url, "_blank") }}
          >
            <img style={{ height: "15px" }} src={Open} alt="open icon" />
            <span>&nbsp;Open</span>
          </OpenIcon>
        </div>
        <form onSubmit={(e) => {
          e.preventDefault();
          handleCommentSubmit();
        }}>
          {data?.permissions?.can_post_comments ?
            <MentionComment comment={comment} setComment={setComment} mentions={mentions} setMentions={setMentions} />
            // <LoginUserImage>
            //   {/* {
            //     pasteButton &&
            //     <div className='pasteWrapper' onClick={handlePasteClick} style={{ cursor: "pointer" }}>
            //       <img src={pastIcon} />
            //       Paste
            //     </div>
            //   } */}
            //   <textarea
            //     ref={textareaRef}
            //     value={comment}
            //     onChange={handleCommentChange}
            //     placeholder="Add a comment"
            //     // className={`${pasteButton ? "inputIcon" : ""}`}
            //     style={{
            //       width: "100%",
            //       padding: "0.5rem 1rem",
            //       resize: "none",
            //       overflowY: "hidden",
            //       whiteSpace: "pre-wrap",
            //       wordBreak: "break-word",
            //       boxSizing: "border-box",
            //       lineHeight: "15px",
            //     }}
            //   ></textarea>
            //   {showDropdown && (
            //     <Dropdown ref={dropdownRef} style={{ top: `${textareaRef.current?.offsetHeight + 10}px` }}>
            //       {userSearchLoading ? (
            //         <li>Loading...</li>
            //       ) : (
            //         users.map((user) => (
            //           <li key={user.profile_id} onClick={() => handleSelectUser(user)}>
            //             {user.name}
            //           </li>
            //         ))
            //       )}
            //     </Dropdown>
            //   )}
            // </LoginUserImage> 
            :
            <LoginUserImage>
              <input type='text' value={""} disabled placeholder='You cannot comment on this post.' style={{ width: "97%", padding: "0.5rem 0", paddingLeft: "1rem" }}></input>
            </LoginUserImage>
          }
          <CommentInputBox>
            <div onClick={() => setcommentPopup(true)} style={{ display: "inline-flex", backgroundColor: "#0a66c2", color: "white", borderRadius: "5px", justifyContent: "space-between", alignItems: "center", margin: "1rem 0", padding: "0.7rem 0.6rem", cursor: "pointer", border: "none", minWidth: "50px", fontSize: "12px" }}>
              See Comments
            </div>

            {commentPopup && <PostCommentsPopup setcommentPopup={setcommentPopup} social_id={data?.social_id} totalComments={data?.comment_counter} />}

            <button type='submit' disabled={commentLoading || comment.trim() === ""} style={{ display: "inline-flex", backgroundColor: "#0a66c2", color: "white", borderRadius: "5px", justifyContent: "space-between", alignItems: "center", margin: "1rem 0", padding: "0.7rem 0.6rem", cursor: (commentLoading || comment.trim() === "") ? "default" : "pointer", opacity: (comment.trim() === "" || commentLoading) ? 0.6 : 1, border: "none", minWidth: "50px" }}>
              {commentLoading ? "Adding comment" : commentAddedSuccess ? commentAddedSuccess : "Reply"}
            </button>
          </CommentInputBox>
        </form>
      </PostFooter>
    </Post>
  )
}

const Dropdown = styled.ul`
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  padding: 5px;
  list-style: none;
  width: 200px;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  li {
    padding: 5px;
    cursor: pointer;
  }

  li:hover {
    background: #f0f0f0;
  }
`;

const CommentInputBox = styled.div`
  display: flex ;
  justify-content: space-between;
`;
const LoginUserImage = styled.div`
  position:relative;

  .inputIcon{
    box-sizing: border-box;
    width: 100% !important;
    padding-left: 75px !important;
    
  }
  .inputiconwrapper{
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0;
    width: 32px;
    opacity: 0;
    cursor: pointer;
  }
    .pasteWrapper {
    position: absolute;
    left: 5px;
    top: 3px;
    bottom: unset;
    margin: auto;
    height: max-content;
    font-size: 12px;
    color: rgb(108, 102, 133);
    font-weight: 400;
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 3px;

   
       > img{
       width: 20px;}
}
    
`;
const OpenIcon = styled.div`
  display: flex;
  align-items: center;
`;
const LikeIcon = styled.div`
 
`;
const CommentCounter = styled.div`

`;
const LikeCounter = styled.div`
`;
const PostFooter = styled.div`
  margin: 0 1rem;
`;
const PostMedia = styled.div`
  margin-top: 1rem;
`;

const PostBody = styled.div`
  margin: 0 1rem;
`;
const PostHeader = styled.div`
  height: 5rem;
`;

const Post = styled.div`
  margin: 1.5rem 0;
  padding: 1rem;
  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
`;

export default PostS
