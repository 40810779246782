import React from "react";
import styled from "styled-components";

const RadioContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: black;
  >span{
    font-size:16px;
  }
`;

const StyledInput = styled.input`
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #5BB7E7;
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;
  position: relative;

  &:checked::before {
    content: "";
    width: 8px;
    height: 8px;
    background-color: #5BB7E7;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const RadioLabel = styled.span`
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0%;

  @media only screen and (max-width:767px){
     font-size: 16px;
  }
`;

const RadioSubLabel = styled.span`
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0%;
  margin-left: 5px;
   @media only screen and (max-width:767px){
     font-size: 16px;
  }
`;

const OnboardingRadio = ({ options, name, selectedOption, onChange }) => {
  return (
    <RadioContainer>
      {options.map((option, index) => (
        <Label key={index}>
          <StyledInput
            type="radio"
            name={name}
            value={option.value}
            checked={selectedOption === option.value}
            onChange={() => onChange(option.value)}
          />
          <RadioLabel>{option.label}</RadioLabel>{" "}
          {option?.sublabel && <RadioSubLabel>{option.sublabel}</RadioSubLabel>}
        </Label>
      ))}
    </RadioContainer>
  );
};

export default OnboardingRadio;
