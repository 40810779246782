import React, { useEffect, useState } from 'react';
import { OnboardingButton } from '../../../components/onboarding/OnboardingButton';
import "../Css/custom-new.css";
import OnboardingInput from '../../../components/onboarding/OnboardingInput';
import OnboardingMultiOptions from '../../../components/onboarding/OnboardingMultiOptions';
import { fetchAPI } from '../../../utils/fetch';
import { config } from '../../../utils/config';

const Step5 = ({ setProgress, setStep, onboardingData, setOnboardingData, stepInfo, setStepInfo }) => {
  const [inputValue, setInputValue] = useState("");
  const [inputValue1, setInputValue1] = useState("");
  const [selected, setSelected] = useState(onboardingData?.pharases?.favouritePharases || []);
  const [selected1, setSelected1] = useState(onboardingData?.pharases?.bannedPharases || []);
  const [isChanged, setIsChanged] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setProgress(58);
  }, []);

  const handleKeyDown = (event, type) => {
    if (event.key === 'Enter' && event.target.value.trim() !== "") {
      event.preventDefault();
      const newPhrase = event.target.value.trim();
      setIsChanged(true);

      if (type === "favouritePharases") {
        if (!selected.includes(newPhrase)) {
          const updatedFav = [...selected, newPhrase];
          setSelected(updatedFav);
          setOnboardingData(prev => ({
            ...prev,
            pharases: { ...prev.pharases, favouritePharases: updatedFav }
          }));
        }
        setInputValue("");
      } else {
        if (!selected1.includes(newPhrase)) {
          const updatedBanned = [...selected1, newPhrase];
          setSelected1(updatedBanned);
          setOnboardingData(prev => ({
            ...prev,
            pharases: { ...prev.pharases, bannedPharases: updatedBanned }
          }));
        }
        setInputValue1("");
      }
    }
  };

  const handleRemovePhrase = (type, updatedList) => {
    setIsChanged(true);
    if (type === "favouritePharases") {
      setSelected(updatedList);
      setOnboardingData(prev => ({
        ...prev,
        pharases: { ...prev.pharases, favouritePharases: updatedList }
      }));
    } else {
      setSelected1(updatedList);
      setOnboardingData(prev => ({
        ...prev,
        pharases: { ...prev.pharases, bannedPharases: updatedList }
      }));
    }
  };

  const handleNext = async () => {
    if (!selected.length && !selected1.length) {
      setError("Please add at least one phrase of each");
      return;
    }

    setError(null);
    setStep(6);

    if (!isChanged) return;

    try {
      setStepInfo({ step: 5, message: "Saving your preferences..." });

      const response = await fetchAPI.post(
        `${config.urlPlatform}/api/v2/onboarding/add`,
        { withCredentials: true },
        {
          step: 5,
          field: "pharases",
          value: { favouritePharases: selected, bannedPharases: selected1 }
        }
      );

      if (response?.data?.success) {
        setIsChanged(false);
      } else {
        setError(response?.data?.message);
        setStep(5);
      }
    } catch (error) {
      setStep(5);
      setError("Failed to update. Please try again.");
    } finally {
      setStepInfo({ step: -1, message: null });
    }
  };

  return (
    <div className='onboarding-body-global' style={{ gap: "20px", marginTop: "55px" }}>
      <div className='step0-onboarding-heading'>
        What Phrases Do You Like (or Hate)?
      </div>
      <div className='step0-onboarding-subheading'>
        AI can include your favorite phrases and avoid the ones you never use.
      </div>
      <div style={{ width: "90%", display: "flex", flexDirection: "column", gap: "20px" }} class="onboarding-Wrapper-container">
        <div style={{display: "flex", flexDirection: "column", gap: "20px" }} class="onboarding-Inner-container">
        <OnboardingInput
          placeholder="Write the most common words + Hit “Enter”"
          value={inputValue}
          onChange={setInputValue}
          onKeyDown={(e) => handleKeyDown(e, "favouritePharases")}
          title="Favorite Phrases"
        />
        <OnboardingMultiOptions options={selected} onRemove={(updatedList) => handleRemovePhrase("favouritePharases", updatedList)} />
        </div>
        <div style={{display: "flex", flexDirection: "column", gap: "20px" }} class="onboarding-Inner-container">
        <OnboardingInput
          placeholder="Write the words that the AI shouldn’t use + Hit “Enter”"
          value={inputValue1}
          onChange={setInputValue1}
          onKeyDown={(e) => handleKeyDown(e, "bannedPharases")}
          title="Banned Phrases"
        />
        <OnboardingMultiOptions options={selected1} onRemove={(updatedList) => handleRemovePhrase("bannedPharases", updatedList)} />

        </div>
        {stepInfo.step === 5 && stepInfo.message && <div className="info-message-onboarding">{stepInfo.message}</div>}
        {error && <div className="error-message-onboarding" >{error}</div>}
        <div className='onboarding-below-buttons' style={{ marginTop: "22px", marginBottom: "27px" }}>
          <OnboardingButton style={{ width: "177px", height: "55px", boxSizing: "border-box" }} onClick={() => setStep(4)} title="Back" />
          <OnboardingButton style={{ width: "177px", height: "55px", boxSizing: "border-box" }} onClick={handleNext} title="Next" variant='hollow' />
        </div>
      
      </div>
    </div>
  );
};

export default Step5;
