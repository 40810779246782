import React from 'react';
import styled from 'styled-components';
import { OnboardingButton } from '../../../components/onboarding/OnboardingButton';
import SocialIcon from "../../../assets/social-icon.png";
import { useNavigate } from 'react-router-dom';

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
   @media only screen and (max-width:1200px){
     z-index: 99;
     padding: 30px 0;
   }
`;

const Modal = styled.div`
  background: white;
  border-radius: 16px;
  padding: 24px;
  max-width: 1170px;
  height: 500px;
  box-shadow: 10px 10px 0px 0px #5BB7E7;

  @media only screen and (max-width:1200px){
    height: auto;
    box-sizing: border-box;
    width: 94%;
    height: 100% !important;
    overflow-y: auto;
  }
  @media only screen and (max-width:767px){
    width: 89%;
    margin-left: -10px;
    padding:15px;
  }
   

`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
`;

const Title = styled.h2`
  font-family: "Roboto Slab";
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
  text-indent: 62px;
  margin-bottom: 0;

  @media only screen and (max-width:1200px){
     font-size: 25px;
     line-height: 35px;
     text-align: start;
     text-indent: 0;
  }
`;

const CloseButton = styled.button`
  color: black;
  font-size: 60px;
  border: none;
  background: none;
  cursor: pointer;
  &:hover {
    color: black;
  }
     @media only screen and (max-width:1200px){
     font-size: 40px;
     
  }
`;

const Content = styled.p`
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 26px;
  line-height: 100%;
  text-align: center;
`;

const StepsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-top: 24px;

  @media only screen and (max-width:1200px){
    flex-wrap: wrap;
    grid-template-columns: 1fr !important;
  }
    
`;

const StepCard = styled.div`
  width: 502px;
  min-height: 275px;
  border-radius: 20px;
  background: rgba(30, 84, 165, 0.1);
  border: 3px solid rgb(30, 84, 165);
  padding: 21px;
  box-sizing: border-box;
  
`;

const StepTitle = styled.p`
  font-family: Open Sans;
  font-weight: 800;
  font-size: 20px;
  line-height: 100%;
  color: #1E54A5;
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  
`;

const CongratulationPopup = ({ onClose }) => {

  const navigate = useNavigate();

  const openLinkedIn = () => {
    window.open("https://chromewebstore.google.com/detail/social-post-mate-commente/ffebfjkgjgbpmnoogjjdgfkmiobngdnf", "_blank");
  };

  const goToPostsPage = () => {
    navigate("/posts"); 
  };

  return (
    <Overlay className='modalContainerOverlay'>
      <Modal className='modalContainer'>
        <Header>
          <Title>Congratulations! Your AI Is Ready!</Title>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </Header>
        <Content>
          Your AI-generated comments are now personalized. What’s next?
        </Content>
        <StepsContainer className='stepmodalContainer'>
          <StepCard>
            <div style={{ minHeight: "141px" }}>
              <StepTitle>Step 1: Install the Chrome extension.</StepTitle>
              <StepTitle>Step 2: Connect it with Commenter AI.</StepTitle>
              <StepTitle>Step 3: Generate comments.</StepTitle>
            </div>
            <ButtonDiv>
              <OnboardingButton title="TEST IT DIRECTLY ON LINKEDIN" style={{ maxWidth: "max-content" }} onClick={openLinkedIn} />
            </ButtonDiv>
          </StepCard>
          <StepCard>
            <div style={{ minHeight: "141px" }}>
              <StepTitle>Step 1: Connect the platform with LinkedIn.</StepTitle>
              <StepTitle>
                <span>Step 2: Start engaging by clicking on the icon.</span>
                <img style={{ verticalAlign: "sub", marginLeft: "10px", marginTop: "5px", cursor: "pointer" }} height={26} width={26} src={SocialIcon} alt='social-icon' />
              </StepTitle>
            </div>
            <ButtonDiv>
              <OnboardingButton title="GO TO POST FEATURE" style={{ maxWidth: "max-content" }} onClick={goToPostsPage} />
            </ButtonDiv>
          </StepCard>
        </StepsContainer>
      </Modal>
    </Overlay>
  );
};

export default CongratulationPopup;
