import axios from "axios";

class FetchAPI {
    fetch;
    constructor(library) {
        this.fetch = library;
    }

    async get(url, configuration = {}) {
        try {
            const request = await axios.get(url, configuration);
            return this.authenticateUnauthorizedRequests(request);
        } catch(error) {
            return this.handleRequestError(error);
        }
    }

    async post(url, configuration = {}, data ={}) {
        try {
            const request = await axios.post(url, data, configuration)
            return this.authenticateUnauthorizedRequests(request);
        } catch(error) {
            return this.handleRequestError(error);
        }
    }

    async put(url, configuration = {}, data ={}) {
        try {
            const request = await axios.put(url, data, configuration)
            return this.authenticateUnauthorizedRequests(request);
        } catch(error) {
            return this.handleRequestError(error);
        }
    }

    async delete(url, configuration = {}) {
        try {
            const request = await axios.delete(url, configuration)
            return this.authenticateUnauthorizedRequests(request);
        } catch(error) {
            return this.handleRequestError(error);
        }
    }

    authenticateUnauthorizedRequests(response) {
        if (response?.status === 401) {
            this.handleLogout();
        }
        return response;
    }

    handleRequestError(error) {
        if (error.response?.status === 401) {
            this.handleLogout();
        }
        return Promise.reject(error);
    }

    handleLogout() {
        console.warn("Unauthorized! Logging out...");
        localStorage.clear();
        window.location.href = "/login";  
    }
}

export const fetchAPI = new FetchAPI(axios);