import { useState, useRef } from "react";
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";

import Input from "../components/input";
import { fetchAPI } from "../utils/fetch";
import { config, urlPaths } from "../utils/config";
import { NavLink, useNavigate } from "react-router-dom";
import { content, size } from "../utils/helpers";
import { Loader } from "../components/loader";
import { Buffer } from "buffer";
import GoogleAuth from "../components/GoogleAuth";
import { useLocation } from "react-router-dom";

const RegisterForm = ({ id, children, planData, disabled }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const saleParameter = queryParams.get("sale");
  const action = queryParams.get("action"); // Retrieve action query parameter
  const registerBuyPlan = queryParams.get("plan")

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setErrorStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const recaptcha = useRef(null);
  const sitekey = "6Lf_8-wnAAAAAKg2EjPlV_lC4b4Vn6cJ6H0nXT8I";

  const navigate = useNavigate();

  let registerBtntext = "Get My Free Trial";
  if (planData.type === "proMonth" || planData.type === "proYearly") {
    registerBtntext = "Get My Free Trial";
  }

  if (action === "buy") {
    registerBtntext = `Create my account`;
  }

  const signupWithGoogle = async (credentialResponse) => {
    setLoading(true);
    try {
      const response = await fetchAPI.post(
        `${config.urlPlatform}/api/v2/auth/register-with-google`,
        {
          withCredentials: true,
        },
        {
          idToken: credentialResponse.credential,
          plan: planData.type,
        }
      );
      if (response && response.data.success === false) {
        setErrorStatus(true);
        setErrorMessage(response.data.message);
        setLoading(false);
      }
      localStorage.setItem(
        "userData",
        JSON.stringify(response?.data?.userData)
      );
      localStorage.setItem(
        "expiration",
        JSON.stringify(response?.data?.expiration)
      );
      //initiatePaymentHandler(response?.data?.userData);
      // window.location.replace(urlPaths.platform);
      //navigate("/platform");
      if (action === "buy") {
        initiatePaymentHandler(response?.data?.userData);
      } else {
        // window.location.replace("/thank-you");
        navigate("/custom");
      }
    } catch (error) {
      setErrorStatus(true);
      setErrorMessage(error.response.data.message);
      setLoading(false);
    }
  };
  const onErrorWithGoogle = () => {
    setErrorStatus(true);
    setErrorMessage("Failed to signup with Google");
  };

  const handleClick = async (event) => {
    setLoading(true);
    event.preventDefault();
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      setLoading(false);
      setErrorStatus(true);
      setErrorMessage("Please verify the reCAPTCHA!");
      return;
    }
    if (password.length >= 30) {
      setLoading(false);
      setErrorStatus(true);
      setErrorMessage("The password must be less than 30 letters");
      return;
    }
    try {
      const response = await fetchAPI.post(
        `${config.urlPlatform}/api/v2/auth/register`,
        {},
        {
          email: email,
          password: Buffer.from(password, "ascii").toString("base64"),
          plan: planData.type,
        }
      );
      if (response && response.data.success === false) {
        setLoading(false);
        setErrorStatus(true);
        setErrorMessage(response.data.message);
      }

      // Log in the user
      const loginResponse = await fetchAPI.post(
        `${config.urlPlatform}/api/v2/auth/login`,
        {
          withCredentials: true,
        },
        {
          email: email,
          password: Buffer.from(password, "ascii").toString("base64"),
        }
      );

      if (loginResponse && loginResponse.data.success === false) {
        setLoading(false);
        setErrorStatus(true);
        setErrorMessage(loginResponse.data.message);
        return;
      }

      localStorage.setItem(
        "userData",
        JSON.stringify(loginResponse?.data?.userData)
      );
      localStorage.setItem(
        "expiration",
        JSON.stringify(loginResponse?.data?.expiration)
      );

      // Handle pending payment if action is "buy"
      if (action === "buy") {
        const paymentData = {
          stripeId: loginResponse.data.userData.stripeId,
          email: email,
          planType: planData.type,
        };
        // localStorage.setItem("pendingPaymentData", JSON.stringify(paymentData));
        initiatePaymentHandler(paymentData);
      } else {
        // window.location.replace("/thank-you");
        navigate("/custom");
      }
    } catch (error) {
      setLoading(false);
      setErrorStatus(true);
      setErrorMessage(error?.response?.data?.message);
    }
  };

  const initiatePaymentHandler = (paymentData) => {
    if (paymentData?.plan !== "free") {
      fetchAPI
        .post(
          `${config.urlPlatform}/api/v2/payment/subscription`,
          {
            withCredentials: true,
          },
          {
            email: paymentData.email,
            plan: registerBuyPlan,
            subscriptionId: paymentData?.stripeId,
            sale: !!saleParameter && saleParameter === "blackwednesday",
          }
        )
        .then((paymentResponse) => {
          // setLoadingOne(false);
          if (paymentResponse.status !== 200) {
            throw new Error("error");
          }
          if (paymentResponse.data.success === false) {
            // setErrorMessage(paymentResponse.data.message);
          } else {
            // navigate('/platform');
            localStorage.setItem("email", paymentData.email);
            window.location.replace(paymentResponse.data.url);
            // setSuccessMessage("Successfully upgraded subscription!")

          }
        })
        .catch((error) => {
          console.log("Error: payment/subscription", error.message);
          // setLoadingOne(false);
          // setErrorMessage("There was an issue subscribing. Please contact us!");
        });
    } else {
      window.location.replace(urlPaths.platform);
    }
  };

  return (
    <FormContainer id={id} onSubmit={handleClick}>
      {(() => {
        if (isError === true) {
          return <ErrorMessage>{errorMessage}</ErrorMessage>;
        }
      })()}
      <Input
        placeholder="Marco@gmail.com"
        label="Email address"
        value={email}
        changeEvent={(event) => setEmail(event.target.value)}
        hasIcon={{ active: true, icon: "@" }}
      />
      <Input
        placeholder="********"
        label="Password"
        value={password}
        inputType="password"
        changeEvent={(event) => setPassword(event.target.value)}
        hasIcon={{ active: true, icon: "lock" }}
      />
      <SignUpContainer>
        <FooterColor>
          {content.PublicPages.Register.signIn}{" "}
          <NavLink to={"/login"}>Log In</NavLink>


        </FooterColor>
      </SignUpContainer>
      <CaptchaContainer>
        <ReCAPTCHA ref={recaptcha} sitekey={sitekey} />
      </CaptchaContainer>
      <SubmitButton
        disabled={isLoading || disabled}
        className={isLoading ? "loading" : ""}
      >
        {isLoading ? <Loader /> : registerBtntext}
      </SubmitButton>
      {children}
      {!disabled && (
        <GoogleAuth
          text={"signup_with"}
          onSuccess={signupWithGoogle}
          onError={onErrorWithGoogle}
        />
      )}
    </FormContainer>
  );
};

const FormContainer = styled.form`
  width: 100%;
  max-width: 350px;
  padding: 26px 28px;
  box-sizing: content-box;
  @media only screen and (min-width: ${size.minWidthTablet}) {
    margin-top: 1rem;
    max-width: 500px;
  }
  @media only screen and (max-width: 480px) {
    max-width: 290px;
  }
`;

const SubmitButton = styled.button`
  display: "block";
  width: 100%;
  text-align: center;
  color: var(--white);
  border-radius: 8px;
  padding: 15px 0;
  background-color: #ff2400;
  border: 1px solid #ff2400;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 500;
  font-size: 18px;
  transition: 0.4s;
  &:disabled {
    background-color: #848484;
    border: 1px solid #848484;
    &:hover {
      cursor: revert;
      background-color: #848484;
      border: 1px solid #848484;
      color: var(--white);
    }
  }
  &:hover {
    color: #ff2400;
    background-color: var(--white);
    transition: 0.4s;
    cursor: pointer;
  }
  &.loading {
    background-color: #fedd00;
    border: 1px solid #fedd00;
    transition: 0.4s;
  }
  @media only screen and (min-width: ${size.minWidthTablet}) {
    padding: 11px 0;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  font-weight: 600;
  margin-bottom: 2rem;
  animation: fade-up-quick 0.5s;
`;

const SignUpContainer = styled.div`
  position: relative;
`;


const FooterColor = styled.p`
  font-family: Space Grotesk;
  font-size: 15px;
  line-height: normal;
  font-weight: 700;
  color: #000;
  margin-top: 22px;
  margin-bottom: 22px;
  > a {
    color: var(--blue-text);
    font-family: Space Grotesk;
    font-weight: 700;
    font-size: 15px;
    transition: 0.4s;
    &:hover {
      color: var(--main-bg);
      text-decoration: none;
      transition: 0.4s;
    }
  }
  @media only screen and (min-width: ${size.minWidthTablet}) {
    font-size: 20px;
    margin-top: 29px;
    > a {
      font-size: 20px;
    }
    margin-bottom: 20px;
  }
`;
const CaptchaContainer = styled.div`
  margin: 20px auto;
  text-align: center;
  > div{
    text-align: center;
    margin: auto;
    width: 100%;
  }
  > div >div >div{
    width: 100%;
    margin:auto;
  }
  > iframe{
    width: 100%;
  }
`;
// const ReCAPTCHA = styled.div`
//   margin: 20px auto;
//   text-align: center;
// `;

export default RegisterForm;
