import React from "react";
import styled from "styled-components";

const ReactionWidget = ({ handleLikePost, setReactionWidgetOpen, selectedReaction, setReactionStatus, reactions }) => {

  const handleReactionClick = (reactionName) => {
    if(reactionName !== selectedReaction) {
      setReactionStatus(reactionName);
      handleLikePost(reactionName);
    }
    setReactionWidgetOpen(false)
  };
  
  return (
    <Container>
      <Title>Leave a reaction</Title>
      <Reactions>
        {reactions.map((reaction) => (
          <ReactionButton
            key={reaction.name}
            onClick={() => handleReactionClick(reaction.name)}
            selected={selectedReaction === reaction.name}
          >
            <ReactionImage src={reaction.image} alt={reaction.name} />
          </ReactionButton>
        ))}
      </Reactions>
    </Container>
  );
};

export default ReactionWidget;

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 1rem 0.5rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px, rgba(0, 0, 0, 0.05) 0px 1px 3px;
  width: 270px;
  position: absolute;
  left: -55px;
  z-index: 99;
`;

const Title = styled.h4`
  margin: 0;
  color: #333;
`;

const Reactions = styled.div`
  display: flex;
`;

const ReactionButton = styled.button`
  background-color: ${(props) => (props.selected ? "#e0f7fa" : "transparent")};
  border: ${(props) => (props.selected ? "1px solid #0a66c2" : "1px solid transparent")};
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const ReactionImage = styled.img`
  height: 24px;
  width: 24px;
`;

const SelectedReaction = styled.p`
  margin-top: 1rem;
  color: #0a66c2;
  font-weight: bold;
`;
