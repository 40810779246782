import React from 'react';
import styled from 'styled-components';
import UserImage from "../../assets/user.png";
import Like from "../../assets/like.png";
import Comment from "../../assets/chat-box.png";


const OnboardingPost = ({ profileImage, name, title, content, likes, comments }) => {
  return (
    <PostContainer>
      <Header>
        <Avatar src={profileImage ?? UserImage} alt={name} />
        <UserInfo>
          <UserName>{name ?? "Peter Parker"}</UserName>
          <UserTitle>{title ?? "Founder & CEO, Spider-man"}</UserTitle>
        </UserInfo>
      </Header>
      <Content>{content}</Content>
      <Divider />
      <Footer>
        <IconText>
          <img src={Like} alt='like' /> <span>{likes}</span>
        </IconText>
        <IconText>
          <img src={Comment} alt='comment' /> <span>{comments}</span>
        </IconText>
      </Footer>
    </PostContainer>
  );
};

export default OnboardingPost;

// Styled Components
const PostContainer = styled.div`
  border: 1px solid rgb(224, 224, 224);
  border-radius: 17px;
  width: 100%;
  padding: 28px 50px;
  box-sizing: border-box;

  @media only screen and (max-width:767px){
     padding: 15px ;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Avatar = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 50%;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserName = styled.span`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 6px;
    @media only screen and (max-width:767px){
      font-size: 16px ;
  }
`;

const UserTitle = styled.span`
  font-size: 16px;
  color: gray;
`;

const Content = styled.p`
  margin-top: 10px;
  font-size: 18px;
  line-height: 1.5;
  @media only screen and (max-width:767px){
      font-size: 16px ;
  }
`;

const Divider = styled.hr`
  border: 0;
  border-top: 2px solid #e0e0e0;
  margin: 10px 0;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  margin-top: 25px;
`;

const IconText = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
   cursor: pointer;
  &:hover {
    color: black;
  }
  >img{
    width:25px;
    opacity:0.5;
  }
  >{
  span {
    font-size: 18px;
    color: gray;
    cursor: pointer;
    font-weight: 500;
    display: block;
  }
`;
