import React, { useEffect, useState } from 'react';
import "../Css/custom-new.css";
import OnboardingSelect from '../../../components/onboarding/OnboardingSelect';
import OnboardingInput from '../../../components/onboarding/OnboardingInput';
import { OnboardingButton } from '../../../components/onboarding/OnboardingButton';
import { fetchAPI } from '../../../utils/fetch';
import { config } from '../../../utils/config';

const Step1 = ({ setProgress, setStep, onboardingData, setOnboardingData, industryData, stepInfo, setStepInfo }) => {

  const [formData, setFormData] = useState(onboardingData);
  const [isChanged, setIsChanged] = useState(false);
  const [error, setError] = useState(null);
  const [isFirstTime, setIsFirstTime] = useState(true);

  useEffect(() => {
    setIsFirstTime(onboardingData.industry ? false : true);
    setProgress(10);
  }, []);

  // Check if "Other" is selected or a custom industry is entered
  const isOtherSelected = formData.industry === "Other" || !industryData.includes(formData.industry);

  const handleChange = (field, value) => {
    if (formData[field] !== value) {
      setIsChanged(true);
    }
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleNext = async () => {
    if (!formData.industry) {
      setError("Please select an industry.");
      return;
    }

    setError(null);
    setStep(2);

    if (!isChanged) {
      return;
    }

    try {
      setStepInfo({ step: 1, message: "Updating industry data" })
      const response = await fetchAPI.post(
        `${config.urlPlatform}/api/v2/onboarding/add`,
        { withCredentials: true },
        {
          step: 1,
          field: "industry",
          value: formData.industry
        }
      );

      if (response?.data?.success) {
        setOnboardingData(formData); // Update global state
        setIsChanged(false); // Reset change tracking
      } else {
        setError(response?.data?.message);
        setStep(1);
      }
    } catch (error) {
      setStep(1);
      setError("Failed to update. Please try again.");
    } finally {
      setStepInfo({ step: -1, message: null })
    }
  };

  return (
    <div className='onboarding-body-global'>
      <div className='step0-onboarding-heading'>What’s Your Industry?</div>
      <div className='step0-onboarding-subheading'>
        AI tailors comments based on industry. Select the industry you most engage with.
      </div>
      <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>
        <OnboardingSelect
          label="Choose your industry"
          options={industryData}
          selectedValue={isOtherSelected ? "Other" : formData.industry}
          onChange={(value) => { setIsFirstTime(false); handleChange("industry", value) }}
          isFirstTime={isFirstTime}
        />
        {formData.industry !== "" && isOtherSelected && (
          <OnboardingInput
            placeholder="Write your own"
            value={formData.industry === "Other" ? "" : formData.industry}
            cutomHandler={true}
            onChange={(e) => handleChange("industry", e.target.value)}
          />
        )}
          {stepInfo.step === 1 && stepInfo.message && <div className='info-message-onboarding'>{stepInfo.message}</div>}
          {error && <div className='error-message-onboarding'>{error}</div>}
        <div className='onboarding-below-buttons'>
          <OnboardingButton style={{ width: "177px", height: "55px" }} onClick={() => setStep(0)} title="Back" />
          <OnboardingButton style={{ width: "177px", height: "55px" }} onClick={handleNext} title="Next" variant='hollow' />
        </div>
      
      </div>
    </div>
  );
};

export default Step1;
