import React, { useEffect, useState } from 'react'
import { OnboardingButton } from '../../../components/onboarding/OnboardingButton'
import "../Css/custom-new.css"
import OnboardingPost from '../../../components/onboarding/OnboardingPost'
import OnboardingGeneratedComment from '../../../components/onboarding/OnboardingGeneratedComment'
import styled from 'styled-components'
import Logo64 from "../../../assets/logo-64.png";
import { fetchAPI } from '../../../utils/fetch'
import { config } from '../../../utils/config'

const Step9 = ({ setProgress, setStep, onboardingData, setOnboardingData, setStepInfo, stepInfo, setShowCongratulationsPopup }) => {

  const [loading, setLoading] = useState(true);
  const engagementPreferences = onboardingData.engagementPreferences || [];
  const [comments, setComments] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    setProgress(95);
    fetchPosts();
    setComments([]);
    setError(null);
  }, [])

  const fetchPosts = async () => {
    for (let i = 0; i < engagementPreferences.length; i++) {
      const preference = engagementPreferences[i];
      try {
        setLoading(true);
        const response = await fetchAPI.post(
          `${config.urlPlatform}/api/v2/plugin/get-posts`,
          { withCredentials: true },
          { engagementPreference: preference }
        );

        if (response?.data?.success) {
          const postData = response?.data?.data;
          const newPost = {
            post: postData.post,
            author: postData.author,
            profession: postData.profession,
            likes: postData.likes,
            commentsCount: postData.commentsCount,
            comment: "Generating Comment...",
            isApproved: false
          };
          setComments((prev) => [...prev, newPost]);
          fetchComment(newPost, i);
        }
        else {
          console.log("Failed to generate data for ", preference);
        }
      } catch (error) {
        console.error("Error fetching posts:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchComment = async (post, index) => {
    try {
      const response = await fetchAPI.post(
        `${config.urlPlatform}/api/v2/plugin/get-comment-for-post`,
        { withCredentials: true },
        {
          post: post?.post,
          author: post?.author
        }
      );

      if (response?.data?.success) {
        const commentData = response?.data?.data;
        setComments((prev) => {
          const newComments = [...prev];
          newComments[index] = { ...newComments[index], comment: commentData };
          return newComments;
        });
      }
      else {
        console.log("Failed to generate comment for ", post);
      }
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const handleApprove = (index) => {
    setComments((prev) => {
      const newComments = [...prev];
      newComments[index] = {
        ...newComments[index],
        isApproved: !newComments[index].isApproved
      };
      return newComments;
    });
  };

  const handleEdit = (index, newComment) => {
    setComments((prev) => {
      const updatedComments = [...prev];
      updatedComments[index] = { ...updatedComments[index], comment: newComment };
      return updatedComments;
    });
  };

  const handlePersonalizationToggle = async (value) => {
    try {
      const response = await fetchAPI.post(
        `${config.urlPlatform}/api/v2/onboarding/add`,
        { withCredentials: true },
        {
          step: 9,
          field: "personalization",
          value: value,
        }
      );

      if (!response?.data?.success) {
        console.log(response?.data?.message || "Failed to update personalization. Please try again.");
      }
    } catch (error) {
      console.log("Failed to update personalization. Please try again.");
    }
  };

  const handleNext = async () => {
    // Validation: All comments must be approved
    if (comments.some(comment => !comment.isApproved)) {
      setError("All comments must be approved before proceeding.");
      return;
    }

    setError(null);
    setStep(10);
    setShowCongratulationsPopup(true);
    setStepInfo({ step: 9, message: "Submitting your comments..." });

    try {
      const genComments = comments.map(comment => comment.comment);
      setOnboardingData(prev => ({ ...prev, approvedComments: genComments, personalization: true }));

      // Create API call promises
      const handlePersonalizationPromise = handlePersonalizationToggle(true);

      const submitCommentsPromise = fetchAPI.post(
        `${config.urlPlatform}/api/v2/onboarding/add`,
        { withCredentials: true },
        {
          step: 9,
          field: "approvedComments",
          value: genComments
        }
      );

      // Execute both API calls in parallel
      const [personalizationResponse, submitResponse] = await Promise.all([
        handlePersonalizationPromise,
        submitCommentsPromise
      ]);

      // Handle API response
      if (!submitResponse?.data?.success) {
        setStep(9);
        setError(submitResponse?.data?.message || "Failed to submit comments. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting comments:", error);
      setStep(9);
      setError("Failed to submit. Please try again.");
    } finally {
      setStepInfo({ step: -1, message: null });
    }
  };

  return (
    <div className='onboarding-body-global'>
      <div className='step0-onboarding-heading' style={{ fontSize: "35px" }}>
        Final Review: Your AI-Generated Comments
      </div>
      <div className='step0-onboarding-subheading'>
        Approve, or edit the AI-generated comments.
      </div>

      <div className='step0-onboarding-cards' style={{ width: "90%", display: "flex", flexDirection: "column", gap: "20px" }}>

        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
          {comments.map((comment, index) => (
            <div key={index}>
              <OnboardingPost
                name={comment?.author ?? "Author Name"}
                title={comment?.profession ?? "Profession"}
                content={comment?.post ?? "Post Content"}
                likes={comment?.likes ?? 0}
                comments={comment?.commentsCount ?? 0}
              />
              <OnboardingGeneratedComment
                comment={comment.comment}
                isApproved={comment.isApproved}
                onApprove={() => handleApprove(index)}
                onEdit={(newComment) => handleEdit(index, newComment)}
              />
              {index < comments.length - 1 && <div style={{ border: "1px solid #00000033", marginBottom: "30px" }}></div>}
            </div>
          ))}
        </div>

        {/* Error Message */}
        {error && <div className="error-message-onboarding">{error}</div>}

        {/* Info Message */}
        {stepInfo.step === 9 && stepInfo.message && <div className="info-message-onboarding">{stepInfo.message}</div>}

        {/* Loader starts */}
        <div
          style={{
            display: loading ? "flex" : "none",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <div className="container">
            <div className="logo-container">
              <div className="logo">
                <LogoImg src={Logo64} alt="logo" />
              </div>
            </div>

            <div className="loading-letters">
              <span>G</span>
              <span>e</span>
              <span>n</span>
              <span>e</span>
              <span>r</span>
              <span>a</span>
              <span>t</span>
              <span>i</span>
              <span>n</span>
              <span>g</span>
              <span></span>
              <span>d</span>
              <span>a</span>
              <span>t</span>
              <span>a</span>
              <span>.</span>
              <span>.</span>
            </div>
          </div>
        </div>
        {/* Loader ends */}

        <div className='onboarding-below-buttons' style={{ marginTop: "0px" }}>
          <OnboardingButton style={{ width: "177px", height: "55px", boxSizing: "border-box" }} onClick={() => setStep(8)} title="Back" />
          <OnboardingButton disabled={loading} style={{ width: "177px", height: "55px", boxSizing: "border-box" }} onClick={handleNext} title="Next" variant='hollow' />
        </div>

      </div>
    </div>
  )
}

export default Step9

const LogoImg = styled.img.attrs({
  loading: "lazy",
})`
  width: 4rem;
`;