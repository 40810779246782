import React, { useEffect, useMemo } from 'react'
import Step0 from './steps/Step0';
import { useState } from 'react';

import CommenterBigLogo from "./../../assets/commenter-big.svg"
import OnboardingProgress from '../../components/onboarding/OnboardingProgress';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';
import Step5 from './steps/Step5';
import Step6 from './steps/Step6';
import Step7 from './steps/Step7';
import Step8 from './steps/Step8';
import Step9 from './steps/Step9';
import Step10 from './steps/Step10';
import styled from 'styled-components';
import Logo64 from "../../assets/logo-64.png";
import { fetchAPI } from '../../utils/fetch';
import { config } from '../../utils/config';
import { useNavigate } from 'react-router-dom';
import SkipCustomizationPopup from './Popups/SkipCustomizationPopup';
import "./Css/custom-new.css"

const MainOnboarding = () => {

  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [progress, setProgress] = useState(10);
  const [stepInfo, setStepInfo] = useState({ step: -1, message: null })
  const [isLoading, setIsLoading] = useState(true);
  const [showCongratulationsPopup, setShowCongratulationsPopup] = useState(false);
  const [showSkipPopup, setshowSkipPopup] = useState(false);

  const initialData = {
    industry: "",
    commentLength: "SHORT",
    tone: "",
    emojiUsage: false,
    pharases: {
      favouritePharases: "",
      bannedPharases: ""
    },
    callToAction: "YES",
    personalization: false,
    recentComments: [],
    aiModel: "OPENAI",
    approvedComments: [],
    engagementPreferences: [],
    step: 0,
  }
  const [onboardingData, setOnboardingData] = useState(initialData);

  // Static onboarding data starts
  const industryData = [
    "Technology",
    "Healthcare",
    "Finance",
    "Marketing",
    "Education",
    "HR & Recruiting",
    "Sales",
    "Engineering",
    "Other"
  ]

  const toneData = [
    "Professional",
    "Casual",
    "Enthusiastic",
    "Friendly",
    "Funny",
    "Other"
  ];

  const ctaOptions = [
    "YES",
    "NO",
    "SOMETIMES"
  ];

  const commentLengthOptions = [
    { label: "Short", sublabel: "(Up to 20 words)", value: "SHORT" },
    { label: "Medium", sublabel: "(20-70 words)", value: "MEDIUM" },
    { label: "Long", sublabel: "(70+ words)", value: "LONG" },
  ];

  const engagementPreferencesOptions = [
    { label: "How-to / Lessons Learned", value: "How-to / Lessons Learned" },
    { label: "Inspirational / Motivational", value: "Inspirational / Motivational" },
    { label: "Opinion / Controversial", value: "Opinion / Controversial" },
    { label: "Event Updates", value: "Event Updates" },
    { label: "Job-Related Posts", value: "Job-Related Posts" },
    { label: "Appreciation / Gratitude", value: "Appreciation / Gratitude" },
    { label: "Storytelling", value: "Storytelling" },
    { label: "Industry News & Trends", value: "Industry News & Trends" }
  ];

  const aiOptions = [
    { label: "OpenAI", value: "OPENAI" },
    { label: "Claude AI", value: "CLAUDEAI" }
  ];

  useEffect(() => {
    const updateBodyClass = () => {
      const classNameToCheck = "full-screen";
      const targetElement = document.querySelector(`.${classNameToCheck}`);
      
      if (targetElement || step === 0) {
        document.body.classList.add("showfullscreen");
      } else {
        document.body.classList.remove("showfullscreen");
      }
    };

    updateBodyClass(); // Run when component mounts or updates

    // Cleanup function: remove class when component unmounts
    return () => {
      document.body.classList.remove("showfullscreen");
    };
  }, [step]); //

  // Static onboarding data ends

  const fetchOnboardingData = async () => {
    try {
      setIsLoading(true);
      const response = await fetchAPI.get(
        `${config.urlPlatform}/api/v2/onboarding/getDetails`,
        { withCredentials: true }
      );

      if (response?.data?.success) {
        setOnboardingData(response?.data?.data ?? initialData);
        setStep(response?.data?.data?.step + 1 ?? 0);
      }
    } catch (error) {
      console.log("Failed to fetch onboarding data");
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchOnboardingData();
  }, []);

  // Render different components based on the step
  const renderStep = () => {
    switch (step) {
      case 0:
        return <Step0 setProgress={setProgress} setStep={setStep} onboardingData={onboardingData} setOnboardingData={setOnboardingData} stepInfo={stepInfo} setStepInfo={setStepInfo} />;
      case 1:
        return <Step1 setProgress={setProgress} setStep={setStep} industryData={industryData} onboardingData={onboardingData} setOnboardingData={setOnboardingData} stepInfo={stepInfo} setStepInfo={setStepInfo} />;
      case 2:
        return <Step2 setProgress={setProgress} setStep={setStep} onboardingData={onboardingData} setOnboardingData={setOnboardingData} commentLengthOptions={commentLengthOptions} stepInfo={stepInfo} setStepInfo={setStepInfo} />;
      case 3:
        return <Step3 setProgress={setProgress} setStep={setStep} onboardingData={onboardingData} setOnboardingData={setOnboardingData} stepInfo={stepInfo} setStepInfo={setStepInfo} toneData={toneData} />;
      case 4:
        return <Step4 setProgress={setProgress} setStep={setStep} onboardingData={onboardingData} setOnboardingData={setOnboardingData} stepInfo={stepInfo} setStepInfo={setStepInfo} />;
      case 5:
        return <Step5 setProgress={setProgress} setStep={setStep} onboardingData={onboardingData} setOnboardingData={setOnboardingData} stepInfo={stepInfo} setStepInfo={setStepInfo} />;
      case 6:
        return <Step6 setProgress={setProgress} setStep={setStep} onboardingData={onboardingData} setOnboardingData={setOnboardingData} stepInfo={stepInfo} setStepInfo={setStepInfo} ctaOptions={ctaOptions} />;
      case 7:
        return <Step7 setProgress={setProgress} setStep={setStep} onboardingData={onboardingData} setOnboardingData={setOnboardingData} stepInfo={stepInfo} setStepInfo={setStepInfo} />;
      case 8:
        return <Step8 setProgress={setProgress} setStep={setStep} onboardingData={onboardingData} setOnboardingData={setOnboardingData} stepInfo={stepInfo} setStepInfo={setStepInfo} engagementPreferencesOptions={engagementPreferencesOptions} />;
      case 9:
        return <Step9 setProgress={setProgress} setStep={setStep} onboardingData={onboardingData} setOnboardingData={setOnboardingData} stepInfo={stepInfo} setStepInfo={setStepInfo} setShowCongratulationsPopup={setShowCongratulationsPopup} />;
      case 10:
        return <Step10 setProgress={setProgress} setStep={setStep} onboardingData={onboardingData} setOnboardingData={setOnboardingData} stepInfo={stepInfo} setStepInfo={setStepInfo} industryData={industryData} toneData={toneData} ctaOptions={ctaOptions} commentLengthOptions={commentLengthOptions} engagementPreferencesOptions={engagementPreferencesOptions} aiOptions={aiOptions} showCongratulationsPopup={showCongratulationsPopup} setShowCongratulationsPopup={setShowCongratulationsPopup} />;
      default:
        return <Step0 setProgress={setProgress} onboardingData={onboardingData} setOnboardingData={setOnboardingData} stepInfo={stepInfo} setStepInfo={setStepInfo} />;
    }
  };

  if (isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          minHeight: "100vh",
          width: "100%",
          background: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          top: 0,
          zIndex: 9999,
        }}
      >
        <div className="container">
          <div className="logo-container">
            <div className="logo">
              <LogoImg src={Logo64} alt="logo" />
            </div>
          </div>

          <div className="loading-letters">
            <span>L</span>
            <span>o</span>
            <span>a</span>
            <span>d</span>
            <span>i</span>
            <span>n</span>
            <span>g</span>
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`${step !== 10 ? "full-screen" : "showchildEle"}`}>
  

      <div className='onboarding-container'>
        <div>
          {
            progress === 100 ?
              <span className='commenter-logo-text'>You’ve made it!</span> :
              <img className='commenter-big-logo' src={CommenterBigLogo} alt='commenter-big' />
          }
        </div>
        <div className='onboarding-body-container'>
          <OnboardingProgress progress={progress} step={step} />
          <div className="onboarding-body" style={{ position: "relative" }}>
            {(step !== 0 && step !== 10) && <SkipButton onClick={() => setshowSkipPopup(true)}>Skip Customization</SkipButton>}
            {
              showSkipPopup &&
              <SkipCustomizationPopup onClose={() => setshowSkipPopup(false)} />
            }
            {renderStep()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainOnboarding

const LogoImg = styled.img.attrs({
  loading: "lazy",
})`
  width: 4rem;
`;

const SkipButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 17px;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 500;
  color: #0152b3;
`;