import React, { useState } from "react";
import styled from "styled-components";

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

const Label = styled.label`
  font-family: Open Sans;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0%;
  margin-bottom:17px;
   @media only screen and (max-width: 767px) {
     font-size: 16px;
      margin-bottom:10px;
   }
`;

const SelectedOption = styled.div`
  box-shadow: 10px 10px 0px 0px #5BB7E7;
  height: 66.05504608154297px;
  border-width: 1px;
  border-radius: 20px;
  apperance: none;
  padding:17px 30px;
  box-sizing:border-box;
  border: 1px solid #5BB7E7
  font-family: Open Sans;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: 0%;
  color:#000;
  display:flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #5BB7E7;
  @media only screen and (max-width: 767px) {
        box-shadow: 5px 5px 0px 0px #5BB7E7;
        padding:17px 15px;
        font-size: 18px;
   }
  
`;

const DropdownMenu = styled.ul`
    position: absolute;
    width: 100%;
    background: #E5F6FF;
    border-radius: 8px;
    list-style: none;
    padding: 0px 0px;
    margin: 5px 0px 0px;
    z-index: 10;
    max-height: 183px;
    overflow-y: auto;
    top: calc(100% + 20px);
    border-radius: 20px;
`;

const DropdownItem = styled.li`
  padding: 20px 30px;
  background: #E5F6FF;
  cursor: pointer;
  font-family: Open Sans;
  color #000000;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: 0%;
  border-bottom: 1px solid #0000001A;
    @media only screen and (max-width: 767px) {
      font-size: 18px;
   }

  &:hover {
   background: #2F62AD1A;

  }
`;
const ArrowIcon = styled.svg`
  width: 21px;
  height: 14px;
  fill: black;
  transition: transform 0.3s ease-in-out;
  transform: ${({ isOpen }) => (isOpen ? "rotate(0deg)" : "rotate(180deg)")};
`;


const OnboardingSelect = ({ label, options, selectedValue, onChange, isFirstTime }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <SelectContainer>
      {label && <Label>{label}</Label>}
      <SelectedOption onClick={() => setIsOpen(!isOpen)}>
        {isFirstTime ? "Select an option" : selectedValue} 
        <ArrowIcon
          isOpen={isOpen}
          viewBox="0 0 21 14"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.33352 1.0841L-1.00722e-07 11.2155L2.33297 13.7479L10.5 4.88271L18.667 13.7479L21 11.2155L11.6665 1.0841C11.3571 0.74835 10.9375 0.559735 10.5 0.559735C10.0625 0.559735 9.64292 0.74835 9.33352 1.0841Z"
          />
        </ArrowIcon>

      </SelectedOption>
      {isOpen && (
        <DropdownMenu>
          {options.map((option, index) => (
            <DropdownItem key={index} onClick={() => { onChange(option); setIsOpen(false); }}>
              {option}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </SelectContainer>
  );
};

export default OnboardingSelect;