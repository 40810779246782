import { useState } from "react";
import styled from "styled-components";
import Input from "../components/input";
import { fetchAPI } from "../utils/fetch";
import { config } from "../utils/config";
import { useNavigate } from "react-router-dom";
import { Loader } from "../components/loader";
import { size } from "../utils/helpers";
import { Buffer } from "buffer";
import GoogleAuth from "../components/GoogleAuth";

const LoginForm = ({ id, captcha }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLogged, setLoggedStatus] = useState(false);
  const [isError, setErrorStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleClick = async (event) => {
    event.preventDefault();
    setLoading(true);
    const token = captcha.current.getValue();
    if (!token) {
      setLoading(false);
      setErrorStatus(true);
      setErrorMessage("Please verify the reCAPTCHA!");
      return;
    }
    if (password.length >= 30) {
      setLoading(false);
      setErrorStatus(true);
      setErrorMessage("The password must be less than 30 letters");
      return;
    }
    try {
      const response = await fetchAPI.post(
        `${config.urlPlatform}/api/v2/auth/login`,
        {
          withCredentials: true,
        },
        {
          email: email,
          password: Buffer.from(password, "ascii").toString("base64"),
        }
      );

      if (isLogged === false && response?.data?.success === false) {
        setLoggedStatus(false);
        setErrorStatus(true);
        setErrorMessage(response.data.message);
        setLoading(false);
      } else {
        setLoggedStatus(true);
        setLoading(false);
        localStorage.setItem(
          "userData",
          JSON.stringify(response?.data?.userData)
        );
        localStorage.setItem(
          "expiration",
          JSON.stringify(response?.data?.expiration)
        );
        // navigate('/platform');
        window.fpr("referral", {
          email: email,
        });
        // console.log("login response >>>", response);
        // if(!response?.data?.userData.isFreeTrial && response?.data?.userData?.plan !== 'free' && response?.data?.userData.paidStatus === false){
        //     fetchAPI.post(`${config.urlPlatform}/api/v2/payment/subscription`, {
        //         withCredentials: true
        //     }, {
        //         email: response?.data?.userData?.email,
        //         subscriptionId: response?.data?.userData?.stripeId,
        //     }).then(paymentResponse => {
        //         // setLoadingOne(false);
        //         if (paymentResponse.status !== 200) {
        //             throw new Error("error");
        //         }
        //         if (paymentResponse.data.success === false) {
        //             // setErrorMessage(paymentResponse.data.message);
        //         } else {
        //             window.location.replace(paymentResponse.data.url);
        //             // setSuccessMessage("Successfully upgraded subscription!")

        //         }
        //     }).catch(error => {
        //         console.log("Error: payment/subscription", error.message);
        //         // setLoadingOne(false);
        //         // setErrorMessage("There was an issue subscribing. Please contact us!");
        //     })
        // }else{

        navigate("/platform");
        // }
        // initiatePaymentHandler(response?.data?.userData)
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      setLoggedStatus(false);
      setErrorStatus(true);
      setErrorMessage("We have server issues");
    }
  };

  const loginWithOauth = async (credentialResponse) => {
    setLoading(true);
    try {
      const response = await fetchAPI.post(
        `${config.urlPlatform}/api/v2/auth/check-oauth`,
        {
          withCredentials: true,
        },
        {
          idToken: credentialResponse.credential,
        }
      );

      if (isLogged === false && response?.data?.success === false) {
        setLoggedStatus(false);
        setErrorStatus(true);
        setErrorMessage(response.data.message);
        setLoading(false);
      } else {
        setLoggedStatus(true);
        setLoading(false);
        localStorage.setItem(
          "userData",
          JSON.stringify(response?.data?.userData)
        );
        localStorage.setItem(
          "expiration",
          JSON.stringify(response?.data?.expiration)
        );
        // initiatePaymentHandler(response?.data?.userData)
        navigate("/platform");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      setLoggedStatus(false);
      setErrorStatus(true);
      setErrorMessage("We have server issues");
    }
  };

  const initiatePaymentHandler = (userData) => {
    if (
      !userData.isFreeTrial &&
      userData?.plan !== "free" &&
      userData.paidStatus === false
    ) {
      fetchAPI
        .post(
          `${config.urlPlatform}/api/v2/payment/subscription`,
          {
            withCredentials: true,
          },
          {
            email: userData?.email,
            subscriptionId: userData?.stripeId,
          }
        )
        .then((paymentResponse) => {
          // setLoadingOne(false);
          if (paymentResponse.status !== 200) {
            throw new Error("error");
          }
          if (paymentResponse.data.success === false) {
            // setErrorMessage(paymentResponse.data.message);
          } else {
            window.location.replace(paymentResponse.data.url);
            // setSuccessMessage("Successfully upgraded subscription!")
            localStorage.setItem("subscription", "pro");

            // navigate('/platform');
          }
        })
        .catch((error) => {
          console.log("Error: payment/subscription", error.message);
          // setLoadingOne(false);
          // setErrorMessage("There was an issue subscribing. Please contact us!");
        });
    } else {
      // window.location.replace(config.platform);
      navigate("/platform");
    }
  };

  const onErrorWithGoogle = () => {
    setErrorStatus(true);
    setErrorMessage("We have server issues");
  };

  return (
    <FormContainer id={id} onSubmit={handleClick}>
      {(() => {
        if (isError === true) {
          return <ErrorMessage>{errorMessage}</ErrorMessage>;
        }
      })()}
      <Input
        placeholder="Marco@gmail.com"
        label="Email address"
        value={email}
        changeEvent={(event) => setEmail(event.target.value)}
        hasIcon={{ active: true, icon: "@" }}
      />
      <Input
        placeholder="********"
        label="Password"
        value={password}
        inputType="password"
        changeEvent={(event) => setPassword(event.target.value)}
        hasIcon={{ active: true, icon: "lock" }}
      />
      <ForgetPasswordHolder
        onClick={() => {
          navigate("/request-new-password");
        }}
      >
        Forgot password?
      </ForgetPasswordHolder>
      <SubmitButton className={isLoading ? "loading" : ""}>
        {isLoading ? <Loader /> : "Login"}
      </SubmitButton>
      <GoogleAuth
        text={"Sign in with Google"}
        onSuccess={loginWithOauth}
        onError={onErrorWithGoogle}
      />
    </FormContainer>
  );
};

const FormContainer = styled.form`
  width: 100%;
  margin-top: 5rem;
  padding: 1rem 1rem 0;
  box-sizing: content-box;

  @media only screen and (max-width: ${size.minWidthTablet}) {
    width: revert;
    margin-top: 2rem;
  }
`;

const SubmitButton = styled.button`
  display: "block";
  width: 100%;
  text-align: center;
  color: var(--white);
  border-radius: 8px;
  padding: 1rem 0;
  background-color: #ff2400;
  border: 1px solid #ff2400;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  transition: 0.4s;
  &:hover {
    color: #ff2400;
    background-color: var(--white);
    transition: 0.4s;
    cursor: pointer;
  }
  &.loading {
    background-color: #fedd00;
    border: 1px solid #fedd00;
    transition: 0.4s;
  }
`;
const ForgetPasswordHolder = styled.div`
  color: var(--main-bg);
  cursor: pointer;
  margin: 1rem 0 1rem;
  text-align: right;
  text-decoration: underline;
`;

const ErrorMessage = styled.div`
  color: red;
  font-weight: 600;
  margin-bottom: 2rem;
  animation: fade-up-quick 0.5s;
`;

export default LoginForm;
