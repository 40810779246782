import React from 'react'
import MainOnboarding from '../onboarding/MainOnboarding'

const CustomNew = () => {
  return (
    <MainOnboarding />
  )
}

export default CustomNew
