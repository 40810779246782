import React, { useEffect, useState } from 'react';
import { OnboardingButton } from '../../../components/onboarding/OnboardingButton';
import '../Css/custom-new.css';
import OnboardingCheckbox from '../../../components/onboarding/OnboardingCheckbox';
import { fetchAPI } from '../../../utils/fetch';
import { config } from '../../../utils/config';

const Step8 = ({ setProgress, setStep, onboardingData, setOnboardingData, engagementPreferencesOptions, stepInfo, setStepInfo }) => {
  useEffect(() => {
    setProgress(90);
  }, []);

  // Initialize selected preferences from onboardingData
  const [selected, setSelected] = useState(onboardingData.engagementPreferences || []);
  const [error, setError] = useState(null);
  const [isChanged, setIsChanged] = useState(false);

  const handleNext = async () => {
    if (selected.length === 0) {
      setError('Please select at least one preference to proceed.');
      return;
    }

    setError(null);
    setStep(9);

    if (!isChanged) return; // Skip API call if no change

    try {
      setStepInfo({ step: 8, message: 'Saving your preferences...' });
      // saving before as it required in next step
      setOnboardingData(prev => ({ ...prev, engagementPreferences: selected }));
      const response = await fetchAPI.post(
        `${config.urlPlatform}/api/v2/onboarding/add`,
        { withCredentials: true },
        {
          step: 8,
          field: 'engagementPreferences',
          value: selected
        }
      );

      if (response?.data?.success) {
        
        setIsChanged(false);
      } else {
        setError(response?.data?.message || 'Failed to save preferences. Please try again.');
        setStep(8);
      }
    } catch (error) {
      setStep(8);
      setError('Failed to update. Please try again.');
    } finally {
      setStepInfo({ step: -1, message: null });
    }
  };

  return (
    <div className='onboarding-body-global'>
      <div className='step0-onboarding-heading'>
        What Type of Posts Do You Engage With?
      </div>
      <div className='step0-onboarding-subheading'>
        AI will prioritize comments on topics that interest you most.
      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <OnboardingCheckbox
          options={engagementPreferencesOptions}
          selectedOptions={selected}
          onChange={(newSelection) => {
            setSelected(newSelection);
            setIsChanged(true);
          }}
        />
        {error && <div className='error-message-onboarding'>{error}</div>}
        {stepInfo.step === 8 && stepInfo.message && <div className='info-message-onboarding'>{stepInfo.message}</div>}
        <div className='onboarding-below-buttons'>
          <OnboardingButton style={{ width: '177px', height: '55px', boxSizing: 'border-box' }} onClick={() => setStep(7)} title='Back' />
          <OnboardingButton style={{ width: '177px', height: '55px', boxSizing: 'border-box' }} onClick={handleNext} title='Next' variant='hollow' />
        </div>
      </div>
    </div>
  );
};

export default Step8;
