import React, { useEffect, useState } from 'react';
import { OnboardingButton } from '../../../components/onboarding/OnboardingButton';
import '../Css/custom-new.css';
import OnboardingInput from '../../../components/onboarding/OnboardingInput';
import { fetchAPI } from '../../../utils/fetch';
import { config } from '../../../utils/config';

const Step7 = ({ setProgress, setStep, onboardingData, setOnboardingData, stepInfo, setStepInfo }) => {
  const initialComments = onboardingData.recentComments || [];
  const filledComments = initialComments; // Keep all comments from onboardingData
  const emptyComments = Array(10 - filledComments.length).fill(''); // Fill remaining slots with empty strings
  const [comments, setComments] = useState([...filledComments, ...emptyComments]);
  const [error, setError] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const storage = localStorage.getItem("userData");
  const storageJson = JSON.parse(storage);

  useEffect(() => {
    setProgress(73);
  }, []);

  const handleChange = (index, value) => {
    const updatedComments = [...comments];
    updatedComments[index] = value;
    setComments(updatedComments);
    setIsChanged(true);
  };

  const handleNext = async () => {
    const filledComments = comments.filter(comment => comment.trim() !== '');

    if (filledComments.length < 5) {
      setError('Please provide at least 5 comments to proceed.');
      return;
    }

    setError(null);
    setStep(8);

    if (!isChanged) return;

    try {
      setStepInfo({ step: 7, message: 'Saving your comments...' });

      // Create API call promises
      const saveCommentsPromise = fetchAPI.post(
        `${config.urlPlatform}/api/v2/onboarding/add`,
        { withCredentials: true },
        {
          step: 7,
          field: 'recentComments',
          value: filledComments
        }
      );

      const createPromptPromise = fetchAPI.post(
        `${config.urlPlatform}/api/v2/prompt/create`,
        { withCredentials: true },
        {
          email: storageJson.email,
          categories: [onboardingData?.industry] ?? [], 
          styles: [onboardingData?.tone] ?? [] 
        }
      );

      // Execute API calls in parallel
      const [commentsResponse, promptResponse] = await Promise.all([
        saveCommentsPromise,
        createPromptPromise
      ]);

      // Handle comments response
      if (commentsResponse?.data?.success) {
        setOnboardingData(prev => ({ ...prev, recentComments: filledComments }));
        setIsChanged(false);
      } else {
        setError(commentsResponse?.data?.message || 'Failed to save comments. Please try again.');
        setStep(7);
      }

      if (!promptResponse?.data?.success) {
        setError(promptResponse?.data?.message || 'Failed to create prompt.');
      }
    } catch (error) {
      setStep(7);
      setError('Failed to update. Please try again.');
    } finally {
      setStepInfo({ step: -1, message: null });
    }
  };

  return (
    <div className='onboarding-body-global'>
      <div className='step0-onboarding-heading'>
        Help AI Learn Your Writing Style
      </div>
      <div className='step0-onboarding-subheading'>
        Paste 5-10 of your recent LinkedIn comments so AI can mimic your style.
      </div>
      <div style={{
        fontFamily: 'Open Sans',
        fontWeight: 800,
        fontSize: '20px',
        lineHeight: '100%',
        color: '#0450AC',
        marginTop: '-20px'
      }}>
        Requires at least 5 comments to proceed
      </div>
      <div style={{
        fontFamily: 'Open Sans',
        fontWeight: 400,
        fontSize: '22px',
        lineHeight: '100%',
        color: '#0450AC',
        marginTop: '-20px',
        fontStyle: "italic",
        color: "#000000"
      }}>
        (if you don’t have recent comments paste 5+ comments that you like)
      </div>
      <div style={{ width: '90%', display: 'flex', flexDirection: 'column', gap: '20px' }} class="onboarding-Wrapper-container">
        {comments.map((comment, index) => (
          <OnboardingInput
            key={index}
            value={comment}
            onChange={(e) => handleChange(index, e.target.value)}
            title={`Comment ${index + 1}`}
            cutomHandler={true}
          />
        ))}
        {error && <div className='error-message-onboarding'>{error}</div>}
        {stepInfo.step === 7 && stepInfo.message && <div className='info-message-onboarding'>{stepInfo.message}</div>}
        <div className='onboarding-below-buttons'>
          <OnboardingButton style={{ width: '177px', height: '55px', boxSizing: 'border-box' }} onClick={() => setStep(6)} title='Back' />
          <OnboardingButton style={{ width: '177px', height: '55px', boxSizing: 'border-box' }} onClick={handleNext} title='Next' variant='hollow' />
        </div>
      </div>
    </div>
  );
};

export default Step7;
