import React, { useState, useRef, useEffect, useCallback } from "react";
import styled from "styled-components";

const PostTextComponent = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const postTextRef = useRef(null);

  const maxLines = 5;

  const checkOverflow = useCallback(() => {
    if (postTextRef.current) {
      const contentHeight = postTextRef.current.scrollHeight;
      const containerHeight = postTextRef.current.clientHeight;
      const lineHeightValue = containerHeight / maxLines;

      setIsOverflowing(contentHeight > lineHeightValue * maxLines);
    }
  }, []);

  useEffect(() => {
    checkOverflow(); // Check on initial render
  }, [text, checkOverflow]);

  return (
    <div>
      <PostText
        ref={postTextRef}
        style={{
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: isExpanded ? "unset" : maxLines,
          overflow: "hidden",
          lineHeight: "1.4rem",
          wordWrap: "break-word", 
        }}
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      ></PostText>

      {isOverflowing && (
        <div
          onClick={() => setIsExpanded(!isExpanded)}
          style={{
            marginTop: "2px",
            color: "gray",
            cursor: "pointer",
            background: "none",
            border: "none",
            fontSize: "12px",
          }}
        >
          {isExpanded ? "... Show Less" : "... Show More"}
        </div>
      )}
    </div>
  );
};

const PostText = styled.div`
  font-size: 16px;
  font-family: "Exo 2", serif;
`;

export default PostTextComponent;
