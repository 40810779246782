import React, { useState } from "react";
import styled from "styled-components";

const AttachmentCarousel = ({ attachments }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    if (currentIndex < attachments.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  return (
    <CarouselWrapper>
      {attachments.length > 0 && (
        <>
          <CarouselContainer currentIndex={currentIndex}>
            {attachments.map((attachment, index) => (
              <CarouselItem key={index}>
                {attachment.type === "img" ? (
                  <img
                    src={attachment.url}
                    alt={`Attachment ${index + 1}`}
                    style={{ objectFit: "cover", width: "100%" }}
                  />
                ) : attachment.type === "video" ? (
                  <video
                    src={attachment.url}
                    controls
                    style={{ objectFit: "cover", width: "100%" }}
                  ></video>
                ) : attachment.type === "file" ? (
                  <iframe
                    src={attachment.url}
                    title={attachment.file_name}
                    style={{ width: "100%", height: "500px", border: "none" }}
                  ></iframe>
                ) : null}
              </CarouselItem>
            ))}
          </CarouselContainer>

          {/* Show navigation only if there are multiple attachments */}
          {attachments.length > 1 && (
            <Navigation>
              <button style={{ visibility: currentIndex > 0 ? "visible" : "hidden" }} onClick={handlePrev}>
                <svg style={{transform: "rotate(180deg)", "margin-top": "-3px"}} xmlns="http://www.w3.org/2000/svg" width="40" fill="white" height="40" viewBox="0 0 16 16" id="chevron-right">
                  <path fill="white" d="M7.14645,3.89645 C6.95118,4.09171 6.95118,4.40829 7.14645,4.60355 L10.2929,7.7499975 L7.14645,10.8964 C6.95118,11.0917 6.95118,11.4083 7.14645,11.6036 C7.34171,11.7988 7.65829,11.7988 7.85355,11.6036 L11.3536,8.10355 C11.5488,7.90829 11.5488,7.59171 11.3536,7.39645 L7.85355,3.89645 C7.65829,3.70118 7.34171,3.70118 7.14645,3.89645 Z" />
                </svg>
              </button>
              <button style={{ visibility: currentIndex < attachments.length - 1 ? "visible" : "hidden" }} onClick={handleNext}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" fill="white" height="40" viewBox="0 0 16 16" id="chevron-right">
                  <path fill="white" d="M7.14645,3.89645 C6.95118,4.09171 6.95118,4.40829 7.14645,4.60355 L10.2929,7.7499975 L7.14645,10.8964 C6.95118,11.0917 6.95118,11.4083 7.14645,11.6036 C7.34171,11.7988 7.65829,11.7988 7.85355,11.6036 L11.3536,8.10355 C11.5488,7.90829 11.5488,7.59171 11.3536,7.39645 L7.85355,3.89645 C7.65829,3.70118 7.34171,3.70118 7.14645,3.89645 Z" />
                </svg>
              </button>
            </Navigation>
          )}
        </>
      )}
    </CarouselWrapper>
  );
};

export default AttachmentCarousel;

const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const CarouselContainer = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: translateX(-${(props) => props.currentIndex * 100}%);
`;

const CarouselItem = styled.div`
  min-width: 100%;
  box-sizing: border-box;
`;

const Navigation = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  button {
    background-color: rgba(0, 0, 0, 0.5);
    color: rgb(255, 255, 255);
    border: none;
    padding: 0;
    cursor: pointer;
    border-radius: 50%;
    font-size: 16px;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.2rem;
  }
`;
