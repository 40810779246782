import React from 'react';
import styled from 'styled-components';

const ButtonBase = styled.button`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  font-family: 'Roboto Slab', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0;
  vertical-align: middle;
  padding: 10px 20px;
  border-radius: 8px;
  width:100% !important;
  max-width:177px;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  @media only screen and (max-width:767px){
      font-size: 16px;
  }

`;

const PrimaryButton = styled(ButtonBase)`
  background-color: #0152B3;
  color: white;
  border: none;

  &:hover {
    background-color: #004199;
  }
`;

const SecondaryButton = styled(ButtonBase)`
  background-color: transparent;
  color: #0152B3;
  border: 2px solid #0057b8;

  &:hover {
    background-color: #0057b8;
    color: white;
  }
`;

export const OnboardingButton = ({ title, variant = 'primary', disabled = false, ...rest }) => {
  const ButtonComponent = variant === 'primary' ? PrimaryButton : SecondaryButton;
  
  return <ButtonComponent disabled={disabled} {...rest}>{title}</ButtonComponent>;
};
