import React, { useState } from 'react';
import styled from 'styled-components';
import ListModal from '../list/ListModal';

const PeopleListViewSearch = ({ data, setLists, listData, MAX_PEOPLE_ALLOWED }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [added, setAdded] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleAddUser = async (e) => {
    e.preventDefault();
    if(!data?.id) return;
    // Check if max limit is reached
    if (listData?.people?.length >= MAX_PEOPLE_ALLOWED) {
      setErrorMessage(`You have reached the maximum limit of ${MAX_PEOPLE_ALLOWED} people.`);
      setTimeout(() => setErrorMessage(null), 2000);
      return;
    }
    // Check for existing user
    const userExists = listData?.people?.some((user) => user.id === data.id);
    if (userExists) {
      setErrorMessage("User already exists in the list.");
      setTimeout(() => {
        setErrorMessage(null);
      }, 2000);
      return;
    }
    const addList = {
      "id": data?.id,
      "name": data?.name,
      "profile_url": data?.profile_url,
      "image": data?.profile_picture_url,
      "headline": data?.headline
    }
    setAdded(true);
    setLists({...listData, people: [addList, ...listData?.people]});
  }

  return (
    <Container>
      <Content>
        <WrapperContent>
          <WrapperLeft>
            <div style={{ display: 'flex' }}>
              <div>
                <AvatarStyle src={data?.profile_picture_url ?? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"} alt={data?.name} />
              </div>
              <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center' }}>
                <Title onClick={() => window.open(data.public_profile_url, "_blank")} style={{ cursor: "pointer" }}>{data?.name}</Title>
                <Subtitle>{data?.headline}</Subtitle>
              </div>
            </div>
          </WrapperLeft>
          <WrapperRight>
          <div onClick={!added ? handleAddUser : () => { }} style={{ backgroundColor: "#0073B1", color: "#fff", padding: "5px 10px", borderRadius: "4px", cursor: "pointer", fontSize: '14px' }}>{added ? "Added" : "Add"}</div>
          </WrapperRight>
        </WrapperContent>
        {errorMessage && <div style={{ color: 'red', fontSize: '13px', marginTop: '-12px', textAlign: 'center' }}>
          {errorMessage}
        </div>}
      </Content>
      {showEditModal && <ListModal setShowModal={setShowEditModal} action="Edit" data={data} setLists={setLists} />}
    </Container>
  );
};
export default PeopleListViewSearch;

const AvatarStyle = styled.img`
  "object- fit": cover;
  border-radius: 50%;
  height:40px;
  margin-right: 1rem;
`;

const Container = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #E5E5E5;
`;
const Content = styled.div`
  padding: 3px;
`;
const Title = styled.h2`
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin: 0;

  &:hover {
    text-decoration: underline;
  }
`;
const Subtitle = styled.p`
  font-size: 14px;
  color: #777;
  margin-top: 4px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const WrapperContent = styled.div`
  display:flex;
  justify-content:space-between;
 `;
const WrapperLeft = styled.div`
 `;
const WrapperRight = styled.div`
  position:relative;
  > svg{
    cursor:pointer;
    }
 `;