import React, { useState } from 'react'
import { useEffect } from 'react';
import styled from 'styled-components';
import { fetchAPI } from '../../../utils/fetch';
import { config } from '../../../utils/config';
import { Loader } from '../../../components/loader';

const PostCommentsPopup = ({ setcommentPopup, social_id, totalComments }) => {

  const [loadComments, setLoadComments] = useState([]);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [currentCursor, setcurrentCursor] = useState(null);
  const [hasMoreData, sethasMoreData] = useState(false);

  const fetchComments = async (type) => {
    if (commentsLoading) return;
    try {
      setCommentsLoading(true);
      const body = {
        social_id
      }
      if (currentCursor) {
        body.cursor = currentCursor
      }
      const response = await fetchAPI.post(
        `${config.urlPlatform}/api/v2/unipile/linkedin/getAllPostCommentsById`,
        { withCredentials: true },
        body
      );

      if (response?.data?.success) {
        if (type == "addMore") {
          setLoadComments([...loadComments, ...response?.data?.data?.items]);
        }
        else {
          setLoadComments(response?.data?.data?.items);
        }
        setcurrentCursor(response?.data?.data?.cursor);
        sethasMoreData(response?.data?.data?.paging?.page_count < response?.data?.data?.paging?.total_count);
      }
    } catch (error) {
      console.log("Failed to load comment");
    } finally {
      setCommentsLoading(false);
    }
  }

  useEffect(() => {
    fetchComments();
  }, [])

  const handleFetchMoreComments = () => {
    fetchComments("addMore");
  }

  return (
    <div>
      <ModalWrapper>
        <ModalInner onSubmit={() => { }}>
          <div>
            <h3 style={{ margin: "0.5rem" }}>Post comments ({totalComments})</h3>
            <CloseButton onClick={() => { setcommentPopup(false) }}>&#10005;</CloseButton>
          </div>
          <div className='CommentWaapers'>
            {loadComments?.length > 0 ? (
              loadComments.map((item, index) => (
                <div className='commentsDiv' key={index}>
                  <p>{item?.author}</p>
                  <p style={{ textAlign: "justify" }}>{item?.text}</p>
                </div>
              ))
            ) : (
              !commentsLoading && <p style={{ textAlign: "center" }}>No comments found.</p>
            )}

            {hasMoreData && !commentsLoading && (
              <button style={{ cursor: "pointer" }} onClick={handleFetchMoreComments}>
                Load More
              </button>
            )}

            {commentsLoading && <PageLoader />}
          </div>
        </ModalInner>
      </ModalWrapper>

    </div>
  )
}

export default PostCommentsPopup

export const PageLoader = styled.span`
    display: block;
    margin-left:auto;
    margin-right:auto;
    margin-top: 12px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
    &::before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        inset: 0px;
        border-radius: 50%;
        border: 4px solid #0a66c2;
        animation: prixClipFix 2s linear infinite ;
    }
`;

const ModalWrapper = styled.div`
  position: fixed;
  background: #00000059;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalInner = styled.form`
  padding: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  min-height: 230px;
  gap: 15px;
  border-radius: 10px;
  position: relative; /* To position the CloseButton inside this container */

  > input {
    height: 47px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border-color: #0000002e;

    &:focus-visible {
      outline: 0px;
    }
  }

  > button {
    background: #0050b2;
    padding: 15px;
    border: none;
    border-radius: 5px;
    color: #ffff;
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
    curosor:pointer;
  }

  > h3 {
    margin: 0px;
  }
  .commentsDiv {
    background: #80808000;
    border: 1px solid #0000002e;
    border-left: 0px;
    border-right: 0px;
    background: #80808000 !important;
    border: 1px solid #0000002e !important;
    border-left: 0px !important;
    border-right: 0px !important;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor:pointer;
    padding: 10px 15px !important;
}
  .commentsDiv > p {
    margin: 0px !important;
    font-size: 12px;
}  
    .commentsDiv > p:first-child {
    font-weight: 600;
    font-size: 14px;
}
    .CommentWaapers {
      height: 300px;
      overflow-y: auto;
      position:relative;
  }
      button{
       background: rgb(0, 80, 178);
        margin: auto;
        display: block;
        margin: 10px auto;
        color: #fff;
        border: 0px;
        padding: 10px ;
        border-radius: 10px}
         
`;

const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;

  &:hover {
    color: #0050b2; /* Change color on hover */
  }
`;


