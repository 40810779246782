import styled from "styled-components";
import { useState } from "react";
import checkIcon from "../../../../../assets/modal-check-icon.png";
import { fetchAPI } from "../../../../../utils/fetch";
import { config } from "../../../../../utils/config";
import { Loader } from "../../../../../components/loader";
import { size } from "../../../../../utils/helpers";

const PlansComponents = ({ setOpenPlansPopup, userData }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoadingPrm, setLoadingPrm] = useState(false);
  const [isLoadingPro, setLoadingPro] = useState(false);
  const [isLoadingThree, setLoadingThree] = useState(false);

  const handleSubscribeYearly = () => {
    setLoadingThree(true);
    fetchAPI
      .post(
        `${config.urlPlatform}/api/v2/payment/subscription`,
        {
          withCredentials: true,
        },
        {
          email: userData?.email,
          subscriptionId: userData?.stripeId,
          plan: "starter",
        }
      )
      .then((response) => {
        setLoadingThree(false);
        if (response.status !== 200) {
          throw new Error("error");
        }
        if (response.data.success === false) {
          setErrorMessage(response.data.message);
        } else {
          window.location.replace(response.data.url);
          setSuccessMessage("Successfully upgraded subscription!");
          localStorage.setItem("subscription", "pro");
        }
      })
      .catch((error) => {
        setLoadingThree(false);
        setErrorMessage("There was an issue subscribing. Please contact us!");
      });
  };

  const handleSubscribeProfesYearly = () => {

    setLoadingPro(true);
    fetchAPI
      .post(
        `${config.urlPlatform}/api/v2/payment/subscription`,
        {
          withCredentials: true,
        },
        {
          email: userData?.email,
          subscriptionId: userData?.stripeId,
          plan: "professional",
        }
      )
      .then((response) => {
        setLoadingPro(false);
        if (response.status !== 200) {
          throw new Error("error");
        }
        if (response.data.success === false) {
          setErrorMessage(response.data.message);
        } else {
          window.location.replace(response.data.url);
          setSuccessMessage("Successfully upgraded subscription!");
          localStorage.setItem("subscription", "pro");
        }
      })
      .catch((error) => {
        setLoadingPrm(false);
        setErrorMessage("There was an issue subscribing. Please contact us!");
      });
  };

  const handleSubscribeProYearly = () => {
    setLoadingPrm(true);
    fetchAPI
      .post(
        `${config.urlPlatform}/api/v2/payment/subscription`,
        {
          withCredentials: true,
        },
        {
          email: userData?.email,
          subscriptionId: userData?.stripeId,
          plan: "proMonth",
        }
      )
      .then((response) => {
        setLoadingPrm(false);
        if (response.status !== 200) {
          throw new Error("error");
        }
        if (response.data.success === false) {
          setErrorMessage(response.data.message);
        } else {
          window.location.replace(response.data.url);
          setSuccessMessage("Successfully upgraded subscription!");
          localStorage.setItem("subscription", "pro");
        }
      })
      .catch((error) => {
        setLoadingPro(false);
        setErrorMessage("There was an issue subscribing. Please contact us!");
      });
  };

  return (
    <div className="modalOverlay" style={{ zIndex: "9999" }}>
      <div
        className="modalContent"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modalHeader" onClick={() => setOpenPlansPopup(false)}>
          <span
            className="closeButton"
          >
            &times;
          </span>
        </div>
        <div className="planInnermodal">
          <div className="planWrapperDiv">
            {
              !(userData?.plan === "starter" && userData?.paidStatus === true || userData?.plan === "professional" && userData?.paidStatus === true) &&
              <PlanboxOne>
                <FreeplanPart>
                  <h2>Starter plans</h2>
                </FreeplanPart>
                <ContentList>
                  <ContentItem>
                    <CheckIcon src={checkIcon} />
                    <Text> 300 comments/month</Text>
                  </ContentItem>
                  <ContentItem>
                    <CheckIcon src={checkIcon} />
                    <Text>1 emotion</Text>
                  </ContentItem>
                  <ContentItem>
                    <CheckIcon src={checkIcon} />
                    <Text>TextTune™ - only short comments</Text>
                  </ContentItem>
                  <ContentItem>
                    <CheckIcon src={checkIcon} />
                    <Text>GenuYou™ - turn your ideas into comments </Text>
                  </ContentItem>
                  <ContentItem>
                    <CheckIcon src={checkIcon} />
                    <Text>Multi-language support</Text>
                  </ContentItem>
                  <ContentItem>
                    <CheckIcon src={checkIcon} />
                    <Text>Grammar check of your comment</Text>
                  </ContentItem>
                  <ContentItem>
                    <CheckIcon src={checkIcon} />
                    <Text>Standard support</Text>
                  </ContentItem>
                  <ContentItem>
                    <CheckIcon src={checkIcon} />
                    <Text>Commenter academy</Text>
                  </ContentItem>
                  <MonthPlan>
                    {" "} Just $12/month
                  </MonthPlan>
                  <BoxContent isButton>
                    <SubmitButton
                      className={
                        isLoadingThree ? "loading" : ""
                      }
                      onClick={handleSubscribeYearly}
                    >
                      {isLoadingThree ? (
                        <Loader />
                      ) : (
                        "UPGRADE ME TO STARTER"
                      )}
                    </SubmitButton>
                  </BoxContent>

                </ContentList>
              </PlanboxOne>
            }
            <PlanboxTwo>
              <ProPlan>Influencer PLAN
                <span>(customize your prompt)</span>
              </ProPlan>

              <ContentList>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>3000 comments/month </Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>5 emotions (+Natural™ emotion)</Text>
                </ContentItem>

                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>Full TextTune™ - short & long comments</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>GenuYou™ - turn your ideas into comments</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>Multi-language support</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>Grammar check of your comment</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>VIP support</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>Commenter academy</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>SmartReply+™ - reply to comments</Text>
                </ContentItem>

                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>Video course: "Strategic commenting"</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>
                    <strong>+Customize your prompt & voice</strong>
                  </Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text><strong>+13 commenting styles</strong></Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text><strong>+Saved comments - unlimited</strong></Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text><strong>+Engagement list - unlimited</strong></Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text><strong>+Special discounts for events, courses</strong></Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text><strong>+Commenting on Facebook and Twitter</strong></Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text><strong>+eBook - "All-in-one LI personal branding"</strong></Text>
                </ContentItem>
                <MonthPlan>
                  {" "}
                  <span>$82</span> $27/month
                </MonthPlan>
                <PerCup>
                  (less than a cup of coffee per day)
                </PerCup>
                <BoxContent isButton>
                  <SubmitButton
                    className={isLoadingPrm ? "loading" : ""}
                    onClick={handleSubscribeProYearly}
                  >
                    {isLoadingPrm ? (
                      <Loader />
                    ) : (
                      "UPGRADE ME TO PREMIUM"
                    )}
                  </SubmitButton>
                </BoxContent>
                <CancleAnytime>
                </CancleAnytime>
              </ContentList>
            </PlanboxTwo>
            {
              !(userData?.plan === "professional" && userData?.paidStatus === true) &&
              <PlanboxOne>
                <ProPlanBlackTwo>
                  Creator PLAN
                </ProPlanBlackTwo>
                <ContentList>
                  <ContentItem>
                    <CheckIcon src={checkIcon} />
                    <Text>900 comments/month </Text>
                  </ContentItem>
                  <ContentItem>
                    <CheckIcon src={checkIcon} />
                    <Text>3 emotions</Text>
                  </ContentItem>
                  <ContentItem>
                    <CheckIcon src={checkIcon} />
                    <Text> Full TextTune™ - short and long comments</Text>
                  </ContentItem>
                  <ContentItem>
                    <CheckIcon src={checkIcon} />
                    <Text>GenuYou™ - turn your ideas into comments</Text>
                  </ContentItem>
                  <ContentItem>
                    <CheckIcon src={checkIcon} />
                    <Text>Multi-language support.</Text>
                  </ContentItem>
                  <ContentItem>
                    <CheckIcon src={checkIcon} />
                    <Text>Grammar check of your comment</Text>
                  </ContentItem>
                  <ContentItem>
                    <CheckIcon src={checkIcon} />
                    <Text>Priority support</Text>
                  </ContentItem>
                  <ContentItem>
                    <CheckIcon src={checkIcon} />
                    <Text>Commenter academy</Text>
                  </ContentItem>
                  <ContentItem>
                    <CheckIcon src={checkIcon} />
                    <Text><strong>+SmartReply+™- basic comment replies</strong></Text>
                  </ContentItem>
                  <ContentItem>
                    <CheckIcon src={checkIcon} />
                    <Text><strong>+5 commenting styles</strong></Text>
                  </ContentItem>
                  <ContentItem>
                    <CheckIcon src={checkIcon} />
                    <Text><strong>+Saved comments - up to 40.</strong></Text>
                  </ContentItem>
                  <ContentItem>
                    <CheckIcon src={checkIcon} />
                    <Text><strong>+Engagement list - up to 40.</strong></Text>
                  </ContentItem>
                  <ContentItem>
                    <CheckIcon src={checkIcon} />
                    <Text><strong>+Video course: “Strategic commenting”</strong></Text>
                  </ContentItem>
                  <MonthPlan>
                    {" "}
                    <span>$36</span> $17/month
                  </MonthPlan>

                  {/* <MonthPlan> <span>$324</span> $243/year</MonthPlan> */}
                  <NewRedbtn>
                    <BoxContent isButton>
                      <SubmitButton
                        className={
                          isLoadingPro ? "loading" : ""
                        }
                        onClick={handleSubscribeProfesYearly}
                      >
                        {isLoadingPro ? (
                          <Loader />
                        ) : (
                          "UPGRADE ME TO PROFESSIONAL"
                        )}
                      </SubmitButton>
                    </BoxContent>
                    {/* <RedButtonclick>
                                      <RedLinkButton
                                        href="https://forms.gle/LkJZVf9eNju6s7C27"
                                        text="UPGRADE PROFESSIONAL PLAN"
                                        rounded={false}
                                      />
                                    </RedButtonclick> */}
                    {/* <CancleAnytime>
                                      <TextCancel>
                                        <CheckIcon
                                          style={{
                                            width: "13px",
                                            height: "13px",
                                          }}
                                          src={checkIcon}
                                        />
                                        <Text>Limited spots available</Text>
                                      </TextCancel>
                                    </CancleAnytime> */}
                  </NewRedbtn>
                </ContentList>
              </PlanboxOne>
            }
          </div>

        </div>
      </div>
      <style jsx>{`
      .modalOverlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.8);
          display: flex;
          align-items: start;
          justify-content: center;
          z-index: 99;
          {/* padding-top: 40px; */}
      }

              .modalContent {
                  background: #fff0;
                      padding: 20px;
                      border-radius: 8px;
                      width: 100%;
                      /* margin: 40px auto; */
                      height: calc(100vh - 35px);
                      overflow-y: auto;
              }

              .modalHeader {
              display: flex;
              justify-content: flex-end;
              }

              .closeButton {
              cursor: pointer;
              font-size: 30px;
              color: #fff;
              }

              .openButton {
              margin-top: 20px;
              cursor: pointer;
              }
              .planWrapperDiv{
                  display:flex;
                  justify-content: center;
                  gap: 10px;
                  margin-top: 50px;
              }
              ::-webkit-scrollbar {
                width: 0px;
                }

              .planInnermodal{
              position:relative;}

              @media only screen and (max-width:1023px){
                  .planWrapperDiv {
                          flex-direction: column;
                      }
                      .planWrapperDiv >div {
                          width: 100%;
                          margin: auto;
                          max-width: 100%;
                          box-sizing:border-box;
                      }
              }
              }

              @media only screen and (max-width:767px){
                  .modalContent {
                      height: 600px;
                    }
              }
          `}</style>
    </div >

  );
};

const PlanboxOne = styled.div`
  max-width: 389px;
  width: 100%;
  height: max-content;
  border-radius: 45px;
  border: 8px solid #ebebeb;
  background: var(--w, #fff);
  box-shadow: 0px 20px 56px 0px rgba(0, 0, 0, 0.16);
  @media only screen and (max-width: 991px) {
    max-width: 89%;
    margin: auto;
  }
  @media only screen and (max-width: 1322px) {
    width: 326px;
    flex-shrink: 0;
    ${"" /* box-sizing: border-box; */}
  }
`;
const FreeplanPart = styled.div`
  border-radius: 32px 32px 0px 0px;
  background: radial-gradient(
    142.67% 120.19% at 91.93% 3.53%,
    #d0e5ff 0%,
    #bfd3ec 0.01%,
    #dedede 48.92%,
    #dcecff 91.67%
  );
  & > h2 {
    color: #4b4b4b;
    font-family: Space Grotesk;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    /* padding: 41px 105px 55px 103px; */
    margin: 0px;
    text-align: center;
    min-height: 105px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const CheckIcon = styled.img`
  width: 14px !important;
  height: 14px !important;
`;

const ContentItem = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;
const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  @media only screen and (max-width: 480px) {
    padding: 15px;
  }
`;

const Text = styled.div`
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #12100b;
`;

const MonthPlan = styled.div`
  color: #000;
  font-family: Space Grotesk;
  font-size: 19.5px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-top: 20px;
  text-align: center;
  > span {
    color: #000;
    text-align: center;
    font-family: Space Grotesk;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
    text-decoration-line: line-through;
  }
`;

const SubmitButton = styled.a`
  background: #ff2400;
  border-radius: 500px;
  padding: 16px 60px;
  color: #fff;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 30px;
  outline: 6px solid rgba(255, 36, 0, 0.33);
  transition: 0.4s;
  text-transform: capitalize;
  min-width: 100px;
  display:inline-block;
  
  &.block {
    display: block;
    color: white;
  }
  &.cancel {
    background: transparent;
    color: #fff;
    outline: 6px solid rgba(255, 255, 255, 0.33);
    &.block {
      background: #0050b2;
      outline: 6px solid rgba(0, 36, 255, 0.33);
      &:hover {
        background-color: transparent;
      }
    }
  }
  &.loading {
    background-color: #fedd00;
    outline: 6px solid rgba(254, 221, 0, 0.42);
    transition: 0.4s;
    padding: 16px 60px;
  }
  &:hover {
    transition: 0.4s;
    background: var(--white);
    color: #ff2400;
    cursor: pointer;
  }

  span {
    display: inline-block;
  }

  @media only screen and (max-width: ${size.maxWidthTablet}) {
    padding: 12px 20px;
    text-align: center;
    outline: 3px solid rgba(255, 255, 255, 0.42);
    font-size: 16px;
    line-height: 25px;
  }
`;

const BoxContent = styled.p`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 26px;
  text-align: ${(props) => (props.isButton ? "center" : "left")};
  margin-top: ${(props) => (props.isButton ? "15px" : "0")};
  &.block {
    margin-top: 0;
  }
  &.plan {
    font-size: 1.2rem;
  }
`;

const NeeDed = styled.div`
  color: #333;
  text-align: center;
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 134.1%;
  letter-spacing: -0.8px;
  text-transform: capitalize;
  margin: 15px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;
const PlanboxTwo = styled.div`
  border-radius: 45px;
  border: 8px solid #ebebeb;
  box-shadow: 0px 20px 56px 0px rgba(0, 0, 0, 0.16);
  max-width: 389px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #fff;
  @media only screen and (max-width: 991px) {
    max-width: 89%;
    margin: auto;
  }
  @media only screen and (max-width: 1322px) {
    width: 306px;
    box-sizing: border-box;
  }
`;
const ProPlan = styled.div`
  border-radius: 40px 40px 0px 0px;

  background: radial-gradient(
    142.67% 120.19% at 91.93% 3.53%,
    #0050b2 0%,
    #2c281f 0.01%,
    #1f1b13 48.92%,
    #12100b 100%
  );
  height: 119px;
  color: var(--w, #fff);
  font-family: Space Grotesk;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${'' /* padding-right: 20px; */}

   >span{
    display:flex;
    text-align:center;
  }
`;

const Tag = styled.div`
  background: #ff2400;
  padding: 6px;
  -webkit-transform: rotate(37deg);
  -ms-transform: rotate(37deg);
  -webkit-transform: rotate(37deg);
  -ms-transform: rotate(37deg);
  transform: rotate(37deg);
  position: absolute;
  top: 26px;
  width: 45%;
  right: -46px;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  text-transform: uppercase;
  font-family: Space Grotesk;
  @media only screen and (max-width: 1323px) {
    top: 16px;
    width: 38%;
    right: -28px;
    font-size: 8px;
  }
  @media only screen and (max-width: 1200px) {
    position: absolute;
    top: 15px;
    width: 47%;
    right: -35px;
    text-align: center;
    font-size: 7px;
  }
  @media only screen and (max-width: 991px) {
    font-size: 13px;
    position: absolute;
    top: 30px;
    width: 200px;
    right: -46px;
    text-align: center;
  }

  @media only screen and (max-width: 480px) {
    font-size: 9px;
    top: 19px;
    width: 53%;
    text-align: center;
  }
`;
const PerCup = styled.div`
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const CancleAnytime = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 18px;
`;

const TextCancel = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

const ProPlanBlackTwo = styled.div`
  border-radius: 40px 40px 0px 0px;
  background: radial-gradient(
    96.02% 97.16% at 23.13% 29.46%,
    #0050b2 0%,
    #0a66c2 70.17%,
    #0050b2 100%
  );
  height: 119px;
  color: var(--w, #fff);
  font-family: Space Grotesk;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const NewRedbtn = styled.div`
  ${"" /* margin-top: 65px; */}

  @media only screen and (max-width: 767px) {
    margin-top: 0px;
  }
`;

const Toggle = styled.div`
    max-width: 1233px;
    display: flex;
    width: 100%;
    margin: auto;
    justify-content: center;

@media only screen and (max-width:767px){
  padding:0px;
  display:flex;
  justify-content:center;
  margin-bottom:40px;
}
 .toggle-container {
  display: flex;
  align-items: center;
  gap: 10px;
  width: max-content;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.toggle-label {
  font-size: 20px;
  font-weight: bold;
  color:#fff;

  @media only screen and (max-width:767px){
    font-size: 12px;
  }
}

`;


export default PlansComponents;
