import React, { useEffect, useState } from 'react'
import { OnboardingButton } from '../../../components/onboarding/OnboardingButton'
import "../Css/custom-new.css"
import OnboardingInput from '../../../components/onboarding/OnboardingInput'
import { fetchAPI } from '../../../utils/fetch'
import { config } from '../../../utils/config'
import OnboardingSelect from '../../../components/onboarding/OnboardingSelect'

const Step3 = ({ setProgress, setStep, onboardingData, setOnboardingData, toneData, stepInfo, setStepInfo }) => {

  const [formData, setFormData] = useState(onboardingData);
  const [isChanged, setIsChanged] = useState(false);
  const [error, setError] = useState(null);
  const [isFirstTime, setIsFirstTime] = useState(true);

  useEffect(() => {
    setIsFirstTime(onboardingData.tone ? false : true);
    setProgress(35);
  }, [])

  // Check if "Other" is selected or a custom tone is entered
  const isOtherSelected = formData.tone === "Other" || !toneData.includes(formData.tone);

  const handleChange = (field, value) => {
    if (formData[field] !== value) {
      setIsChanged(true);
    }
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleNext = async () => {
    if (!formData.tone) {
      setError("Please select an tone.");
      return;
    }

    setError(null);
    setStep(4);

    if (!isChanged) {
      return;
    }

    try {
      setStepInfo({ step: 1, message: "Updating tone data" })
      const response = await fetchAPI.post(
        `${config.urlPlatform}/api/v2/onboarding/add`,
        { withCredentials: true },
        {
          step: 3,
          field: "tone",
          value: formData.tone
        }
      );

      if (response?.data?.success) {
        setOnboardingData(formData); // Update global state
        setIsChanged(false); // Reset change tracking
      } else {
        setError(response?.data?.message);
        setStep(1);
      }
    } catch (error) {
      setStep(1);
      setError("Failed to update. Please try again.");
    } finally {
      setStepInfo({ step: -1, message: null })
    }
  };

  return (
    <div className='onboarding-body-global'>
      <div className='step0-onboarding-heading'>
        What’s Your Writing Style?
      </div>
      <div className='step0-onboarding-subheading'>
        AI will mimic your tone of voice. How do you want your comments to sound?
      </div>
      <div style={{ width: "90%", display: "flex", flexDirection: "column", gap: "20px" }} className='onboarding-Wrapper-container'>
        <OnboardingSelect
          label="Choose your tone"
          options={toneData}
          selectedValue={isOtherSelected ? "Other" : formData.tone}
          onChange={(value) => { setIsFirstTime(false); handleChange("tone", value) }}
          isFirstTime={isFirstTime}
        />
        {formData.tone !== "" && isOtherSelected && (
          <OnboardingInput
            placeholder="Write your own"
            value={formData.tone === "Other" ? "" : formData.tone}
            cutomHandler={true}
            onChange={(e) => handleChange("tone", e.target.value)}
          />
        )}
         {stepInfo.step === 1 && stepInfo.message && <div className='info-message-onboarding'>{stepInfo.message}</div>}
         {error && <div className='error-message-onboarding'>{error}</div>}
        <div className='onboarding-below-buttons'>
          <OnboardingButton style={{ width: "177px", height: "55px", boxSizing: "border-box" }} onClick={() => setStep(2)} title="Back" />
          <OnboardingButton style={{ width: "177px", height: "55px", boxSizing: "border-box" }} onClick={handleNext} title="Next" variant='hollow' />
        </div>
       
      </div>
    </div>
  )
}

export default Step3