import React from 'react'
import styled from 'styled-components';

const OnboardingSwitch = ({ isToggled, setIsToggled, text, ...rest }) => {
  return (
    <SwitchContainer {...rest} onClick={() => setIsToggled(!isToggled)}>
      <SwitchLabel isActive={isToggled}>{text[0] ?? "Yes"}</SwitchLabel>
      <SwitchButton isActive={isToggled} />
      <SwitchLabel isActive={!isToggled}>{text[1] ?? "No"}</SwitchLabel>
    </SwitchContainer>
  );
};

const SwitchContainer = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

const SwitchLabel = styled.span`
  font-weight: ${(props) => (props.isActive ? 'bold' : 'normal')};
  color: ${(props) => (props.isActive ? 'black' : '#5BB7E7')};
  margin: 0 8px;
  font-family: Open Sans;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: 0%;

  @media only screen and (max-width: 767px) {
    font-size: 18px;
  }
`;

const SwitchButton = styled.div`
  width: 47px;
  height: 22px;
  border-radius: 21px;
  border: 1px solid rgb(91, 183, 231);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 2px;
  position: relative;
  transition: background 0.3s ease-in-out;
  box-shadow: rgb(91, 183, 231) 2px 2px 0px 0px;

  &:before {
    content: "";
    width: 22px;
    height: 21px;
    border-radius: 50%;
    background: #5BB7E7;
    position: absolute;
    left: ${(props) => (props.isActive ? '24px' : '2px')};
    transition: left 0.3s ease-in-out;
  }
`;

export default OnboardingSwitch
