import React, { useEffect, useState } from 'react'
import { OnboardingButton } from '../../../components/onboarding/OnboardingButton'
import "../Css/custom-new.css"
import OnboardingRadio from '../../../components/onboarding/OnboardingRadio'
import OnboardingSelect from '../../../components/onboarding/OnboardingSelect'
import styled from 'styled-components'
import OnboardingMultiOptions from '../../../components/onboarding/OnboardingMultiOptions'
import OnboardingCheckbox from '../../../components/onboarding/OnboardingCheckbox'
import OnboardingInput from '../../../components/onboarding/OnboardingInput'
import OnboardingSwitch from '../../../components/onboarding/OnboardingSwitch'
import CongratulationPopup from '../Popups/Congratulation'
import { config } from '../../../utils/config'
import { fetchAPI } from '../../../utils/fetch'
import { useGetStoredData } from '../../../hooks/useGetStoreData'

const Step10 = ({ setProgress, setStep, onboardingData, setOnboardingData, industryData, toneData, ctaOptions, commentLengthOptions, engagementPreferencesOptions, aiOptions, showCongratulationsPopup, setShowCongratulationsPopup }) => {

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [favoriteInput, setFavoriteInput] = useState("");
  const [bannedInput, setBannedInput] = useState("");
  const isOtherToneSelected = onboardingData.tone === "Other" || !toneData.includes(onboardingData.tone);
  const isOtherIndustrySelected = onboardingData.industry === "Other" || !industryData.includes(onboardingData.industry);
  const storage = localStorage.getItem("userData");
  const storageJson = JSON.parse(storage);

  useEffect(() => {
    setProgress(100);
    fetchUserData();
    if(showCongratulationsPopup){
      setIsPopupOpen(true)
    }
  }, [])

  const handleChange = (field, value) => {
    setOnboardingData(prevData => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleKeyDown = (event, type) => {
    if (event.key === "Enter" && event.target.value.trim() !== "") {
      event.preventDefault();
      const newPhrase = event.target.value.trim();

      setOnboardingData((prev) => {
        const updatedPhrases = { ...prev.pharases };

        if (type === "favouritePharases") {
          if (!updatedPhrases.favouritePharases.includes(newPhrase)) {
            updatedPhrases.favouritePharases = [...updatedPhrases.favouritePharases, newPhrase];
          }
          setFavoriteInput(""); // Clear input after adding
        } else if (type === "bannedPharases") {
          if (!updatedPhrases.bannedPharases.includes(newPhrase)) {
            updatedPhrases.bannedPharases = [...updatedPhrases.bannedPharases, newPhrase];
          }
          setBannedInput(""); // Clear input after adding
        }

        return {
          ...prev,
          pharases: updatedPhrases,
        };
      });
    }
  };

  const handlePersonalizationToggle = async (value) => {
    try {
      const response = await fetchAPI.post(
        `${config.urlPlatform}/api/v2/onboarding/add`,
        { withCredentials: true },
        {
          step: 9,
          field: "personalization",
          value: value,
        }
      );

      if (!response?.data?.success) {
        console.log(response?.data?.message || "Failed to update personalization. Please try again.");
      }
    } catch (error) {
      console.log("Failed to update personalization. Please try again.");
    }
  };

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [error, setError] = useState(null);
  const [customPrompt, setCustomPrompt] = useState("");
  const [changeInPrompt, setChangeInPrompt] = useState(false);
  const storedData = useGetStoredData();

  const fetchUserData = async () => {
    try {
      setCustomPrompt("Fetching prompt data...")
      const userData = await fetchAPI.get(`${config.urlPlatform}/api/v2/auth/user?email=${storageJson.email}`, {
        withCredentials: true
      });
      setCustomPrompt(userData?.data?.prompt);
    } catch (error) {
      setCustomPrompt("Failed to fetch prompt")
    }
  };

  const handleUpdateSettings = async () => {
    setError(null);
    setIsSubmitLoading(true);
    let isSuccess = true;

    try {
      // Main API Call for Onboarding Data
      const updateOnboarding = fetchAPI.put(
        `${config.urlPlatform}/api/v2/onboarding/editAll`,
        { withCredentials: true },
        { ...onboardingData, step: 9 }
      );

      // Custom Prompt API Call (only if `changeInPrompt` is true and prompt is not empty)
      const updatePrompt =
        changeInPrompt
          ? fetchAPI.post(`${config.urlPlatform}/api/v2/auth/user/updatePrompt`,
            { withCredentials: true },
            {
              email: storageJson.email,
              prompt: customPrompt,
            })
          : Promise.resolve(); // If no prompt update, resolve immediately

      // Run both API calls in parallel
      const [onboardingResponse, promptResponse] = await Promise.all([
        updateOnboarding,
        updatePrompt,
      ]);

      // Handle errors in responses
      if (!onboardingResponse?.data?.success) {
        setError(onboardingResponse?.data?.message || "Failed to update settings. Please try again.");
        isSuccess = false;
      }

      if (promptResponse && promptResponse?.data && promptResponse?.data?.error) {
        setError("Failed to update prompt. Please try again.");
        isSuccess = false;
      } else if (promptResponse?.data) {
        // Save new prompt in local storage
        localStorage.setItem(
          "userData",
          JSON.stringify({
            ...storedData.userData,
            prompt: customPrompt,
          })
        );
      }

      // If everything is successful, open the popup
      // if (isSuccess) {
      //   setIsPopupOpen(true)
      // }
    } catch (error) {
      setError("Failed to update. Please try again.");
    } finally {
      setIsSubmitLoading(false);
    }
  };

  return (
    <div className='onboarding-body-global'>
      {/* *************************************************************************** */}

      <div className='step0-onboarding-heading laststep'  style={{ width: "100%", position: "relative" }}>
        Your AI Settings
        <OnboardingSwitch
          className="switchlast"
          style={{ position: "absolute", display: "flex", alignItems: "center", justifyContent: "center", top: "23px", right: "0", cursor: "pointer" }}
          isToggled={onboardingData.personalization}
          setIsToggled={async (value) => {
            handleChange("personalization", value);
            await handlePersonalizationToggle(value);
          }}
          text={["Off", "On"]} />
      </div>

      {/* *************************************************************************** */}

      <OnboardingRadio
        options={aiOptions}
        name="aiModel"
        selectedOption={onboardingData.aiModel}
        onChange={(value) => handleChange('aiModel', value)}
      />

      {/* *************************************************************************** */}

      <div className='step0-onboarding-subheading'>
        You can review, edit, or update your settings at any time
      </div>
      <div style={{ width: "90%", display: "flex", flexDirection: "column", gap: "20px" }}>
        <OnboardingSelect
          label="Choose your industry"
          options={industryData}
          selectedValue={isOtherIndustrySelected ? "Other" : onboardingData.industry}
          onChange={(value) => handleChange('industry', value)}
        />
        {onboardingData.industry !== "" && isOtherIndustrySelected && (
          <OnboardingInput
            placeholder="Write your own"
            value={onboardingData.industry === "Other" ? "" : onboardingData.industry}
            cutomHandler={true}
            onChange={(e) => handleChange("industry", e.target.value)}
          />
        )}
        <div style={{ border: "1px solid #00000033", margin: "12px 0" }}></div>

        {/* *************************************************************************** */}
        <CommentTitle>Comment Length Preference</CommentTitle>
        <OnboardingRadio
          name="commentLength"
          options={commentLengthOptions}
          selectedOption={onboardingData.commentLength}
          onChange={(value) => handleChange('commentLength', value)}
        />
        <div style={{ border: "1px solid #00000033", margin: "12px 0" }}></div>

        {/* *************************************************************************** */}
        <CommentTitle>AI will mimic your tone of voice. How do you want your comments to sound?</CommentTitle>
        <OnboardingSelect
          label="Choose your tone"
          options={toneData}
          selectedValue={isOtherToneSelected ? "Other" : onboardingData.tone}
          onChange={(value) => { handleChange("tone", value) }}
        />
        {onboardingData.tone !== "" && isOtherToneSelected && (
          <OnboardingInput
            placeholder="Write your own"
            value={onboardingData.tone === "Other" ? "" : onboardingData.tone}
            cutomHandler={true}
            onChange={(e) => handleChange("tone", e.target.value)}
          />
        )}

        <div style={{ border: "1px solid #00000033", margin: "12px 0" }}></div>
        {/* *************************************************************************** */}

        <CommentTitle style={{ textAlign: "center" }}>Some people love emojis, others don’t. Should AI include them?</CommentTitle>
        <OnboardingSwitch isToggled={onboardingData.emojiUsage} setIsToggled={(value) => handleChange('emojiUsage', value)} text={["No", "Yes"]} />
        <div style={{ border: "1px solid #00000033", margin: "12px 0" }}></div>

        {/* *************************************************************************** */}

        <OnboardingInput
          placeholder="Write the most common words + Hit “Enter”"
          value={favoriteInput}
          onChange={setFavoriteInput}
          onKeyDown={(e) => handleKeyDown(e, "favouritePharases")}
          title="Favorite Phrases"
        />
        <OnboardingMultiOptions
          options={onboardingData?.pharases?.favouritePharases ?? []}
          onRemove={(updatedList) =>
            setOnboardingData((prev) => ({
              ...prev,
              pharases: {
                ...prev.pharases,
                favouritePharases: updatedList,
              },
            }))
          }
        />
        <OnboardingInput
          placeholder="Write the words that the AI shouldn’t use + Hit “Enter”"
          value={bannedInput}
          onChange={setBannedInput}
          onKeyDown={(e) => handleKeyDown(e, "bannedPharases")}
          title="Banned Phrases"
        />
        <OnboardingMultiOptions
          options={onboardingData?.pharases?.bannedPharases ?? []}
          onRemove={(updatedList) =>
            setOnboardingData((prev) => ({
              ...prev,
              pharases: {
                ...prev.pharases,
                bannedPharases: updatedList,
              },
            }))
          }
        />
        <div style={{ border: "1px solid #00000033", margin: "12px 0" }}></div>

        {/* *************************************************************************** */}

        <OnboardingSelect
          label="AI can add CTAs like 'What do you think?' or keep it neutral."
          options={ctaOptions}
          selectedValue={onboardingData.callToAction}
          onChange={(value) => handleChange('callToAction', value)}
        />
        <div style={{ border: "1px solid #00000033", margin: "12px 0" }}></div>

        {/* *************************************************************************** */}

        <CommentTitle style={{ textAlign: "center", fontSize: "20px", fontWeight: "700" }}>These are the comments that you have shared with us.</CommentTitle>
        {(onboardingData?.recentComments ?? []).map((comment, index) => (
          <OnboardingInput
            key={index}
            value={comment}
            onChange={(e) => {
              const updatedComments = [...onboardingData.recentComments];
              updatedComments[index] = e.target.value;
              handleChange('recentComments', updatedComments);
            }}
            title={`Comment ${index + 1}`}
            cutomHandler={true}
          />
        ))}
        <div style={{ border: "1px solid #00000033", margin: "12px 0" }}></div>

        {/* *************************************************************************** */}
        <CommentTitle style={{ textAlign: "center" }}>AI will prioritize comments on topics that interest you most.</CommentTitle>
        <OnboardingCheckbox
          options={engagementPreferencesOptions}
          selectedOptions={onboardingData.engagementPreferences ?? []}
          onChange={(value) => handleChange('engagementPreferences', value)}
        />
        <div style={{ border: "1px solid #00000033", margin: "12px 0" }}></div>

        {/* *************************************************************************** */}
        <OnboardingInput
          placeholder="Write your custom prompt"
          value={customPrompt}
          onChange={(e) => { setChangeInPrompt(true); setCustomPrompt(e.target.value) }}
          cutomHandler={true}
        />

        {/* Error Message */}
        {error && <div className="error-message-onboarding">{error}</div>}

        <div className="onboarding-below-buttons">
          <OnboardingButton
            onClick={handleUpdateSettings}
            style={{
              maxWidth: "312px",
              height: "55px",
              boxSizing: "border-box",
            }}
            title={isSubmitLoading ? "Updating..." : "Update Settings"}
            disabled={isSubmitLoading}
          />
        </div>

      </div>
      {isPopupOpen && <CongratulationPopup onClose={() => { setIsPopupOpen(false); setShowCongratulationsPopup(false) }} />}
    </div>
  )
}

export default Step10

const CommentTitle = styled.div`
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0;
`;