import React from "react";
import styled from "styled-components";
import PostTextComponent from "./PostsTextComponent";

const RepostedPost = ({ repost }) => {
  function getFormatedText(text) {
    if (typeof text !== 'string') {
      return '';
    }
    return text.replace(/\n/g, '<br>');
  }

  return (
    <RepostWrapper>
      <RepostHeader>
        <AuthorName>{repost?.author?.name}</AuthorName>
        <PostDate>{repost?.date}</PostDate>
      </RepostHeader>
      <PostTextComponent text={getFormatedText(repost?.text)} />
    </RepostWrapper>
  );
};

export default RepostedPost;

const RepostWrapper = styled.div`
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #f5f5f5;
`;

const RepostHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const AuthorName = styled.span`
  color: #0073b1;
`;

const PostDate = styled.span`
  color: #666;
  font-size: 0.8rem;
`;

const RepostContent = styled.p`
  font-size: 0.9rem;
  color: #333;
`;
