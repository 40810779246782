import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";

const StyledTextArea = styled.textarea`
  width: 100%;
  // min-height: 60px; 
  border-radius: 20px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(91, 183, 231);
  box-shadow: rgb(91, 183, 231) 10px 10px 0px 0px;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0px;
  padding: 17px 20px ;
  box-sizing: border-box;
  resize: none;
  overflow-y: hidden;
  transition: height 0.2s ease-out;
  resize: none;

  @media only screen and (max-width:767px){
      box-shadow: rgb(91, 183, 231) 5px 5px 0px 0px;
      padding: 10px 15px !important;
      font-size: 16px;
    }
    

  &::placeholder {
    color: #AAAAAA;
  
   
  }

  &:focus-visible {
    outline: none;
  }
   
}
`;

const Title = styled.div`
  font-family: Open Sans;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0;
  margin-bottom: 8px;
`;

const WarningText = styled.div`
  font-family: Open Sans;
  font-weight: 500;
  font-size: 14px;
  color: red;
  margin-top: 5px;
`;

const OnboardingInput = ({ title, placeholder, value, onChange, cutomHandler = false, ...rest }) => {
  const textAreaRef = useRef(null);
  const [warning, setWarning] = useState("");

  const adjustHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto"; 
      let newHeight = textAreaRef.current.scrollHeight;

      if (typeof value === "string" && !value.trim()) {
        newHeight = 60;
      }

      textAreaRef.current.style.height = `${newHeight}px`;
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [value]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    const wordCount = newValue.trim().length;

    if (wordCount > 500) {
      setWarning("Word limit exceeded! Max 500 words allowed.");
    } else {
      setWarning("");
      onChange(newValue);
    }
    adjustHeight();
  };

  return (
    <>
      {title && <Title>{title}</Title>}
      <StyledTextArea
        ref={textAreaRef}
        placeholder={placeholder}
        value={value}
        onChange={cutomHandler ? onChange : handleChange}
        {...rest}
      />
      {warning && <WarningText>{warning}</WarningText>}
    </>
  );
};

export default OnboardingInput;
