import { useState, useEffect } from "react";
import styled from "styled-components";
import bgArrows from "../../assets/Vector.png";

const ProgressBarContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  flex-direction:column;
   @media only screen and (max-width: 767px) {
      width: 80%;
   }
`;

const StepLabel = styled.div`
  display: flex;
  align-items: center;
  max-width:177px;
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: center;
  color:#fff;
  height:39px;
  justify-content:center;
  background-image: url(${bgArrows});
  background-size: contain;
  background-repeat: no-repeat;
  position:relative;
  margin-left: -80px;
  transition: left 0.5s ease-in-out;

  @media only screen and (max-width: 767px) {
    max-width: 120px;
    font-family: Poppins;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    text-align: center;
    color: rgb(255, 255, 255);
    height: 26px;
    -webkit-box-pack: center;
    justify-content: center;
    margin-left: -50px;
  }

  &&::after{
    content:"";
    background: #FFFFFF;
    width:2px;
    height:38px;
    box-shadow: 0px 5px 20px 0px #2A2D6533;
    top:100%;
    position:absolute;
  }

  
  &&::before{
      width: 15px;
      content: "";
      background-color: rgb(0, 87, 184);
      border-radius: 50%;
      position: absolute;
      top: calc(100% + 35px);
      height: 15px;
      z-index: 1;
      border: 5px solid #FFFFFF;
  }
`;

const ProgressBarWrapper = styled.div`
  flex: 1;
  height: 15px;
  background-color: #d1d5db;
  border-radius: 3px;
  margin-left: 16px;
  position: relative;
  overflow: hidden;
  min-height: 15px;
  margin-top: 40px;
`;

const ProgressFill = styled.div`
  height: 100%;
  background: #0450AC;
  border-radius: 3px;
  min-height: 15px;
  transition: width 0.5s ease-in-out;
`;

export default function OnboardingProgress({ progress, step }) {
  
  return (
    <ProgressBarContainer>
      {progress !== 100 && <StepLabel style={{ left: `${progress}%` }}>Step {step < 9 && '0'}{step+1} : <strong>{progress}%</strong></StepLabel>}
      <ProgressBarWrapper>
        <ProgressFill style={{ width: `${progress}%` }} />
      </ProgressBarWrapper>
    </ProgressBarContainer>
  );
} 	