import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { config, urlPaths } from '../../../../utils/config';
import styled from 'styled-components';
import { fetchAPI } from '../../../../utils/fetch';
import PeopleListView from './PeopleListView';
import PeopleListViewSearch from './PeopleListSearch';
import ListModalForPeople from './ListModalForPeople';
import BulkUploadPopup from './BulkUploadPopup';

const PeopleList = () => {

  const navigate = useNavigate();
  const [keywordloading, setkeywordLoading] = useState(false);
  const [data, setData] = useState([]);
  const { id } = useParams();
  const MAX_PEOPLE_ALLOWED = 27;

  const fetchData = async () => {
    setkeywordLoading(true);
    try {
      const response = await fetchAPI.get(
        `${config.urlPlatform}/api/v2/unipile/linkedin/people/getListOfPeople/${id}`,
        { withCredentials: true }
      );

      if (response?.data?.success) {
        setData(response?.data?.data);
      }
    } catch (error) {
      console.log("Error fetching filters", error);
    } finally {
      setkeywordLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const [searchLoading, setSearchLoading] = useState(false);
  const [searchData, setSearch] = useState([]);
  const [searchInputData, setsearchInputData] = useState("");
  const [debouncedSearchInput, setDebouncedSearchInput] = useState("");

  const fetchSearchData = async () => {
    setSearchLoading(true);
    try {
      let url = `${config.urlPlatform}/api/v2/unipile/linkedin/search`;
      const body = {
        "category": "people"
      }
      if (debouncedSearchInput.trim() !== "") {
        body.keywords = debouncedSearchInput;
      }
      const response = await fetchAPI.post(
        url,
        { withCredentials: true },
        body
      );

      if (response?.data?.success) {
        setSearch(response?.data?.data?.items);
      }
    } catch (error) {
      console.log("Error fetching data");
    } finally {
      setSearchLoading(false);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchInput(searchInputData);
    }, 500);

    return () => clearTimeout(handler);
  }, [searchInputData]);

  useEffect(() => {
    if (debouncedSearchInput.trim() === "") {
      setSearch([]);
      return;
    }

    fetchSearchData();
  }, [debouncedSearchInput]);

  const [updateButtonText, setupdateButtonText] = useState("Update List")
  const [updateError, setUpdateError] = useState(null);
  const handleUpdateList = async () => {
    try {
      setupdateButtonText("Updating...")
      setUpdateError(null);
      const { id, ...restData } = data
      let url = `${config.urlPlatform}/api/v2/unipile/linkedin/people/edit/${id}`;
      const response = await fetchAPI.put(
        url,
        { withCredentials: true },
        restData
      );

      if (response?.data?.success) {
        setupdateButtonText("List updated");
      }
      else{
        setUpdateError(response?.data?.message ?? "Failed to update list");
      }
    } catch (error) {
      setupdateButtonText("Update Failed");
      setUpdateError(error?.response?.data?.message ?? "Failed to update list");
    } finally {
      setTimeout(() => {
        setupdateButtonText("Update List");
      }, 2000);
    }
  }
  const [showModal, setShowModal] = useState(false);
  const [isBulkUploadPopup, setisBulkUploadPopup] = useState(false);

  return (
    <Container style={{ display: "flex", justifyContent: "center", marginTop: "2rem", fontFamily: '"Exo 2", serif' }}>
      <div style={{ width: "70%" }}>
        <div>
          <h1 style={{ marginBottom: "5px" }}>People lists</h1>
        </div>
        <div style={{ width: "90%", marginLeft: "3%", marginTop: "1rem" }}>
          <div onClick={() => navigate(urlPaths.peopleLists)} style={{ display: "inline-flex", alignItems: "center", color: "#0a66c2", marginTop: "1rem", cursor: "pointer" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-left w-4 h-4"><path d="m12 19-7-7 7-7"></path><path d="M19 12H5"></path></svg>
            Back to People
          </div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", position: "relative" }}>
            <SearchInput
              type="text"
              placeholder="Search and add people"
              value={searchInputData}
              onChange={(e) => setsearchInputData(e.target.value)}
              style={{ paddingRight: "30px" }} 
              disabled={data?.people?.length >= MAX_PEOPLE_ALLOWED} 
            />
            {searchInputData && (
              <button
                onClick={() => { setsearchInputData(""); setSearch([]) }}
                style={{
                  position: "absolute",
                  left: "88%",
                  top: "50%",
                  transform: "translateY(-50%)",
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                  fontSize: "16px",
                  color: "#999",
                  outline: "none"
                }}
              >
                ✖
              </button>
            )}

            <div style={{ position: "absolute", width: "80%", top: "3.1rem", left: "0", right: "0", margin: "auto", zIndex: 50, display: searchLoading || searchData && searchData.length > 0 ? "flex" : "none", flexDirection: "column", justifyContent: "start", maxHeight: "378px", overflowY: "auto", padding: "15px", background: "#fff", boxShadow: "2px 6px 7px 2px #00000030", borderBottomRightRadius: "10px", borderBottomLeftRadius: "10px", border: "1px solid #0000003b", gap: "5px", boxSizing: "border-box", border: searchData && searchData.length > 0 ? "none" : "1px solid #808080b3" }}>
              {
                searchLoading ? (
                  <PageLoader />
                ) : (
                  searchData && searchData.length > 0 ? (
                    searchData.map((list, index) => (
                      <>
                        <PeopleListViewSearch key={`${list.id}-${index}`} data={list} setLists={setData} listData={data} MAX_PEOPLE_ALLOWED={MAX_PEOPLE_ALLOWED}/>
                      </>
                    ))
                  ) : debouncedSearchInput && debouncedSearchInput.trim() !== "" ? (
                    <div>No lists available</div>
                  ) : null
                )
              }
            </div>

          </div>
          <div style={{ margin: "1.5rem auto", width: "80%", display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h3 style={{ margin: 0 }}>{keywordloading ? "Loading.." : data.list_name}</h3>
              <div onClick={() => setShowModal(true)} style={{ marginLeft: "10px", color: "#0a66c2", cursor: "pointer", fontSize: "14px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-pencil mr-2 h-4 w-4"><path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z"></path><path d="m15 5 4 4"></path></svg>
                Edit name
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "7px" }}>
              <div>
                Total People: {data?.people?.length ? data?.people?.length : ".."} / {MAX_PEOPLE_ALLOWED}
              </div>
              <div onClick={() => setisBulkUploadPopup(true)} style={{ backgroundColor: "#0a66c2", color: "white", padding: "5px 10px", borderRadius: "5px", cursor: "pointer" }}>Bulk Upload</div>
              {isBulkUploadPopup && <BulkUploadPopup setisBulkUploadPopup={setisBulkUploadPopup} peopleListId={id} fetchData={fetchData} />}

              <div onClick={updateButtonText !== "Update List" ? null : handleUpdateList} style={{ backgroundColor: "#0a66c2", color: "white", padding: "5px 10px", borderRadius: "5px", cursor: "pointer", opacity: updateButtonText !== "Update List" ? 0.5 : 1 }}>{updateButtonText}</div>
            </div>
          </div>
          {/* {updateError && <div style={{ display: "flex", justifyContent: "center", marginTop: "-23px", color: "red" }}>
            {updateError}
          </div>} */}
          <div style={{ "height": "calc(100dvh - 430px)", "overflowY": "auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
            {
              keywordloading ? (
                <PageLoader />
              ) : (
                data?.people && data.people.length > 0 ? (
                  data.people.map((list, index) => (
                    <PeopleListView key={index} data={list} setLists={setData} listData={data} />
                  ))
                ) : (
                  <div>Search and add people to this list</div>
                )
              )
            }
          </div>
        </div>
      </div>
      {showModal && <ListModalForPeople setShowModal={setShowModal} action="Edit" setData={setData} data={data} />}
    </Container>
  )
}

const SearchInput = styled.input`
  width: 78%; 
  height: 43px;
  padding-left: 16px;
`;

export const PageLoader = styled.span`
    display: block;
    margin-left:auto;
    margin-right:auto;
    margin-top: 2rem;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
    &::before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        inset: 0px;
        border-radius: 50%;
        border: 4px solid #0a66c2;
        animation: prixClipFix 2s linear infinite ;
    }
`;

const Container = styled.div`
  height: calc(100dvh - 180px);
`;

export default PeopleList
