import styled from "styled-components";

const OnboardingMultiOptions = ({ options = ["hello", "world"], onRemove }) => {
  const removeOption = (index) => {
    if (onRemove) {
      onRemove(options.filter((_, i) => i !== index));
    }
  };

  return (
    <OptionsContainer>
      {options.map((option, index) => (
        <Option key={index}>
          <span>{option}</span>
          <CloseButton onClick={() => removeOption(index)}>×</CloseButton>
        </Option>
      ))}
    </OptionsContainer>
  );
};

export default OnboardingMultiOptions;

const OptionsContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  background-color: #0450AC;
  color: white;
  padding: 8px 12px;
  border-radius: 9999px;
`;

const CloseButton = styled.button`
  margin-left: 8px;
  background-color: white;
  color: #0450AC;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  border: none;
  font-size: 16px;
`;
