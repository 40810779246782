import React, { useRef, useState } from "react";
import styled from "styled-components";
import GeneratedTick from "../../assets/GeneratedCommentTick.svg"
import OnboardingInput from './OnboardingInput';

const OnboardingGeneratedComment = ({ comment, isApproved, onApprove, onEdit }) => {

  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);

  const handleEditClick = () => {
    setIsEditing(true);
    if(isApproved){
      onApprove();
    }
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        const length = inputRef.current.value.length;
        inputRef.current.setSelectionRange(length, length); 
      }
    }, 0);
  };

  return (
    <CommentContainer>
      <Label>Your generated comment</Label>

      <div style={{ position: "relative" }}>
        <OnboardingInput
          ref={inputRef}
          value={comment}
          onChange={(e) => onEdit(e.target.value)}
          cutomHandler={true}
          style={{ paddingRight: "40px" }}
          disabled={!isEditing}
        />
        {
          isApproved &&
          <CheckIcon onClick={onApprove}>
            <img src={GeneratedTick} alt="Comment-Tick" />
          </CheckIcon>
        }
      </div>

      {
        comment !== "Generating Comment..." &&
        <Actions>
          {
            isApproved ?
              <ActionButton onClick={onApprove} style={{ color: "#929292", borderBottom: "2px solid #929292" }} >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="10" cy="10" r="10" fill="currentColor" />
                  <path d="M8.95402 14L6 11.2027L7.34619 9.92793L8.95402 11.455L13.6538 7L15 8.27477L8.95402 14Z" fill="white" />
                </svg>
                Approved
              </ActionButton>
              :
              <ActionButton onClick={() => { onApprove(); setIsEditing(false) }} style={{ color: "#0450AC", borderBottom: "2px solid #0450AC" }} >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="10" cy="10" r="10" fill="currentColor" />
                  <path d="M8.95402 14L6 11.2027L7.34619 9.92793L8.95402 11.455L13.6538 7L15 8.27477L8.95402 14Z" fill="white" />
                </svg>
                Approve
              </ActionButton>
          }
          <ActionButton onClick={handleEditClick} style={{ color: isApproved ? "#929292" : "#0450AC", borderBottom: isApproved ? "2px solid #929292" : "2px solid #0450AC" }} >
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.32 3.17505H2C0.895 3.17505 0 4.12405 0 5.29305V15.881C0 17.051 0.895 17.999 2 17.999H13C14.105 17.999 15 17.051 15 15.881V8.13105L11.086 12.275C10.7442 12.6405 10.2991 12.8931 9.81 12.999L7.129 13.567C5.379 13.937 3.837 12.304 4.187 10.452L4.723 7.61305C4.82 7.10105 5.058 6.63005 5.407 6.26105L8.32 3.17505Z" fill="currentColor" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8457 1.31704C16.7446 1.06107 16.5964 0.826345 16.4087 0.625042C16.2242 0.428171 16.0017 0.270677 15.7547 0.162042C15.5114 0.0551784 15.2485 0 14.9827 0C14.7169 0 14.454 0.0551784 14.2107 0.162042C13.9637 0.270677 13.7412 0.428171 13.5567 0.625042L13.0107 1.20304L15.8627 4.22304L16.4087 3.64404C16.5983 3.44427 16.7468 3.20914 16.8457 2.95204C17.0517 2.42651 17.0517 1.84257 16.8457 1.31704ZM14.4497 5.72004L11.5967 2.69904L6.8197 7.75904C6.74922 7.83414 6.70169 7.92782 6.6827 8.02904L6.1467 10.869C6.0767 11.239 6.3857 11.565 6.7347 11.491L9.4167 10.924C9.51429 10.9023 9.60311 10.8518 9.6717 10.779L14.4497 5.72004Z" fill="currentColor" />
            </svg>
            Edit
          </ActionButton>
        </Actions>
      }
    </CommentContainer>
  );
};

export default OnboardingGeneratedComment;

const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 30px 0;
`;

const Label = styled.div`
  font-family: Open Sans;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0;
`;

const CommentText = styled.span`
  font-family: Open Sans;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: 0%;
`;

const CheckIcon = styled.span`
  color: ${({ isApproved }) => (isApproved ? "#5bb7e7" : "#ccc")};
  font-size: 16px;
  position: absolute;
  right: 12px;
  top: 19px;
`;

const Actions = styled.div`
  display: flex;
  gap: 12px;
  font-size: 14px;
  color: gray;
  justify-content: center;
`;

const ActionButton = styled.button`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 6px;
  background: none;
  cursor: pointer;
  color: inherit;
  font-family: "Roboto Slab";
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  vertical-align: middle;
  text-transform: uppercase;
  border-top: none;
  border-right: none;
  border-left: none;
  padding: 1px 0;
  border-image: initial;
  border-bottom: 2px solid #929292;

  &:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
`;
