import React from 'react'
import styled from 'styled-components';
import { fetchAPI } from '../../../utils/fetch';
import { config } from '../../../utils/config';
import { useState, useEffect } from 'react';
import PostsMainPage from './PostsMainPage';

const Posts = () => {

  const [pageLoading, setPageLoading] = useState(false);
  const [showPostsPage, setShowPostsPage] = useState(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        setPageLoading(true);

        const response = await fetchAPI.get(
          `${config.urlPlatform}/api/v2/unipile/auth/getUserProfile`,
          { withCredentials: true }
        );

        if (response?.data?.success) {
          setShowPostsPage(true);
        }
      } catch (error) {
        console.log("Error getting profile");
        setShowPostsPage(false);
      } finally {
        setPageLoading(false);
      }
    }

    fetchUserProfile();
  }, [])

  const [isConnectButtonDisabled, setisConnectButtonDisabled] = useState(false);
  const [errorMessage, seterrorMessage] = useState(null);

  const handleConnectClick = async () => {
    try {
      setisConnectButtonDisabled(true);
      seterrorMessage(null);

      const response = await fetchAPI.get(
        `${config.urlPlatform}/api/v2/unipile/auth/wizard/connectLinkedin`,
        { withCredentials: true }
      );

      if (response?.data?.success && response?.data?.data?.object === "HostedAuthUrl") {
        window.location.assign(response?.data?.data?.url);
      }
    } catch (error) {
      seterrorMessage("Error connecting to LinkedIn");
    } finally {
      const timer = setTimeout(() => {
        setisConnectButtonDisabled(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  };

  if (pageLoading) {
    return (
      <Container>
        <PageLoader />
      </Container>
    );
  }

  return (
    <div style={{ overflow: "auto" }}>
      {!showPostsPage ?
        <Container>
          <div>
            <ConnectButton
              onClick={handleConnectClick}
              disabled={isConnectButtonDisabled}
              style={{
                opacity: isConnectButtonDisabled ? 0.5 : 1,
                cursor: isConnectButtonDisabled ? "not-allowed" : "pointer"
              }}
            >
              {isConnectButtonDisabled ? "Connecting..." : "Connect your LinkedIn Account"}
            </ConnectButton>
            {errorMessage && <ErrorMessage style={CenterStyle}>{errorMessage}</ErrorMessage>}
            <YouTubeContainer>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/5tDIllxMY-E"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </YouTubeContainer>
          </div>
        </Container>
        :
        <PostsMainPage />
      }
    </div>
  );
}

const YouTubeContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
`;

const CenterStyle = {
  "display": "flex",
  "justify-content": "center",
  "align-items": "center"
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100dvh - 180px);
`;

const ConnectButton = styled.div`
  text-align: center;
  background-color: #0a66c2;
  cursor: pointer;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
`;

const ErrorMessage = styled.div`
    color:red;
    font-weight: 600;
    margin-bottom: 2rem;
    animation: fade-up-quick .5s;
`;

export const PageLoader = styled.span`
    display: block;
    margin-left:auto;
    margin-right:auto;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
    &::before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        inset: 0px;
        border-radius: 50%;
        border: 4px solid #0a66c2;
        animation: prixClipFix 2s linear infinite ;
    }
`;

export default Posts
