import React from 'react'
import styled from 'styled-components';
import { useState } from "react";
import { fetchAPI } from '../../../../utils/fetch';
import { config } from '../../../../utils/config';
import SampleExcelFile from '../../../../assets/Sample_people_upload_file-commenter.ai.xlsx';

const BulkUploadPopup = ({ setisBulkUploadPopup, peopleListId, fetchData }) => {

  const [actionType, setactionType] = useState("Text");
  const [textInput, setTextInput] = useState("");
  const [fileInput, setFileInput] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const handleUpload = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setMessage({ type: "info", message: "Note: Uploading might take a long time depending on the data size." });
      let formData = new FormData();
      const body = {}
      if (actionType === "Text" && textInput.trim()) {
        body.linkedinurls = textInput;
      } else if (actionType === "Files" && fileInput) {
        formData.append("file", fileInput);
      } else {
        setMessage({ type: "error", message: "Please provide valid input." });
        return;
      }

      const response = await fetchAPI.post(
        `${config.urlPlatform}/api/v2/unipile/linkedin/people/bulkUpload?peopleListId=${peopleListId}`,
        { withCredentials: true },
        actionType === "Files" ? formData : body
      );

      if (response?.data.success) {
        fetchData();
        setisBulkUploadPopup(false);
        setMessage(null);
      }
      else {
        setMessage({ type: "error", message: response?.data?.message ?? "Failed to upload profiles" });
      }
    } catch (error) {
      setMessage({ type: "error", message: error?.response?.data?.message ?? "Failed to upload data" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <ModalWrapper>
        <ModalInner onSubmit={() => { }}>
          <div>
            <h3 style={{ margin: "0.5rem" }}>Bulk Upload</h3>
            <CloseButton onClick={() => { setisBulkUploadPopup(false) }}>&#10005;</CloseButton>
          </div>
          <div className='bulkupload'>
            <div className='wrapperBulkUpload'>
              <div className="planInnermodal">
                <div className='TogglesDiv'>
                  <p onClick={() => { setactionType("Text") }} className={`textButton ${actionType === "Text" && "active"}`}>Text</p>
                  <p onClick={() => { setactionType("Files") }} className={`textButtonFiles ${actionType === "Files" && "active"}`}>Files</p>
                </div>
                <div className='ContentDiv'>
                  {actionType === "Text" &&
                    <>
                      <textarea
                        style={{ resize: "vertical" }}
                        placeholder='Ex: https://www.linkedin.com/in/commenter-ai/'
                        value={textInput}
                        onChange={(e) => setTextInput(e.target.value)}
                        required
                      ></textarea>
                      <div style={{ fontSize: "0.7rem", color: "#555", marginTop: "-3px" }}>
                        Note: Please add profiles, separated by commas.
                      </div>
                    </>
                  }
                  {
                    actionType === "Files" &&
                    <>
                      <div className='UploadBulkFiles'>
                        <input
                          type='file'
                          accept='.xlsx'
                          className='uploadBulkfile'
                          onChange={(e) => setFileInput(e.target.files[0])}
                          required
                        />
                      </div>
                      <div style={{ fontSize: "0.7rem", color: "#555" }}>
                        Note: Make sure all the links are under the "LinkedIn Urls" column.
                        <br />
                        <a href={SampleExcelFile} download={"Sample People Excel File"} style={{ color: "rgb(0, 80, 178)", textDecoration: "none" }}>
                          Download Sample File
                        </a>
                      </div>
                    </>
                  }
                  <button disabled={isLoading} style={{ cursor: "pointer", opacity: isLoading ? 0.5 : 1 }} onClick={handleUpload} >{isLoading ? "Uploading..." : "Upload Profiles"}</button>
                  {message && <div style={{ fontSize: "0.7rem", color: "#555", marginTop: "-7px", color: message?.type === "error" ? "red" : "black" }}>
                    {message?.message}
                  </div>}
                </div>
              </div>
            </div>
          </div>

        </ModalInner>
      </ModalWrapper>

    </div>
  )
}

export default BulkUploadPopup

const ModalWrapper = styled.div`
  position: fixed;
  background: #0000008f;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalInner = styled.form`
  padding: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  min-height: 230px;
  gap: 15px;
  border-radius: 10px;
  position: relative; /* To position the CloseButton inside this container */

  > input {
    height: 47px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border-color: #0000002e;

    &:focus-visible {
      outline: 0px;
    }
  }

  > button {
    background: #0050b2;
    padding: 15px;
    border: none;
    border-radius: 5px;
    color: #ffff;
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
    curosor:pointer;
  }

  > h3 {
    margin: 0px;
  }
  .commentsDiv {
    background: #80808000;
    border: 1px solid #0000002e;
    border-left: 0px;
    border-right: 0px;
    background: #80808000 !important;
    border: 1px solid #0000002e !important;
    border-left: 0px !important;
    border-right: 0px !important;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor:pointer;
    padding: 10px 15px !important;
}
  .commentsDiv > p {
    margin: 0px !important;
    font-size: 12px;
}  
    .commentsDiv > p:first-child {
    font-weight: 600;
    font-size: 14px;
}
    .CommentWaapers {
      height: 300px;
      overflow-y: auto;
      position:relative;
  }
      button{
       background: rgb(0, 80, 178);
        margin: auto;
        display: block;
        margin: 10px auto;
        color: #fff;
        border: 0px;
        padding: 10px ;
        border-radius: 10px}

      .TogglesDiv {
          display: flex;
          justify-content: center;
          gap: 1px;
          width: max-content;
          margin: auto;
          padding: 5px;
          border-radius: 5px;
          border: 1px solid #0000001f;
      }
      .TogglesDiv p {
        margin: 0px;
        border: 1px solid #00000087;
        padding: 5px 20px;
        box-sizing: border-box;
        border-radius: 5px;
        cursor:pointer;
      }
        .active {
          background: #0050b2;
          color: #fff;
          border-color: #0050b2;
      }
      .ContentDiv {
    margin: 10px 0;
}
    textarea {
    height: 100px;
    width: 100%;
    border: 1px solid #00000040;
        padding: 10px;
    box-sizing: border-box;
}
    .UploadBulkFiles {
    position: relative;
}
    input.uploadBulkfile {
   cursor: pointer;
}
    .UploadBulkFiles {
    height: 47px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    padding: 10px;
    box-sizing: border-box;
    border-radius: 4px;
}
    .UploadBulkFiles span {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    display: block;
}
    button {
    background: #0050b2;
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: #ffff;
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
    curosor:pointer;
    width:100%;
  }
         
`;


const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;

  &:hover {
    color: #0050b2; /* Change color on hover */
  }


`;
const Toggle = styled.div`
    max-width: 1233px;
    display: flex;
    width: 100%;
    margin: auto;
    justify-content: center;

@media only screen and (max-width:767px){
  padding:0px;
  display:flex;
  justify-content:center;
  margin-bottom:40px;
}
 .toggle-container {
  display: flex;
  align-items: center;
  gap: 10px;
  width: max-content;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.toggle-label {
  font-size: 20px;
  font-weight: bold;
  color:#000;

  @media only screen and (max-width:767px){
    font-size: 12px;
  }
}
`;