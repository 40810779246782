import React from "react";
import styled from "styled-components";

const CheckboxContainer = styled.div`
  display: flex;
  gap: 30px 10px;
  -webkit-box-pack: justify;
  justify-content: center;
  flex-wrap: wrap;
  width: calc(100% + 40px);
    margin-left: -20px;
  @media only screen and (max-width: 767px) {
    justify-content: start;
    width: 100%;
    margin-left: auto;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: black;
`;

const StyledCheckbox = styled.input`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #5BB7E7;
  border-radius: 4px;
  margin-right: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  

  &:checked {
    background-color: #5BB7E7;
    border: none;
  }

  &:checked::before {
    content: "✔";
    font-size: 14px;
    color: white;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const OnboardingCheckbox = ({ options, selectedOptions, onChange }) => {
  const handleCheckboxChange = (value) => {
    const newSelected = selectedOptions.includes(value)
      ? selectedOptions.filter((item) => item !== value)
      : [...selectedOptions, value];
    onChange(newSelected);
  };

  return (
    <CheckboxContainer>
      {options.map((option, index) => (
        <Label key={index}>
          <StyledCheckbox
            type="checkbox"
            checked={selectedOptions.includes(option.value)}
            onChange={() => handleCheckboxChange(option.value)}
          />
          {option.label}
        </Label>
      ))}
    </CheckboxContainer>
  );
};

export default OnboardingCheckbox;
