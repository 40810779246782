import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { fetchAPI } from '../../../../utils/fetch';
import { config } from '../../../../utils/config';

const ListModal = ({ setShowModal, action, data, setLists }) => {

  const [keywordData, setKeywordData] = useState({ name: "", values: "" });
  const [submitLoading, setsubmitLoading] = useState(false);

  useEffect(() => {
    if (data) {
      setKeywordData({ name: data?.list_name, values: data?.keywords?.join(",") });
    }
  }, [data])

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setsubmitLoading(true);
      let response;
      if (data) {
        response = await fetchAPI.put(
          `${config.urlPlatform}/api/v2/unipile/linkedin/keywords/edit/${data?.id}`,
          { withCredentials: true },
          { ...keywordData, values: keywordData.values.split(",") }
        );
      }
      else {
        response = await fetchAPI.post(
          `${config.urlPlatform}/api/v2/unipile/linkedin/keywords/add`,
          { withCredentials: true },
          { ...keywordData, values: keywordData.values.split(",") }
        );
      }

      if (response?.data?.success) {
        if (action === "Edit") {
          setLists((prevLists) => prevLists.map((list) => list?.id === data?.id ? { ...list, list_name: keywordData.name, keywords: keywordData.values.split(",") } : list));
          setShowModal(false);
        }
        else {
          if (response?.data?.data?.id) {
            setLists((prevLists) => [{ id: response?.data?.data?.id, list_name: keywordData.name, keywords: keywordData.values.split(",") }, ...prevLists]);
          }
          setShowModal(false);
        }
      }
    } catch (error) {
      console.log("Failed to submit data");
    } finally {
      setsubmitLoading(false);
    }
  }

  return (
    <ModalWrapper>
      <ModalInner onSubmit={handleSubmit}>
        <div>
          <h3 style={{ margin: "0.5rem" }}>{action} your list</h3>
          <CloseButton onClick={() => setShowModal(false)}>&#10005;</CloseButton>
        </div>
        <input value={keywordData.name} onChange={(e) => setKeywordData({ ...keywordData, name: e.target.value })} placeholder='List Name' required />
        <input value={keywordData.values} onChange={(e) => setKeywordData({ ...keywordData, values: e.target.value })} placeholder='Keywords e.g: Keyword 1, Keyword 2' required />
        <button style={{ cursor: "pointer", opacity: submitLoading ? 0.5 : 1 }} type='submit' disabled={submitLoading} >{submitLoading ? "Submitting.." : action === "Edit" ? "Edit list" : "Create list"}</button>
      </ModalInner>
    </ModalWrapper>
  );
};

export default ListModal;

const ModalWrapper = styled.div`
  position: fixed;
  background: #00000038;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalInner = styled.form`
  padding: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  min-height: 230px;
  gap: 15px;
  border-radius: 10px;
  position: relative; /* To position the CloseButton inside this container */

  > input {
    height: 47px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border-color: #0000002e;

    &:focus-visible {
      outline: 0px;
    }
  }

  > button {
    background: #0050b2;
    padding: 15px;
    border: none;
    border-radius: 5px;
    color: #ffff;
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
  }

  > h3 {
    margin: 0px;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;

  &:hover {
    color: #0050b2; /* Change color on hover */
  }
`;
