import React, { useEffect, useState } from "react";
import "../Css/custom-new.css";
import OnboardingRadio from "../../../components/onboarding/OnboardingRadio";
import { OnboardingButton } from "../../../components/onboarding/OnboardingButton";
import { fetchAPI } from "../../../utils/fetch";
import { config } from "../../../utils/config";

const Step1 = ({ setProgress, setStep, onboardingData, setOnboardingData, commentLengthOptions, stepInfo, setStepInfo }) => {
  const [selected, setSelected] = useState(onboardingData.commentLength || "SHORT");
  const [isChanged, setIsChanged] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setProgress(20);
  }, []);

  const handleChange = (value) => {
    if (selected !== value) {
      setIsChanged(true);
    }
    setSelected(value);
  };

  const handleNext = async () => {
    if (!selected) {
      setError("Please select a comment length.");
      return;
    }

    setError(null);
    setStep(3);

    if (!isChanged) return;

    try {
      setStepInfo({ step: 2, message: "Updating comment length preference..." });

      const response = await fetchAPI.post(
        `${config.urlPlatform}/api/v2/onboarding/add`,
        { withCredentials: true },
        {
          step: 2,
          field: "commentLength",
          value: selected
        }
      );

      if (response?.data?.success) {
        setOnboardingData((prev) => ({ ...prev, commentLength: selected }));
        setIsChanged(false);
      } else {
        setError(response?.data?.message);
        setStep(2);
      }
    } catch (error) {
      setStep(2);
      setError("Failed to update. Please try again.");
    } finally {
      setStepInfo({ step: -1, message: null });
    }
  };

  return (
    <div className="onboarding-body-global">
      <div className="step0-onboarding-heading">How Long Should Your Comments Be?</div>
      <div className="step0-onboarding-subheading">
        Do you prefer short, medium, or long responses on LinkedIn?
      </div>
      <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>
        <OnboardingRadio  options={commentLengthOptions} selectedOption={selected} onChange={handleChange} />
        {stepInfo.step === 2 && stepInfo.message && <div className="info-message-onboarding">{stepInfo.message}</div>}
        {error && <div className="error-message-onboarding">{error}</div>}
        <div className="onboarding-below-buttons">
          <OnboardingButton style={{ width: "177px", height: "55px" }} onClick={() => setStep(1)} title="Back" />
          <OnboardingButton
            style={{ width: "177px", height: "55px" }}
            onClick={handleNext}
            title="Next"
            variant="hollow"
          />
        </div>
       
      </div>
    </div>
  );
};

export default Step1;
