import React, { useState } from 'react';
import styled from 'styled-components';
import ListModal from '../list/ListModal';

const PeopleListView = ({ data, setLists, listData }) => {
  const [isActionOpen, setIsActionOpen] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [deleteLoading, setDeleteLoading] = useState(false);
  
  const handleDelete = async (e) => {
    e.preventDefault();
    setDeleteLoading(true);
    setLists({...listData, people: listData?.people.filter((list) => list?.id !== data?.id)});
    setIsActionOpen(false);
    setDeleteLoading(false);
  }

  return (
    <Container>
      <Content>
        <WrapperContent>
          <WrapperLeft>
            <div style={{ display: 'flex' }}>
              <div>
                <AvatarStyle src={data?.image ?? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"} alt={data?.name} />
              </div>
              <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center' }}>
                <Title onClick={() => window.open(data.profile_url, "_blank")} style={{ cursor: "pointer" }}>{data?.name}</Title>
                <Subtitle>{data?.headline}</Subtitle>
              </div>
            </div>
          </WrapperLeft>
          <WrapperRight>
            <div style={{ cursor: "pointer" }} onClick={() => setIsActionOpen(!isActionOpen)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-ellipsis h-4 w-4"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
            </div>
            {
              isActionOpen &&
              <Dropdown>
                <Item onClick={deleteLoading ? () => { } : handleDelete}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash2 mr-2 h-4 w-4"><path d="M3 6h18"></path><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path><line x1="10" x2="10" y1="11" y2="17"></line><line x1="14" x2="14" y1="11" y2="17"></line></svg>
                  {deleteLoading ? "Deleting..." : "Remove"}
                </Item>
              </Dropdown>
            }
          </WrapperRight>
        </WrapperContent>
      </Content>
      {showEditModal && <ListModal setShowModal={setShowEditModal} action="Edit" data={data} setLists={setLists} />}
    </Container>
  );
};
export default PeopleListView;

const AvatarStyle = styled.img`
  "object- fit": cover;
  border-radius: 50%;
  height: 65px;
  margin-right: 1rem;
`;

const Container = styled.div`
  background-color: #fff;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 80%;
  margin: 0.1rem 0;
`;
const Content = styled.div`
  padding: 10px;
`;
const Title = styled.h2`
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin: 0;

  &:hover {
    text-decoration: underline;
  }
`;
const Subtitle = styled.p`
  font-size: 14px;
  color: #777;
  margin-top: 4px;
`;
const WrapperContent = styled.div`
  display:flex;
  justify-content:space-between;
 `;
const WrapperLeft = styled.div`
 `;
const WrapperRight = styled.div`
  position:relative;
  > svg{
    cursor:pointer;
    }
 `;
const Dropdown = styled.div`
    max-width: 130px;
    padding: 5px;
    border: 1px solid #00000012;
    border-radius: 4px;
    box-shadow: 0px 1px 0px 0px #00000014;
    position: absolute;
    background-color: #fff;
    top: 22px;
    right: 0;
}
 `;
const Item = styled.div`
  padding:9px;
  border-top:1px solid #00000012;
  display:flex;
  align-item:center;
  gap:4px;
  font-size:14px;
  cursor:pointer;

> svg{
    width:16px;
    height: 16px;
    }
  &:first-child {
    border-top: 0px solid #00000012;
  }
  &:nth-child(2) {
    color:red;
  }
 `;
