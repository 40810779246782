import React, { useEffect, useState } from "react";
import "../Css/custom-new.css";
import { OnboardingButton } from "../../../components/onboarding/OnboardingButton";
import OnboardingSwitch from "../../../components/onboarding/OnboardingSwitch";
import { fetchAPI } from "../../../utils/fetch";
import { config } from "../../../utils/config";

const Step4 = ({ setProgress, setStep, onboardingData, setOnboardingData, stepInfo, setStepInfo }) => {
  const [emojiUsage, setEmojiUsage] = useState(onboardingData.emojiUsage ?? true);
  const [isChanged, setIsChanged] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setProgress(44);
  }, []);

  const handleToggle = (value) => {
    if (emojiUsage !== value) {
      setIsChanged(true);
    }
    setEmojiUsage(value);
  };

  const handleNext = async () => {
    setError(null);
    setStep(5);

    if (!isChanged) return;

    try {
      setStepInfo({ step: 4, message: "Updating emoji preference..." });

      const response = await fetchAPI.post(
        `${config.urlPlatform}/api/v2/onboarding/add`,
        { withCredentials: true },
        {
          step: 4,
          field: "emojiUsage",
          value: emojiUsage
        }
      );

      if (response?.data?.success) {
        setOnboardingData((prev) => ({ ...prev, emojiUsage }));
        setIsChanged(false);
      } else {
        setError(response?.data?.message);
        setStep(4);
      }
    } catch (error) {
      setStep(4);
      setError("Failed to update. Please try again.");
    } finally {
      setStepInfo({ step: -1, message: null });
    }
  };

  return (
    <div className="onboarding-body-global">
      <div className="step0-onboarding-heading">Do You Use Emojis in Comments?</div>
      <div className="step0-onboarding-subheading">
        Some people love emojis, others don’t. Should AI include them?
      </div>
      <div style={{ width: "90%", display: "flex", flexDirection: "column", gap: "20px" }}>
        <OnboardingSwitch isToggled={emojiUsage} setIsToggled={handleToggle} text={["No", "Yes"]} />
        {stepInfo.step === 4 && stepInfo.message && <div className="info-message-onboarding" >{stepInfo.message}</div>}
        {error && <div className="error-message-onboarding">{error}</div>}
        <div className="onboarding-below-buttons">
          <OnboardingButton style={{ width: "177px", height: "55px" }} onClick={() => setStep(3)} title="Back" />
          <OnboardingButton style={{ width: "177px", height: "55px" }} onClick={handleNext} title="Next" variant="hollow" />
        </div>
      
      </div>
    </div>
  );
};

export default Step4;
