import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Lato&family=Montserrat&family=Space+Grotesk:wght@500&display=swap');

    html {
        --main-bg: #0050B2;
        --blue-text: #0A66C2;
        --white: #ffffff;
        --white-grey: #F0F8FF;
        --black: #000000;
        --font-grey: #929292;
        --font-grey-dark: #848484;
        --dark-grey: #4B4B4B;
        --light-black: #343434;
        --red: #FF2400;
        --roboto-font: 'Roboto Slab', sans-serif
    }
    body {
        font-family: 'Montserrat', sans-serif;
    }

    @keyframes slide-left-public {
        from {
            opacity: 0;
            transform: translateX(-350px);
        } to {
            opacity: 1;
            transform: translateX(0);
        }
    }
    @keyframes slide-right-public {
        from {
            opacity: 0;
            transform: translateX(50%);
        } to {
            opacity: 1;
            transform: translateX(0);
        }
    }

    @keyframes fade-down {
        from {
            opacity: 0;
            transform: translateY(-50px);
        } to {
            opacity: 1;
            transform: translateY(0);
        }  
    }

    @keyframes fade-up-quick {
        from {
            opacity: 0;
            transform: translateY(-30px);
        } to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes popUp {
        from {
            opacity: 0;
            transform: scale(0.5); 
        } to {
            opacity: 1;
            transform: scale(1);
        }
    }

    @keyframes image-fade-up-main {
        0% {
            opacity: 0;
            transform: translateY(20px);
        } 20% {
            opacity: 0;
            transform: translateY(20px);
        } 40% {
            opacity: 0;
            transform: translateY(20px);
        } 60% {
            opacity: 0;
            transform: translateY(20px);
        } 80% {
            opacity: 0;
            transform: translateY(20px);
        } 100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes image-fade-up {
        0% {
            opacity: 0;
            transform: translateY(20px);
        } 25% {
            opacity: 0;
            transform: translateY(20px);
        } 50% {
            opacity: 0;
            transform: translateY(20px);
        } 75% {
            opacity: 0;
            transform: translateY(20px);
        } 100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes fade-opacity {
        0% {
            opacity: 0;
        } 25% {
            opacity: 0;
        } 50% {
            opacity: 0;
        } 75% {
            opacity: 0;
        } 100% {
            opacity: 1;
        }
    }

    @keyframes image-scale {
        0% {
            opacity: 0;
            transform: scale(0);
        } 20% {
            opacity: 0;
            transform: scale(0);
        } 40% {
            opacity: 0;
            transform: scale(0);
        } 60% {
            opacity: 0;
            transform: scale(0);
        } 80% {
            opacity: 0;
            transform: scale(0);
        } 100% {
            opacity: 1;
            transform: scale(1);
        }
    }

    @keyframes rotate {
      100%   {transform: rotate(360deg)}
    }

    @keyframes prixClipFix {
        0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
        25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
        50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
        75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
        100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
    }

    .style_font{
       white-space:nowrap;
        
      }
      @media only screen and (max-width:991px){
        .style_font{
            font-size:11px!important;
            white-space:nowrap;
        }
      }
      @media only screen and (max-width:767px){
        .style_font{
            font-size:15px!important;
            white-space:nowrap;
        }
      }
     
`;


export default GlobalStyle;