import React, { useEffect, useState } from 'react'
import { OnboardingButton } from '../../../components/onboarding/OnboardingButton'
import "../Css/custom-new.css"
import OnboardingSelect from '../../../components/onboarding/OnboardingSelect'
import { fetchAPI } from '../../../utils/fetch'
import { config } from '../../../utils/config'

const Step6 = ({ setProgress, setStep, onboardingData, setOnboardingData, ctaOptions, stepInfo, setStepInfo }) => {
  const [formData, setFormData] = useState(onboardingData);
  const [isChanged, setIsChanged] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setProgress(69);
  }, []);

  const handleChange = (field, value) => {
    if (formData[field] !== value) {
      setIsChanged(true);
    }
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleNext = async () => {
    if (!formData.callToAction) {
      setError("Please select a preference.");
      return;
    }

    setError(null);
    setStep(7);

    if (!isChanged) {
      return;
    }

    try {
      setStepInfo({ step: 6, message: "Updating call-to-action preference..." });
      const response = await fetchAPI.post(
        `${config.urlPlatform}/api/v2/onboarding/add`,
        { withCredentials: true },
        {
          step: 6,
          field: "callToAction",
          value: formData.callToAction
        }
      );

      if (response?.data?.success) {
        setOnboardingData(formData);
        setIsChanged(false);
      } else {
        setError(response?.data?.message);
        setStep(6);
      }
    } catch (error) {
      setStep(6);
      setError("Failed to update. Please try again.");
    } finally {
      setStepInfo({ step: -1, message: null });
    }
  };

  return (
    <div className='onboarding-body-global'>
      <div className='step0-onboarding-heading'>
        Should AI Encourage Engagement?
      </div>
      <div className='step0-onboarding-subheading'>
        AI can add CTAs like 'What do you think?' or keep it neutral.
      </div>
      <div style={{ width: "90%", display: "flex", flexDirection: "column", gap: "20px" }} class="onboarding-Wrapper-container">
        <OnboardingSelect
          label="Call-to-Action Preference"
          options={ctaOptions}
          selectedValue={formData.callToAction}
          onChange={(value) => handleChange("callToAction", value)}
        />
          {stepInfo.step === 6 && stepInfo.message && <div className='info-message-onboarding'>{stepInfo.message}</div>}
          {error && <div className='error-message-onboarding'>{error}</div>}
        <div className='onboarding-below-buttons'>
          <OnboardingButton style={{ width: "177px", height: "55px", boxSizing: "border-box" }} onClick={() => setStep(5)} title="Back" />
          <OnboardingButton style={{ width: "177px", height: "55px", boxSizing: "border-box" }} onClick={handleNext} title="Next" variant='hollow' />
        </div>
      
      </div>
    </div>
  );
}

export default Step6