import React from "react";
import styled from "styled-components";
import "../Css/custom-new.css"
import { OnboardingButton } from "../../../components/onboarding/OnboardingButton";
import { useNavigate } from "react-router-dom";
import { fetchAPI } from "../../../utils/fetch";
import { config } from "../../../utils/config";

const PopupOverlay = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    z-index: 999;
`;

const PopupContainer = styled.div`
  background: white;
  padding: 40px 20px;
  border-radius: 10px;
  text-align: center;
  width: 473px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
`;

const Heading = styled.h2`
  margin: 0;
  color: black;
`;

const ConfirmationPopup = ({ setPopupShownThisSession }) => {

  const navigate = useNavigate();

  const handleSkip = async () => {
    try {
      const response = await fetchAPI.put(
        `${config.urlPlatform}/api/v2/onboarding/skipOnboarding`,
        { withCredentials: true },
        { "skipOnboardingStatus": true }
      );

      if (response?.data?.success) {
        setPopupShownThisSession(false);
      }
    } catch (error) {
      console.log("Failed to update skip state");
    }
  };

  return (
    <PopupOverlay>
      <PopupContainer>
        <Heading>Do you want to customize your AI?</Heading>
        <div className='onboarding-below-buttons' style={{ marginTop: "0px", marginBottom: "0px" }}>
          <OnboardingButton style={{ width: "155px", height: "49px", boxSizing: "border-box" }} onClick={handleSkip} title="No" />
          <OnboardingButton style={{ width: "155px", height: "49px", boxSizing: "border-box" }} onClick={() => navigate("/custom")} title="Yes" variant='hollow' />
        </div>
      </PopupContainer>
    </PopupOverlay>
  );
};

export default ConfirmationPopup;
