import React, { useEffect, useState } from "react";
import "./styles.popover.css";
import creativeIcon from "../../../../assets/creative-copy.png";
import funnyIcon from "../../../../assets/funny.png";
import controversialIcon from "../../../../assets/controversial.png";
import influencerbadgeIcon from "../../../../assets/influencer-badge.svg";
import custommoodIcon from "../../../../assets/custom-mood.png";
import commenterLogo from "../../../../assets/commenter-logo.png";
import tutorialsIcon from "../../../../assets/tutorials.png";
import menuIcon from "../../../../assets/menu.svg";
import menuCloseIcon from "../../../../assets/menu-close.svg";
import logoSmallIcon from "../../../../assets/logo-small.svg";
import { config } from "../../../../utils/config";
import { fetchAPI } from "../../../../utils/fetch";
import PlansComponents from "./PlansComponents/planComponent";

const DropdownArrow = () => (
  <svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 9l6 6 6-6" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const CommentPopover = ({ postData, setOpenCommentPostId, commentsLeft, setCommentsLeft, openCommentPostId, getRemainingComments, customPrompt, userData, setPasteButton, handleCommentChange }) => {

  const [comment, setComment] = useState("");
  const [activeMood, setActiveMood] = useState('creative');
  const [commentType, setCommentType] = useState('short');
  const [emotionType, setEmotionType] = useState('basic');
  const [rewrite, setRewrite] = useState(false);
  const [generativeCommentLoading, setGenerativeCommentLoading] = useState(false);
  const [fullComment, setFullComment] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [keywords, setKeywords] = useState([]);

  // saving menu options in localstorage
  const [autoGenerateOnOpen, setAutoGenerateOnOpen] = useState(
    JSON.parse(localStorage.getItem("menuoptions"))?.autogen ?? true
  );

  useEffect(() => {
    localStorage.setItem("menuoptions", JSON.stringify({ autogen: autoGenerateOnOpen }));
  }, [autoGenerateOnOpen]);

  const handleClick = (mood) => {
    setActiveMood(mood);
  };

  useEffect(() => {
    if (customPrompt) {
      setActiveMood('custom');
    }
    else {
      setActiveMood('creative');
    }
  }, [customPrompt])

  const generateComment = async () => {
    try {
      setComment("Thinking...");
      setGenerativeCommentLoading(true);
      setIsTyping(false);
      const response = await fetchAPI.post(`${config.urlPlatform}/api/v2/plugin/commentForWebsite`, { withCredentials: true }, {
        prompt: `${postData?.author?.name} ${postData?.text}`,
        keywords,
        "emotion": activeMood,
        "platform": "LinkedIn",
        "style": commentType,
        "emotionNew": selectedEmotion.value,
        rewrite
      })
      if (response?.data?.success) {
        setFullComment(response?.data?.message);
        setComment("");
        setIsTyping(true);
        setRewrite(true);
        getRemainingComments();
      }
    } catch (error) {
      setComment("Failed to generate comment");
    } finally {
      setGenerativeCommentLoading(false);
    }
  }

  useEffect(() => {
    if (isTyping && fullComment.length > 0) {
      let index = 0;
      setComment("|");

      const interval = setInterval(() => {
        if (index < fullComment.length) {
          setComment((prev) => prev.slice(0, -1) + fullComment[index - 1] + "|");
          index++;
        } else {
          clearInterval(interval);
          setIsTyping(false);
          setComment((prev) => prev.slice(0, -1));
        }
      }, 5);

      return () => clearInterval(interval);
    }
  }, [isTyping, fullComment]);

  function getRandomNumber(start, end) {
    return Math.floor(Math.random() * (end - start + 1)) + start;
  }

  useEffect(() => {
    if (openCommentPostId && comment !== "Thinking..." && autoGenerateOnOpen) {
      // get random emotion Type
      const choosedEmotionType = getRandomNumber(0, 1) === 0 ? 'basic' : 'bold';
      setEmotionType(choosedEmotionType);
      // get emotionNew list and select random value from it 
      const emotionNewStylesInternal =
        commentType === "long" && activeMood === "creative"
          ? LONG_STYLES_CREATIVE
          : commentType === "short" && choosedEmotionType === "basic" && activeMood === "creative"
            ? SHORT_STYLES_BASIC
            : commentType === "short" && choosedEmotionType === "bold" && activeMood === "creative"
              ? SHORT_STYLES_BOLD
              : FUNNY_CONTROVERSIAL_STYLES;

      const choosedEmotionNewStyles = emotionNewStylesInternal[getRandomNumber(1, emotionNewStyles.length)];
      setSelectedEmotion(choosedEmotionNewStyles)
      generateComment();
    }
  }, [openCommentPostId])

  const [copyButtonText, setCopyButtonText] = useState("Copy");
  const copyComment = () => {
    if (comment) {
      setPasteButton(postData?.id);
      setCopyButtonText("Copied");
      navigator.clipboard.writeText(comment);
      const e = {
        target: {
          value: comment
        }
      };
      handleCommentChange(e);
      setTimeout(() => {
        setCopyButtonText("Copy");
      }, 2000);
    }
  }

  const [keywordText, setkeywordText] = useState("");
  const handleKeywordAdd = (e) => {
    e.preventDefault();
    if (keywordText.trim() !== "" && keywords.find((keyword) => keyword === keywordText) === undefined) {
      setKeywords([keywordText, ...keywords]);
      setkeywordText("");
    }
  }

  const handleKeywordDelete = (index) => {
    const newKeywords = [...keywords];
    newKeywords.splice(index, 1);
    setKeywords(newKeywords);
  }

  // emotion new styles
  const [selectedEmotion, setSelectedEmotion] = useState({ name: "", value: "" });
  const SHORT_STYLES_BASIC = [
    { name: "", value: "" },
    { name: "Addition", value: "addition" },
    { name: "Affirmation", value: "affirmation" },
    { name: "Extension", value: "extension" },
    { name: "Reinforcement", value: "reinforcement" }
  ];

  const SHORT_STYLES_BOLD = [
    { name: "", value: "" },
    { name: "Simple", value: "basic" },
    { name: "Supporter", value: "supporting_others" },
    { name: "The thinger", value: "the_thinger" },
    { name: "Helper", value: "helper" },
    { name: "Congratulate", value: "congratulate" },
    { name: "Shoulder", value: "shoulder" },
    { name: "Mathematician", value: "mathematician" },
    { name: "Ifer", value: "ifer" },
    { name: "Shouldefiter", value: "shouldefiter" },
    { name: "Shouldenter", value: "shouldenter" },
    { name: "W_shoulder", value: "w_shoulder" },
    { name: "O_benefiter", value: "o_benefiter" },
  ];

  const FUNNY_CONTROVERSIAL_STYLES = [
    { name: "", value: "" },
    { name: "The thinger", value: "the_thinger" },
    { name: "Helper", value: "helper" },
    { name: "Shoulder", value: "shoulder" }
  ];

  const LONG_STYLES_CREATIVE = [
    { name: "", value: "" },
    { name: "Pros/cons", value: "pros_vs_cons" },
    { name: "Benefiter", value: "benefiter" },
    { name: "Doubler", value: "doubler" },
    { name: "Triple tip", value: "triple_tip" },
    { name: "Abouter", value: "abouter" },
    { name: "Contraster", value: "contraster" },
    { name: "Three_qualiter", value: "three_qualiter" },
    { name: "Three_two_commenter", value: "three_two_commenter" }
  ];

  const emotionNewStyles =
    commentType === "long" && activeMood === "creative"
      ? LONG_STYLES_CREATIVE
      : commentType === "short" && emotionType === "basic" && activeMood === "creative"
        ? SHORT_STYLES_BASIC
        : commentType === "short" && emotionType === "bold" && activeMood === "creative"
          ? SHORT_STYLES_BOLD
          : FUNNY_CONTROVERSIAL_STYLES;

  // influencer popup
  const [influencerPopup, setInfluencerPopup] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [openPlansPopup, setOpenPlansPopup] = useState(false);

  return (
    <>
      {
        openPlansPopup &&
        <PlansComponents setOpenPlansPopup={setOpenPlansPopup} userData={userData} />
      }
      {
        menuOpen &&
        <div className="panel">
          <div className="header">
            <h2>
              <img
                id="commenter-small-logo"
                src={logoSmallIcon}
                alt="Commenter Logo"
                width="20"
                height="20"
              />
              Features
            </h2>
            <button className="close-menu" id="close-settings-menu" onClick={() => setMenuOpen(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ position: "absolute", left: "auto", right: "0px", width: "26px", height: "28px", cursor: "pointer", top: "0", bottom: "0", margin: "auto", inset: "0px 7px 4px auto" }}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12">
                </path>
              </svg>
            </button>
          </div>

          <div className="feature">
            <span className="feature-name">
              Automatic Generation
              <span className="info-icon" data-tooltip="Automatically generate comment when open Commenter AI">
                ⓘ
              </span>
            </span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ fontSize: "14px", color: !autoGenerateOnOpen ? "#0050B2" : "#999" }}>OFF</div>
              <label className="switch" style={{ display: "flex", alignItems: "center", margin: "2px 3px" }}>
                <div>
                  <input id="shortLongCheck" type="checkbox" checked={autoGenerateOnOpen} onChange={() => setAutoGenerateOnOpen(!autoGenerateOnOpen)} />
                  <span className={`slider roundNew w-color ${!autoGenerateOnOpen ? "hover-active" : ""}`}></span>
                </div>
              </label>
              <div style={{ fontSize: "14px", color: autoGenerateOnOpen ? "#0050B2" : "#999" }}>ON</div>
            </div>
          </div>
        </div>
      }
      <div id="spm-popover-template" className="spm-popover-template-style hide-academy-tips" style={{ width: "500px", background: "white", borderRadius: "5px", boxShadow: "5px -4px 27px 0px #5858583d", position: "absolute", zIndex: "99", top: "-110px", right: "-87%", boxSizing: "border-box" }}>
        <span className="PopoverWrapper"></span>
        <div className="wrapperContentPopUp">
          <div className="webui-popover-title" style={{ "borderBottom": "2px solid rgb(242, 242, 242)", }}>
            <div style={{ textAlign: "center", display: "flex", justifyContent: "space-between", alignItems: "center", position: "relative" }}>
              <div
                id="spm-header-menu"
                style={{ display: "flex", cursor: "pointer", marginLeft: "13px" }}
                onClick={() => setMenuOpen(!menuOpen)}
              >
                <img
                  id="spm-header-menu-icon"
                  src={menuOpen ? menuCloseIcon : menuIcon}
                  alt="Menu Icon"
                  style={{ cursor: "pointer" }}
                />
                <span
                  style={{ color: "#0050B2", marginLeft: "5px", cursor: "pointer" }}>
                  Menu
                </span>
              </div>
              <img style={{ width: "200px", height: "59px", objectFit: "contain", Padding: "5px" }} id="spm-header-commenter-logo" src={commenterLogo} alt="Commenter Logo" />
              <div style={{ height: "18px", padding: "5px", cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "50px", width: "18px" }}>
                <div
                  onClick={() => window.open(`https://commenter.ai/tutorials`, "_blank")}
                  style={{ cursor: "pointer" }}
                >
                  <img id="spm-header-tutorials-logo" src={tutorialsIcon} alt="Tutorials" style={{ width: "29px", height: "29px", padding: "5px" }} />
                </div>
                <div onClick={(e) => {
                  e.stopPropagation();
                  setOpenCommentPostId(null);
                  setCommentsLeft(null);
                }}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ position: "absolute", left: "auto", right: "0px", width: "26px", height: "28px", cursor: "pointer", top: "0", bottom: "0", margin: "auto", inset: "0px 7px 4px auto" }}>
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12">
                    </path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div style={{ padding: "9px 14px " }}>
            <div className="comment-gen" >
              <div className="spm-pop-header">
                <div className="spm-pop-mood">
                  <div className="spm-pop-mood-list">
                    {/* use custom prompt if user has enable personalization */}
                    <div
                      className={`spm-mood-item-icon-column ${activeMood === 'creative' ? 'spm-mood-item-icon-column-active' : ''}`}
                      data-mood="creative"
                      onClick={() => { !customPrompt && setSelectedEmotion(""); !customPrompt && handleClick('creative') }}
                      style={{ cursor: customPrompt ? "default" : "pointer" }}
                    >
                      <img src={creativeIcon} alt="Creative" style={{ filter: activeMood === 'creative' ? "invert(1)" : "" }} />
                      <span>Creative</span>
                    </div>
                    <div
                      className={`spm-mood-item-icon-column ${activeMood === 'funny' ? 'spm-mood-item-icon-column-active' : ''}`}
                      data-mood="funny"
                      onClick={() => {
                        const isEligible =
                          !customPrompt &&
                          ((userData?.plan === "starter" && userData?.paidStatus === false) ||
                            userData?.plan === "proMonth" ||
                            userData?.plan === "proMonth_yearly" ||
                            (userData?.plan === "professional" && userData?.paidStatus === true));

                        if (isEligible) {
                          setSelectedEmotion(FUNNY_CONTROVERSIAL_STYLES[1]);
                          handleClick("funny");
                        } else {
                          setOpenPlansPopup(true);
                        }
                      }}
                      style={{ cursor: customPrompt ? "default" : "pointer" }}
                    >
                      <img src={funnyIcon} alt="Funny" style={{ filter: activeMood === 'funny' ? "invert(1)" : "" }} />
                      <span>Funny</span>
                    </div>
                    <div
                      className={`spm-mood-item-icon-column ${activeMood === 'controversial' ? 'spm-mood-item-icon-column-active' : ''}`}
                      data-mood="controversial"
                      onClick={() => {
                        const isEligible =
                          !customPrompt &&
                          ((userData?.plan === "starter" && userData?.paidStatus === false) ||
                            userData?.plan === "proMonth" ||
                            userData?.plan === "proMonth_yearly"
                          );

                        if (isEligible) {
                          setSelectedEmotion(FUNNY_CONTROVERSIAL_STYLES[1]);
                          handleClick("controversial");
                        } else {
                          setOpenPlansPopup(true);
                        }
                      }}
                      style={{ cursor: customPrompt ? "default" : "pointer" }}
                    >
                      <img src={controversialIcon} alt="Controversial" style={{ filter: activeMood === 'controversial' ? "invert(1)" : "" }} />
                      <span>Controversial</span>
                      {
                        (influencerPopup && activeMood === 'custom') ?
                          <div class="influencer-popup">
                            <div class="influencer-header">
                              <div class="influencer-logo-title">
                                <div class="influencer-logo">
                                  <img src={custommoodIcon} />
                                </div>
                                <div>
                                  <div><h2 class="influencer-h2">Custom</h2></div>
                                  <div class="influencer-badge">
                                    <img src={influencerbadgeIcon} />
                                    Influencer
                                  </div>
                                </div>
                              </div>

                              <button class="influencer-close" onClick={(e) => { e.stopPropagation(); setInfluencerPopup(false) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ position: "absolute", left: "auto", right: "0px", width: "30px", height: "31px", cursor: "pointer", top: "0", bottom: "0", margin: "auto", inset: "0px 7px 4px auto" }}>
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12">
                                  </path>
                                </svg>
                              </button>
                            </div>
                            <div class="influencer-text">
                              <div>The Custom feature allows you to:</div>
                              <ul class="influencer-ul">
                                <li class="influencer-li">- Personalize your AI prompt</li>
                                <li class="influencer-li">- Get comments that sound even more like you</li>
                                <li class="influencer-li">- Save more time on your commenting</li>
                              </ul>
                              <p class="influencer-p">You just need to be subscribed to the "Influencer" plan in order to be able to use it!</p>
                            </div>
                            {/* <button class="influencer-button">Get the "Influencer" plan now</button> */}
                          </div>
                          :
                          influencerPopup &&
                          <div class="influencer-popup">
                            <div class="influencer-header">
                              <div class="influencer-logo-title">
                                <div class="influencer-logo">
                                  <img src={custommoodIcon} />
                                </div>
                                <div>
                                  <div><h2 class="influencer-h2">Custom</h2></div>
                                  <div class="influencer-badge">
                                    <img src={influencerbadgeIcon} />
                                    Influencer
                                  </div>
                                </div>
                              </div>

                              <button class="influencer-close" onClick={(e) => { e.stopPropagation(); setInfluencerPopup(false) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ position: "absolute", left: "auto", right: "0px", width: "30px", height: "31px", cursor: "pointer", top: "0", bottom: "0", margin: "auto", inset: "0px 7px 4px auto" }}>
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12">
                                  </path>
                                </svg>
                              </button>
                            </div>
                            <div class="influencer-text">
                              <p class="influencer-p">You haven't enabled this feature. Enable personalization to use it!</p>
                            </div>
                            <button onClick={(e) => { e.stopPropagation(); window.open(`https://commenter.ai/custom`, "_blank") }} class="influencer-button">Enable Personalization</button>
                          </div>
                      }
                    </div>

                    <div
                      className={`spm-mood-item-icon-column ${activeMood === 'custom' ? 'spm-mood-item-icon-column-active' : ''}`}
                      data-mood="custom"
                      onClick={() => {
                        setSelectedEmotion("");
                        // handleClick('custom'); 
                        setInfluencerPopup(true);
                      }}
                    >
                      <img src={custommoodIcon} alt="Custom" />
                      <span style={{ textAlign: "left" }}>
                        Custom
                        <div className="influencer-badge-mood">
                          <span>
                            <img src={influencerbadgeIcon} alt="Influencer" width="10" height="8" />
                          </span>
                          <span>INFLUENCER</span>
                        </div>
                      </span>

                    </div>

                  </div>
                </div>
                <div className="spm-pop-style" style={{ marginBottom: "0px" }}>
                  <div className="short-long-container" style={{ display: "flex", alignItems: "center" }}>
                    <div>Long</div>
                    <label className="switch">
                      <input id="shortLongCheck" type="checkbox" checked={commentType === "short"} onChange={() => { setSelectedEmotion(""); setCommentType(commentType === "short" ? "long" : "short") }} />
                      <span className="slider roundNew w-color"></span>
                    </label>
                    <div>Short</div>
                  </div>
                  {
                    (commentType === "short" && (activeMood === "creative")) &&
                    <div className="bold-basic-container" style={{ display: "flex", alignItems: "center" }}>
                      <div>Bold</div>
                      <label className="switch">
                        <input id="boldBasicCheck" type="checkbox" checked={emotionType === "basic"} onChange={() => { setSelectedEmotion(""); setEmotionType(emotionType === "basic" ? "bold" : "basic") }} />
                        <span className="slider roundNew w-color"></span>
                      </label>
                      <div>Basic</div>
                    </div>
                  }
                  {
                    activeMood !== "custom" &&
                    <div className="dropdownEmotion" style={{ cursor: "pointer" }}>
                      <span id="testSpan">{selectedEmotion?.name ? selectedEmotion?.name : "Style (None)"}<DropdownArrow /> </span>
                      <ul>
                        {
                          emotionNewStyles.map((option, index) => (
                            <li key={index}>
                              <a href="#" name={option.value} className="Option" onClick={(e) => {
                                e.preventDefault();
                                if (selectedEmotion !== option) {
                                  setSelectedEmotion(option);
                                }
                              }}>
                                {option.name}
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                  }
                </div>
              </div>

            </div>

            <div className="spm-pop-body" style={{ flexDirection: "column" }}>
              <div className="spm-pop-keywords">
                <form onSubmit={handleKeywordAdd}>
                  <input
                    type="text"
                    name="keywords"
                    className="spm-keywords-input"
                    placeholder="Write Your Ideas/Concepts/Keywords Here + Hit 'Enter'"
                    style={{ boxSizing: "border-box", margin: "12px 0" }}
                    value={keywordText}
                    onChange={(e) => setkeywordText(e.target.value)}
                  />
                </form>
                <div className="spm-entered-keywords-container" style={{ display: "flex", flexWrap: "wrap" }}>
                  {
                    keywords.map((keyword, index) => (
                      <span className="spm-entered-keyword-item" data-text={keyword} key={index}>
                        {keyword}
                        <span onClick={() => handleKeywordDelete(index)} id={`spm-entered-keyword-${index}`} className="spm-centered-keyword-item-cancel"></span>
                      </span>
                    ))
                  }
                </div>
              </div>
              <textarea
                placeholder="Generated comment"
                id="spm-pop-generated-comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                style={{ boxSizing: "border-box" }}
              />
            </div>

            <div className="spm-pop-footer">
              <button onClick={() => generateComment()} className="spm-popover-button spm-write-button sp-btn-active" id="spm-pop-write-comment" style={{ display: "flex", justifyContent: "center", opacity: generativeCommentLoading ? 0.5 : 1, cursor: generativeCommentLoading ? "default" : "pointer" }} disabled={generativeCommentLoading}>
                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABJElEQVR4nOXUP0oDQRTH8URsFF1JELEUTxHwGjaCVxAs9AIigpWtSWNA8A6eQAttg7GzT+MB4kcWJrCJs39JofiDYYt5+/u+efPetFr/XkhwhxEusLLUouDRvIa5EBzjHV9+aoLtTOxa+PbwuRB7GTM/wDQcdYD+wrrCaqYsT7hBOwIZxwDXYXOnpCRJMJ8pBhnGfkyzVNM8BknvJKkNkG8+BynKLheAzRJzYX+9KeCkxPwZW7nmFQD3BeYv6BSaVwCMcsxfZ+ZpC2fnpDIAG2E+YubdTFw6J5MmgP0w2eka4wGni61Y2uYlJdqL9vayAFX0KwC34fLaDQGD9J6KAs4D4LAuBLt4i76imaAuPjTXFEdlmXRwFsrVr7HSp75X59R/R9/39hd/TdG/awAAAABJRU5ErkJggg==" width="24" height="24" alt="write" />
                  <span style={{ paddingTop: "3px" }}>{rewrite ? "Rewrite" : "Write"}</span>
                </div>
              </button>
              <button onClick={() => copyComment()} className="spm-popover-button spm-copy-button" id="spm-pop-copy-comment" style={{ display: "flex", justifyContent: "center", opacity: (generativeCommentLoading || !comment) ? 0.5 : 1, cursor: (generativeCommentLoading || !comment) ? "default" : "pointer" }} disabled={generativeCommentLoading || !comment}>
                <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <svg width="21" height="21" viewBox="0 0 33 34" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_254_1042)"><path d="M20.0449 33.1431H7.73438C4.89114 33.1431 2.57812 30.8301 2.57812 27.9868V10.52C2.57812 7.67678 4.89114 5.36377 7.73438 5.36377H20.0449C22.8882 5.36377 25.2012 7.67678 25.2012 10.52V27.9868C25.2012 30.8301 22.8882 33.1431 20.0449 33.1431ZM7.73438 7.94189C6.31288 7.94189 5.15625 9.09853 5.15625 10.52V27.9868C5.15625 29.4083 6.31288 30.5649 7.73438 30.5649H20.0449C21.4664 30.5649 22.623 29.4083 22.623 27.9868V10.52C22.623 9.09853 21.4664 7.94189 20.0449 7.94189H7.73438ZM30.3574 24.7642V5.29932C30.3574 2.45608 28.0444 0.143066 25.2012 0.143066H10.8926C10.1806 0.143066 9.60352 0.720123 9.60352 1.43213C9.60352 2.14413 10.1806 2.72119 10.8926 2.72119H25.2012C26.6227 2.72119 27.7793 3.87782 27.7793 5.29932V24.7642C27.7793 25.4762 28.3564 26.0532 29.0684 26.0532C29.7804 26.0532 30.3574 25.4762 30.3574 24.7642Z" fill="currentColor"></path></g><defs><clipPath id="clip0_254_1042"><rect width="33" height="33" fill="currentColor" transform="translate(0 0.143066)"></rect></clipPath></defs></svg>
                  <span style={{ paddingTop: "3px" }}>{copyButtonText}</span>
                </span>
              </button>
            </div>
          </div>
          <div className="spm-pop-left-comments spm-popWrapper" style={{ "borderBottomLeftRadius": "10px", "borderBottomRightRadius": "10px", cursor: "default" }}>
            <span>You have remaining <span id="spm-pop-left-comments-no">{commentsLeft ? commentsLeft : "..."}</span> comments. <a className="spm-pop-left-comments-link" target="_blank" href="https://yjdk70k2wcj.typeform.com/to/rudY48L8"> Buy
              More
              Comments</a></span>
          </div>
          {
            !((userData?.plan === "proMonth" || userData?.plan === "proMonth_yearly") && userData?.paidStatus === true) &&
            <div class="spm-pop-get-access getAccesWrapper">
              <span onClick={() => setOpenPlansPopup(true)} style={{ textTransform: "uppercase" }}> &gt;&gt; GET My Full Access &lt;&lt; </span>
            </div>
          }
        </div>
      </div >
    </>
  );
};

export default CommentPopover;
