import styled from "styled-components";
import { lazy, Suspense } from "react";
import { validateSession } from "../../hooks/useValidateSession";
import { useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import { getContent } from "../../hooks/useGetContent";
import robotSubscribe from "../../assets/robot-subscribe-suggestion.jpg";
import { size } from "../../utils/helpers";
import { fetchAPI } from "../../utils/fetch";
import { USER_SUBSCRIPTION, config } from "../../utils/config";
import robotPlugin from "../../assets/welcome-mascot.png";
import TrialExpriedModal from "../../components/TrialExpriedModal";
import ConfirmationPopup from "../onboarding/Popups/ConfirmationPopup";

const SectionOne = lazy(() =>
  import("../../layout/platform/MainPageSectionOne")
);
const SectionTwo = lazy(() =>
  import("../../layout/platform/MainPageSectionTwo")
);
const SectionThree = lazy(() =>
  import("../../layout/platform/MainPageSectionThree")
);
const SectionFour = lazy(() =>
  import("../../layout/platform/MainPageSectionFour")
);
const FAQSection = lazy(() => import("../../layout/platform/FaqSection"));

const MainPage = () => {
  const navigate = useNavigate();
  const [content, setContent] = useState({});
  const [showExpired, setShowExpired] = useState(true);
  const [overlay, setOverlay] = useState(false);
  const [videoOverlay, setOver] = useState(false);
  const [status, setStatus] = useState(true);
  const storage = localStorage.getItem("userData");
  const storageJson = JSON.parse(storage);
  const [onboardingStatus, setOnboardingStatus] = useState(null);
  const [popupShownThisSession , setPopupShownThisSession] = useState(true);

  useMemo(() => {
    getContent().then((response) => {
      setContent(response["videos"]);
    });
    validateSession()
      .then((result) => {
        if (result.success === false || result === false) {
          //navigate("/unauthorized");
        }
      })
      .catch((error) => {
        //navigate("/unauthorized");
      });

    fetchAPI
      .get(
        `${config.urlPlatform}/api/v2/auth/user?email=${storageJson.email}`,
        {
          withCredentials: true,
        }
      )
      .then((result) => {
        if ("success" in result) {
          setOverlay(false);
        } else {
          if (result?.data?.status === USER_SUBSCRIPTION.INACTIVE) {
            setStatus(false);
            setOverlay(true);
          }
          if (!result?.data?.notFirst) {
            setOver(true);
          }
          setOnboardingStatus(result?.data?.onboardingStatus);
          delete result.data.subscriptionData;
          localStorage.setItem("userData", JSON.stringify(result?.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [navigate, storageJson.email]);

  return (
    <MainContainer>
      {
        onboardingStatus && 
        onboardingStatus !== "COMPLETED" &&
        onboardingStatus !== "SKIPPED" &&
        popupShownThisSession && 
        <ConfirmationPopup setPopupShownThisSession={setPopupShownThisSession} />
      }
      {(() => {
        if (overlay) {
          return (
            <Overlay
              onClick={() => {
                setOverlay(false);
              }}
            >
              <OvarlayImg src={robotSubscribe} />
            </Overlay>
          );
        }
        return null;
      })()}
      {(storageJson.expiredFlag || storageJson.trialExpiration === "Expired") &&
        showExpired ? (
        <TrialExpriedModal setShow={setShowExpired} />
      ) : (
        ""
      )}
      {(() => {
        if (status && videoOverlay) {
          return (
            <Overlay
              onClick={() => {
                setOver(false);
                fetchAPI.post(
                  `${config.urlPlatform}/api/v2/auth/first?email=${storageJson.email}`,
                  {
                    withCredentials: true,
                  }
                );
              }}
            >
              <OverlayBox>
                <MascotImg src={robotPlugin} />
                <OverlayTextBox>
                  <OverlayText>
                    Welcome, Commenter.
                    <br />
                    <br />
                    Please, watch the 2 videos to learn how to use our platform
                    properly. <br />
                    <br />
                    It's very important. Thank you.
                  </OverlayText>
                </OverlayTextBox>
              </OverlayBox>
            </Overlay>
          );
        }
        return null;
      })()}
      <Suspense
        fallback={
          <div
            style={{
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Loading...
          </div>
        }
      >
        <SectionOne videoUrl={content.platformStagingVideo} />
      </Suspense>
      <Suspense
        fallback={
          <div
            style={{
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Loading...
          </div>
        }
      >
        <SectionTwo videoUrl={content.platformSecondVideo} />
      </Suspense>
      <Suspense
        fallback={
          <div
            style={{
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Loading...
          </div>
        }
      >
        <SectionThree />
      </Suspense>
      <Suspense
        fallback={
          <div
            style={{
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Loading...
          </div>
        }
      >
        <FAQSection />
      </Suspense>
      <Suspense
        fallback={
          <div
            style={{
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Loading...
          </div>
        }
      >
        <SectionFour />
      </Suspense>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  color: var(--white);
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  transition: 0.4s;
  animation: fade-opacity 1s;
`;

const OvarlayImg = styled.img`
  z-index: 9999;
  opacity: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 30%;
  transition: 0.4s;
  animation: image-scale 1.5s;
  @media only screen and (max-width: ${size.minWidthTablet}) {
    width: 100%;
  }
`;

const MascotImg = styled.img`
  transition: 0.4s;
  animation: image-scale 1.5s;
  width: 100%;
  @media only screen and (max-width: ${size.minWidthTablet}) {
    width: 100%;
  }
`;

const OverlayTextBox = styled.div`
  position: absolute;
  top: 5%;
  right: 0;
  width: 50%;
  left: 43%;
`;

const OverlayText = styled.p`
  color: #fff;
  font-weight: 600;
  font-size: 1.2rem;
  a {
    color: #fff;
  }
  @media only screen and (max-width: ${size.minWidthTablet}) {
    font-size: 0.65rem;
  }
`;

const OverlayBox = styled.div`
  z-index: 9999;
  opacity: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 40%;
  transition: 0.4s;
  animation: image-scale 1.5s;
  @media only screen and (max-width: ${size.minWidthTablet}) {
    max-width: 100%;
  }
`;

export default MainPage;
