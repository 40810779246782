import React, { useState } from 'react';
import styled from 'styled-components';
import ListModal from './ListModal';
import { fetchAPI } from '../../../../utils/fetch';
import { config } from '../../../../utils/config';

const ListS = ({ data, fetchData, setLists }) => {
  const [isActionOpen, setIsActionOpen] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      const listId = data?.id;
      setDeleteLoading(true);
      let response = await fetchAPI.delete(
        `${config.urlPlatform}/api/v2/unipile/linkedin/keywords/delete/${data?.id}`,
        { withCredentials: true }
      );

      if (response?.data?.success) {
        setIsActionOpen(false);
        setLists((prevLists) => prevLists.filter((list) => list?.id !== listId)); 
      }
    } catch (error) {
      console.log("Failed to delete data");
    } finally {
      setDeleteLoading(false);
    }
  }

  return (
    <Container>
      <Content>
        <WrapperContent>
          <WrapperLeft>
            <Subtitle>List Name</Subtitle>
            <Title>{data.list_name}</Title>
          </WrapperLeft>
          <WrapperRight>
            <div style={{ cursor: "pointer" }} onClick={() => setIsActionOpen(!isActionOpen)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-ellipsis h-4 w-4"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
            </div>
            {
              isActionOpen &&
              <Dropdown>
                <Item onClick={() => { setShowEditModal(true); setIsActionOpen(false) }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-pencil mr-2 h-4 w-4"><path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z"></path><path d="m15 5 4 4"></path></svg>
                  Edit
                </Item>
                <Item onClick={deleteLoading ? () => {} : handleDelete}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash2 mr-2 h-4 w-4"><path d="M3 6h18"></path><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path><line x1="10" x2="10" y1="11" y2="17"></line><line x1="14" x2="14" y1="11" y2="17"></line></svg>
                  {deleteLoading ? "Deleting..." : "Delete"}
                </Item>
              </Dropdown>
            }
          </WrapperRight>
        </WrapperContent>
        <Details>
          <p>
            <span className="label">Search query:</span> <br />
            Keywords: <span>{data.keywords.join(",  ")}</span>
          </p>
        </Details>
      </Content>
      {showEditModal && <ListModal setShowModal={setShowEditModal} action="Edit" data={data} setLists={setLists} />}
    </Container>
  );
};
export default ListS;

const Container = styled.div`
  width: 100%
  background-color: #fff;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 0.7rem 0;
`;
const Content = styled.div`
  padding: 16px;
`;
const Title = styled.h2`
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin: 0;
`;
const Subtitle = styled.p`
  font-size: 14px;
  color: #777;
  margin-bottom: 4px;
`;
const Details = styled.div`
  margin-top: 16px;
  font-size: 14px;
  color: #555;
  .label {
    font-weight: 600;
    color: #333;
  }
  .highlight {
    font-weight: 500;
    color: #0073B1;
  }
`;
const WrapperContent = styled.div`
  display:flex;
  justify-content:space-between;
 `;
const WrapperLeft = styled.div`
 `;
const WrapperRight = styled.div`
  position:relative;
  > svg{
    cursor:pointer;
    }
 `;
const Dropdown = styled.div`
    max-width: 130px;
    padding: 5px;
    border: 1px solid #00000012;
    border-radius: 4px;
    box-shadow: 0px 1px 0px 0px #00000014;
    position: absolute;
    top: 22px;
    right: 0;
}
 `;
const Item = styled.div`
  padding:9px;
  border-top:1px solid #00000012;
  display:flex;
  align-item:center;
  gap:4px;
  font-size:14px;
  cursor:pointer;

> svg{
    width:16px;
    height: 16px;
    }
  &:first-child {
    border-top: 0px solid #00000012;
  }
  &:nth-child(2) {
    color:red;
  }
 `;
