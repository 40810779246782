import React from "react";
import styled from "styled-components";

const PollComponent = ({ poll }) => {
  const totalVotes = poll?.total_votes_count || 1;
  const optionsWithPercentage = poll?.options.map(option => ({
    ...option,
    percentage: ((option.votes_count / totalVotes) * 100).toFixed(0),
  }));

  return (
    <PollContainer>
      <PollTitle>Poll:</PollTitle>
      <PollQuestion>{poll?.question ?? ""}</PollQuestion>
      {optionsWithPercentage.map(option => (
        <PollOption key={option.id}>
          <PollText>{option.text}</PollText>
          <PollBar>
            <PollProgress style={{ width: `${option.percentage}%` }} />
          </PollBar>
          <PollPercentage>{option.percentage}%</PollPercentage>
        </PollOption>
      ))}
      <PollFooter>{totalVotes} votes • {poll.is_open ? "Ongoing" : "Closed"}</PollFooter>
    </PollContainer>
  );
};

export default PollComponent;

const PollContainer = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  border: 1px solid black;
  border-radius: 8px;
`;

const PollTitle = styled.h2`
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
`;

const PollQuestion = styled.h3`
  font-size: 1rem;
  margin-bottom: 0.5rem;
`;

const PollOption = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.5rem 0;
`;

const PollText = styled.span`
  flex: 1;
  font-size: 0.9rem;
`;

const PollBar = styled.div`
  flex: 3;
  background: #3a3b3c;
  height: 8px;
  border-radius: 4px;
  overflow: hidden;
`;

const PollProgress = styled.div`
  height: 100%;
  background: #0a66c2;
  border-radius: 4px;
`;

const PollPercentage = styled.span`
  flex: 0.5;
  font-size: 0.9rem;
  text-align: right;
`;

const PollFooter = styled.div`
  margin-top: 0.5rem;
  font-size: 0.8rem;
  color: #b0b3b8;
`;
