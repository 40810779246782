import React, { useEffect, useState } from 'react'
import { OnboardingButton } from '../../../components/onboarding/OnboardingButton'
import "../Css/custom-new.css"
import { fetchAPI } from '../../../utils/fetch'
import { config } from '../../../utils/config'

const Step0 = ({ setProgress, setStep, stepInfo, setStepInfo }) => {

  useEffect(() => {
    setProgress(0);
  }, [])

  const [error, setError] = useState(null);
  const handleInitializeData = async () => {
    setError(null);
    setStep(1);
    try {
      setStepInfo({ step: 0, message: "Initializing your data" })
      const response = await fetchAPI.post(
        `${config.urlPlatform}/api/v2/onboarding/add`,
        { withCredentials: true },
        {
          type: "INITIALIZE_DATA",
          step: 0
        }
      );

      if (!response?.data?.success) {
        setStep(0);
      }
      setError(response?.data?.message);
    }
    catch (error) {
      // setError(error?.response?.data?.message);
    } finally {
      setStepInfo({ step: -1, message: null })
    }
  }

  return (
    <div className='step0-onboarding-body'>
      <div className='step0-onboarding-heading'>
        Let’s Make AI Sound Like You!
      </div>
      <div className='step0-onboarding-subheading'>
        Your LinkedIn comments should reflect your unique voice. Answer a few quick questions to customize how Commenter AI writes for you!
      </div>
      {stepInfo.step === 1 && stepInfo.message && <div className='info-message-onboarding' >{stepInfo.message}</div>}
      {error && <div className='error-message-onboarding' >{error}</div>}
      <OnboardingButton onClick={handleInitializeData} style={{ maxWidth: "260px" }} title="Start Customization" />
    </div>
  )
}

export default Step0
