import React, { useEffect, useState } from 'react'
import { config, urlPaths } from '../../../utils/config'
import { fetchAPI } from '../../../utils/fetch'
import { useNavigate } from 'react-router-dom';

const PostHead = ({ selectedList, setSelectedList, selectedSort, setSelectedSort, selectedDate, setSelectedDate, setcurrentCursor, selectedPeopleList, setSelectedPeopleList }) => {

  const userDetails = JSON.parse(localStorage.getItem("userData"));
  const [userDetail, setUserDetail] = useState(userDetails);

  const [lists, setLists] = useState([{
    "id": "unipile_all_list_filter",
    "list_name": "All Keyword Lists",
    "keywords": []
  }]);

  const [keywordloading, setkeywordLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setkeywordLoading(true);
      try {
        const response = await fetchAPI.get(
          `${config.urlPlatform}/api/v2/unipile/linkedin/keywords/getAll`,
          { withCredentials: true }
        );

        if (response?.data?.success) {
          setLists((prevLists) => [
            prevLists[0],
            ...response?.data?.data,
          ]);
        }
      } catch (error) {
        console.log("Error fetching filters", error);
      } finally {
        setkeywordLoading(false);
      }
    };

    fetchData();
  }, []);

  const [people, setPeople] = useState([{
    "id": "",
    "list_name": "All People Lists",
  }]);

  const [peopleloading, setpeopleLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setpeopleLoading(true);
      try {
        const response = await fetchAPI.get(
          `${config.urlPlatform}/api/v2/unipile/linkedin/people/getAll`,
          { withCredentials: true }
        );

        if (response?.data?.success) {
          setPeople((prevLists) => [
            prevLists[0],
            ...response?.data?.data,
          ]);
        }
      } catch (error) {
        console.log("Error fetching data", error);
      } finally {
        setpeopleLoading(false);
      }
    };

    fetchData();
  }, []);


  const sortOptions = [{ name: "Relevance", value: "relevance" }, { name: "Date", value: "date" }];
  const dateFilters = [{ name: "Date Posted", value: "" }, { name: "Past Day", value: "past_day" }, { name: "Past Week", value: "past_week" }, { name: "Past Month", value: "past_month" }];

  const navigate = useNavigate();
  return (
    <div style={{ fontFamily: '"Exo 2", serif' }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "13rem", height: "3rem" }}>
        <h2>Custom Lists</h2>
        <div onClick={() => navigate(urlPaths.listposts)} style={{ cursor: "pointer", border: "1px solid gray", padding: "8px 5px", borderRadius: "5px", fontSize: "12px" }}>Add/Edit Lists</div>
      </div>
      <div style={{ fontSize: "13px", color: "gray" }}>Create and monitor lists of chosen individuals' posts.</div>

      {/* Filters */}
      <div style={{ display: "flex", alignItems: "center", margin: "1rem 0 0 0", gap: "1rem" }}>
        {/* List Filter */}
        <select
          value={selectedList}
          onChange={(e) => { setSelectedPeopleList(""); setSelectedList(e.target.value); setcurrentCursor(null) }}
          style={dropdownStyle}
          disabled={keywordloading}
        >
          {keywordloading && <option>Loading...</option>}

          {!keywordloading &&
            lists.map((list, index) => (
              <option key={list.id} value={list.keywords}>
                {list.list_name}
              </option>
            ))}
        </select>

        {/* People list Filter only visible to influencers plan*/}
        {
          (userDetails?.sevenDayTrial ||
            ((userDetails?.plan === "proMonth" || userDetails?.plan === "proMonth_yearly") && userDetails?.paidStatus === true)) &&
          <select
            value={selectedPeopleList}
            onChange={(e) => { setSelectedList(""); setSelectedPeopleList(e.target.value); setcurrentCursor(null) }}
            style={dropdownStyle}
            disabled={peopleloading}
          >
            {peopleloading && <option>Loading...</option>}

            {!peopleloading &&
              people.map((list, index) => (
                <option key={list.id} value={list.id}>
                  {list.list_name}
                </option>
              ))}
          </select>
        }


        {/* Sort Filter */}
        <select
          value={selectedSort}
          onChange={(e) => { setSelectedSort(e.target.value); setcurrentCursor(null) }}
          style={dropdownStyle}
        >
          {sortOptions.map((option, index) => (
            <option key={index} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>

        {/* Date Filter */}
        <select
          value={selectedDate}
          onChange={(e) => { setSelectedDate(e.target.value); setcurrentCursor(null) }}
          style={dropdownStyle}
        >
          {dateFilters.map((date, index) => (
            <option key={index} value={date.value}>
              {date.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

// Dropdown styling
const dropdownStyle = {
  width: "33%",
  padding: "8px",
  borderRadius: "5px",
  border: "1px solid gray",
  backgroundColor: "white",
  fontSize: "12px",
  cursor: "pointer"
};

export default PostHead
