export const size = {
    minWidthTablet: "768px",
    maxXlWidthTablet: "992px",
    maxWidthTablet: "1024px",
    minWidthDesktop: "1400px",
    headerWidth: "1299px",
    mobileWidth: "480px",
    tabletWidth: "1200px"

}

export const content = {
    PublicPages: {
        Aside: {
            Title: "When you start your 5-day free trial today, you’ll get:",
            list: [
                "3000 comments/per month (80 comments/5-day trial)",
                "Different types of comments for a perfect fit for your brand.",
                "Incorporate your ideas in your comments and maintain your own voice and tone.",
                "Priority support - you can count on us if you ever feel lost.",
                "Commenter community where you can connect with like-minded professionals and get new ideas.",
                "Commenter award opportunity - your hard work deserves recognition and rewards.",
                "Free Updates - always have access to the latest and greatest features (more coming soon)",
                "Bonus 1: Comment generation for Facebook so you can maximize your reach and interact with more potential clients.",
                "Bonus 2: Comment generation for Twitter - expand your presence on social media and grow even more your brand.",
                "Bonus 3: Sealed price - as an exclusive beta tester you won't be charged even a penny more in the future"
            ],
            imgList: [
                "https://images.ctfassets.net/eay8zh73tbvn/4OP198iBzv54NHieqdgmHG/3a8c3ab9c46d1e058fb487cc866e1ae7/Vector.png",
                "https://images.ctfassets.net/eay8zh73tbvn/45ShD6ckjClEtStUKY09Ng/c9ee3c357b678a329a5b30f55fa044b9/diamond_1.png",
                "https://images.ctfassets.net/eay8zh73tbvn/29RT8a5py0OieuHY4VRcoq/6e55b1b0c61dc8016e55aaf94388976c/Group_40140.png",
                "https://images.ctfassets.net/eay8zh73tbvn/4LKoZY7uODIj5I14xZSRpx/1d5fbc819fce7d695183cb03976c06ed/layer1.png",
                "https://images.ctfassets.net/eay8zh73tbvn/7EpuNMvdHQeINJw5lwMkza/3b428714714fef350f8218d32371b410/Mask_group_4_.png",
                "https://images.ctfassets.net/eay8zh73tbvn/4LKoZY7uODIj5I14xZSRpx/1d5fbc819fce7d695183cb03976c06ed/layer1.png",
                "https://images.ctfassets.net/eay8zh73tbvn/5vKcOaL3OG1cIkWsy1GVlV/c76025235223c2252f2d95334cc42529/Mask_group_5_.png",
                "https://images.ctfassets.net/eay8zh73tbvn/fYDQGfFnWtMwZTee1ZSgy/087aa12ebb8a81ba7ed4fc8de193b117/Vector_1_.png",
                "https://images.ctfassets.net/eay8zh73tbvn/6foZSVLVjBIrSsiny5jdSN/02b6359f3471b988949fb4e90dedb035/mdi_comments-outline.png",
                "https://images.ctfassets.net/eay8zh73tbvn/Eps8yZ6oAY9eOn3XuB0Jx/9149ac51a011c85b5a79bf4ffc527b5a/Mask_group_6_.png",

            ]
        },
        Login: {
            Title: "Hello Commenter",
            Content: "Lorem ipsum dolor sit amet consectetur. Sed convallis ultrices mi ",
            SignUp: `Don’t have an account yet?`
        },
        RequestNewPassword: {
            Title: "Password Reset"
        },
        NewPassword: {
            Title: "New Password",
            content: "Please, choose your new password."
        },
        Register: {
            Title1: "Start your journey",
            Title2: "Start your FREE 5-Day Trial Now",
            Content: "Create Account:",
            step1: "Step 1 of 2",
            step2: "Step 2 of 2",
            payment1: "Why? We ask for a payment method so that your subscription and business can continue without interruption after your trial ends",
            payment2: "5 days free from today, then just $46,80 /month...",
            signIn: "Already have an account? "
        },
        SuccessRegister: {
            Title: "You have registered successfully!",
            Content: "Please go to your email and verify your account. There is a mail that should wait for you there!"
        },
        Welcome: {
            Robot: {
                Headline: "Click me, please",
                Title1: "Thank you for clicking me!",
                Text: `My name is Comy. Nice to e-meet you and welcome on board, Commenter. I am here to help you, guide you, and provide you with tips. Oh, I almost forgot. Watch out for icons over my head. They mean new updates that you can enjoy. I hope that you will have fun around.`
            }
        }
    }
}




export const faq = [
    {
        question: `How do I supercharge my experience with the Chrome extension?`,
        answer: `By using the Chrome extension you can save precious time. There are a few steps that you need to go through in order to use it.

    Step 1: Add it to your Chrome or Brave browser as an extension by going here - https://chrome.google.com/webstore/detail/social-post-mate/ffebfjkgjgbpmnoogjjdgfkmiobngdnf
    Step 2: Pin it on your browser (if you don’t know how to do it, go here - https://help.sendspark.com/en/articles/4292727-how-to-pin-a-chrome-extension#:~:text=This%20makes%20it%20really%20easy,to%20pin%20to%20your%20browser.)
    Step 3: Click on the extension
    Step 4: Turn it on
    
    That’s all. Now you just need to go to LinkedIn, Facebook, or Twitter, click on the icon that you will see in the upper right corner and it will automatically copy the post's text. Then come back on the platform and click on the “Paste” button. 
    
    After that, you just need to choose which platform this post is from and click on the “Write me a comment” button. The other two fields will add more personalization to your comment, but they are not mandatory.
    `
    },
    {
        question: "How do I paste a post into the platform?",
        answer: "If you have copied the post that you want a comment for, then just press the “Paste” button and it will appear in the field below it."
    },
    {
        question: "How do I select the right social media platform for my comment?",
        answer: "After you paste the comment in the field below it you will see another field with 3 options - LinkedIn, Facebook, and Twitter (more options coming soon). You just click on the correct one and you are good to go."
    },
    {
        question: "How do I edit or request another comment for the same post?",
        answer: "If you need to edit a comment you've generated, simply click on the field containing the text and make your changes. If you'd like our AI to generate a new comment for you, click on the “Rewrite” button."
    },
    {
        question: "How do I request another feature?",
        answer: "If you have a suggestion for a new feature, click on the “Request a feature” button and fill out the form. Our technical team and CEO will review your suggestion and work to implement it as quickly as possible. We're always looking for ways to improve the platform for our users."
    },
    {
        question: "How many comments per month am I allowed to generate?",
        answer: "During the free trial, you can generate up to 80 comments. As a paid user, you can generate up to 3000 comments per month. If you need more than that, please contact us or click the button on the “Get comments” page to speak with a member of our team."
    },
    {
        question: "How do I copy the generated comment so I can use it on my social media platform?",
        answer: "You just need to click on the “Copy” button and it will do the work for you."
    },
    {
        question: "Can I cancel my subscription?",
        answer: "Absolutely. There are no long-term contracts, so you can cancel your subscription at any time. You can do so from your account or by contacting our support team. We want our users to be happy with the platform and the service they receive, so please let us know if there's anything we can do to improve your experience."
    }
];

export const academyTips = [
    "Keep your comments concise and to the point.",
    "Use correct spelling and grammar.",
    "Be respectful and professional at all times.",
    "Avoid using emojis excessively.",
    "Use a friendly and approachable tone.",
    "Always stay on topic and relevant to the original post.",
    "Make sure your comments add value to the conversation.",
    "Use humor and wit sparingly, if at all.",
    "Avoid writing overly negative or critical comments.",
    "Use proper punctuation and capitalization.",
    "Use personal examples or stories to illustrate your point.",
    "Avoid using all caps or excessive exclamation points.",
    "Use quotes from the original post or article to support your argument.",
    "Use the person's name when addressing them in a comment.",
    "Use an active voice rather than a passive voice.",
    "Ask thoughtful questions to encourage further discussion.",
    "Use metaphors or analogies to help explain your point.",
    "Avoid using jargon or technical terms that may not be understood by everyone.",
    "Use humor to break the ice, but don't rely on it too heavily.",
    "Be open-minded and willing to consider other perspectives.",
    "Use emojis sparingly to help convey tone or emotion.",
    "Add relevant hashtags to your comment to help increase visibility.",
    "Use a positive and encouraging tone to inspire others.",
    "Make sure your comment is relevant to the post and not just self-promotion.",
    "Use links to credible sources to support your argument.",
    "Use personal experiences or stories to add depth to your comment.",
    "Acknowledge and respond to other comments to encourage further discussion.",
    "Use humor to lighten the tone of a potentially contentious discussion.",
    "Avoid using overly complicated language that may intimidate or confuse others.",
    "Use data or statistics to support your argument.",
    "Be genuine and authentic in your comments.",
    "Use specific examples to help illustrate your point.",
    "Avoid using slang or informal language that may come across as unprofessional.",
    "Use analogies or metaphors to help simplify complex ideas.",
    "Acknowledge and respond to comments that challenge your perspective.",
    "Use humor to add a personal touch to your comment.",
    "Avoid using overly negative language that may be off-putting to others.",
    "Use your own unique voice and personality to stand out from the crowd.",
    "Acknowledge and respond to comments from a variety of perspectives.",
    "Use humor to add levity to a serious discussion.",
    "Avoid using overly formal or stuffy language that may be off-putting to others.",
    "Use anecdotes or stories to add a personal touch to your comment.",
    "Use a warm and friendly tone to make others feel welcome.",
    "Avoid using overly critical or harsh language that may discourage others from participating.",
    "Use metaphors or analogies to help make complex ideas more accessible.",
    "Use humor to create a sense of camaraderie with other commenters.",
    "Avoid using overly technical language that may be difficult for others to understand.",
    "Use real-world examples to help illustrate your point.",
    "Use humor to break the ice and create a more relaxed atmosphere.",
    "Avoid using overly emotional language that may come across as unprofessional.",
    "Use metaphors or analogies to help create visual imagery in your comment.",
    "Use personal insights or experiences to add a unique perspective to the conversation.",
    "Use a positive and uplifting tone to inspire others to engage.",
    "Avoid using overly general or vague language that may lack clarity or focus.",
    "Use metaphors or analogies to help connect complex ideas with relatable concepts.",
    "Use humor to add a human touch to the conversation and make others feel at ease.",
    "Avoid using overly biased language that may come across as unconstructive or unproductive.",
    "Use rhetorical questions to encourage others to engage with your comment.",
    "Use descriptive language to create a vivid picture of the ideas you're discussing.",
    "Use humor to make light of a situation and alleviate tension.",
    "Avoid using overly academic or technical language that may be difficult for others to understand.",
    "Use real-world examples or case studies to support your point of view.",
    "Use humor to help build rapport with other commenters.",
    "Avoid using overly sarcastic or dismissive language that may be perceived as rude or condescending.",
    "Use metaphors or analogies to help simplify complex ideas and make them more accessible to a wider audience.",
    "Use humor to add a lighthearted touch to the conversation and make others feel comfortable participating.",
    "Avoid using overly emotional language that may cloud your judgement or create a negative atmosphere.",
    "Use personal anecdotes or stories to connect with other commenters on a more personal level.",
    "Avoid using overly formal language that may create a barrier between you and other commenters.",
    "Use metaphors or analogies to help connect disparate ideas and create a cohesive narrative.",
    "Avoid using overly simplistic language that may fail to capture the nuance of the ideas you're discussing.",
    "Use personal observations or insights to help frame the conversation in a new light.",
    "Use humor to create a sense of community and build relationships with other commenters.",
    "Avoid using overly negative language that may detract from the overall tone of the conversation.",
    "Use personal experiences or stories to create a more relatable and engaging comment.",
    "Avoid using overly passive language that may come across as disinterested or uninvolved.",
    "Use metaphors or analogies to help create a sense of cohesion and continuity between different aspects of the conversation.",
    "Use humor to create a sense of levity and playfulness in the conversation.",
    "Avoid using overly aggressive language that may be perceived as confrontational or hostile.",
    "Use personal insights or observations to add a unique and interesting perspective to the conversation.",
    "Avoid using overly ambiguous or unclear language that may be difficult for others to understand or follow.",
    "Use metaphors or analogies to help create a more engaging and interesting comment.",
    "Avoid using overly formal or stiff language that may be off-putting or intimidating to others.",
    "Use personal examples or stories to add depth and nuance to the conversation.",
    "Avoid using overly technical jargon that may be difficult for others to understand or follow.",
    "Avoid using overly generalized statements that may lack specificity or relevance to the conversation."
]

export function extractWord(string, split = "</svg>", location = 1) {
    const word = string.split(split);

    return word[location];
}

export function convertStringToParagraphs(string = '', split = '\n') {
    const array = string.split(split);
    if (array.length === 0) {
        return [];
    }

    return array.map((sentance, index) => {
        if (sentance?.includes('<a')) {
            return <span key={`sentence-${index}`}>You can learn more about it by going here - <a href="https://chrome.google.com/webstore/detail/social-post-mate/ffebfjkgjgbpmnoogjjdgfkmiobngdnf" target="_blank" rel="noreferrer"> &gt;&gt; CLICK HERE &lt;&lt;</a><br /></span>
        } else {
            return <span key={`sentence-${index}`}>{sentance}<br /><br /></span>
        }
    })
}

export function convertStringToParagraphsSmallSpaces(string = '', split = '\n') {
    const array = string.split(split);
    const result = [];
    for (const sentance of array) {
        if (sentance?.includes('<a')) {
            result.push(<span>You can learn more about it by going here - <a href="https://chrome.google.com/webstore/detail/social-post-mate/ffebfjkgjgbpmnoogjjdgfkmiobngdnf" target="_blank" rel="noreferrer"> &gt;&gt; CLICK HERE &lt;&lt;</a><br /></span>)
        } else {
            result.push(<span>{sentance}<br /></span>)
        }
    }

    return result;
}

export function convertArrayToArrayOfObject(allCriterias = [], workingArray = []) {
    const readyArray = [];
    const heading = [];
    const images = [];
    const text = [];

    for (const property of workingArray) {
        if (allCriterias[0] && property.nodeType === allCriterias[0]) {
            heading.push(property.content[0].value)
        }

        if (allCriterias[1] && property.nodeType === allCriterias[1]) {
            images.push("https:" + property.data.target.fields.file.url)
        }

        if (allCriterias[2] && property.nodeType === allCriterias[2]) {
            text.push(property.content[0].value)
        }
    }
    for (let index = 0; index < images.length; index++) {
        const object = {
            title: heading[index],
            image: images[index]
        }
        if (text.length) {
            object.text = text[index]
        }
        readyArray.push(object)
    }

    return readyArray;
}
export function convertArrayToArrayOfObjectTwo(allCriterias = [], workingArray = []) {
    const readyArray = [];
    const heading = [];
    const images = [];
    const text = [];
    let index = 0;
    const halfOfTheArray = workingArray.length / 2;

    for (const property of workingArray) {
        index++;
        if (allCriterias[0] && property.nodeType === allCriterias[0]) {
            heading.push(property.content[0].value)
        }

        if (allCriterias[1] && property.nodeType === allCriterias[1] && index > halfOfTheArray) {
            images.push("https:" + property.data.target.fields.file.url)
        }

        if (allCriterias[2] && property.nodeType === allCriterias[2]) {
            text.push(property.content[0].value)
        }
    }
    for (let index = 0; index < heading.length; index++) {
        const object = {
            title: heading[index],
            image: images[index]
        }
        if (text.length) {
            object.text = text[index]
        }
        readyArray.push(object)
    }

    return readyArray;
}

export function convertContentToArray(nodeTypes = [], keys = [], contentArray = []) {
    let result = [];
    let data = {};
    if (nodeTypes.length === 0 || contentArray.length === 0) {
        return result;
    }

    for (const item of contentArray) {
        const index = nodeTypes.findIndex(e => e === item.nodeType);
        if (index !== undefined) {
            if (item.nodeType === 'embedded-asset-block') {
                data = { ...data, [keys[index]]: `https:${item.data?.target?.fields?.file?.url}` };
            } else {
                data = { ...data, [keys[index]]: item.content[0].value };
            }
        }

        if (item.nodeType === nodeTypes[nodeTypes.length - 1]) {
            result = [...result, data];
        }
    }
    return result;
}

export const handleClickScroll = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' });
    }
};

export function timeAgo(dateString) {
    const now = new Date();
    const date = new Date(dateString);
    const diff = now - date; // Difference in milliseconds

    const minutes = Math.floor(diff / 1000 / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30); // Approximation
    const years = Math.floor(days / 365); // Approximation

    if (years > 0) {
        return `${years} year${years === 1 ? '' : 's'} ago`;
    } else if (months > 0) {
        return `${months} month${months === 1 ? '' : 's'} ago`;
    } else if (weeks > 0) {
        return `${weeks} week${weeks === 1 ? '' : 's'} ago`;
    } else if (days > 0) {
        return `${days} day${days === 1 ? '' : 's'} ago`;
    } else if (hours > 0) {
        return `${hours} hour${hours === 1 ? '' : 's'} ago`;
    } else if (minutes > 0) {
        return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
    }

    return "Just now"; // If the time is too small
}

export function formatPostDate(dateString) {
    const date = new Date(dateString);

    const options = {
        month: 'short', // Abbreviated month (e.g., Jan, Feb)
        day: 'numeric', // Day of the month
        hour: 'numeric', // Hour in 12-hour format
        minute: 'numeric', // Minute
        hour12: true // 12-hour format (AM/PM)
    };

    // Format the date
    const formattedDate = date.toLocaleString('en-US', options);

    return formattedDate;
}

