import React, { useState, useRef, useEffect } from 'react';
import { config } from '../../../utils/config';
import { fetchAPI } from '../../../utils/fetch';

// Constants
const MAX_COMMENT_LENGTH = 1250;
const MAX_TEXTAREA_HEIGHT = 300;   // maximum auto-resize height
const MIN_TEXTAREA_HEIGHT = 35;    // minimum height for the textarea

// Shared text styles for both textarea & highlighter
const sharedTextStyle = {
  margin: 0,
  padding: '8px',
  boxSizing: 'border-box',
  fontFamily: 'Arial, sans-serif',
  fontSize: '14px',
  lineHeight: '1.4',
  width: '100%',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
};

const MentionComment = ({ comment, setComment, mentions, setMentions }) => {
  
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const [userSearchLoading, setUserSearchLoading] = useState(false);
  const [activeAtIndex, setActiveAtIndex] = useState(null);
  const [call, setCall] = useState(false);

  const textareaRef = useRef(null);
  const highlighterRef = useRef(null);
  const debounceTimeout = useRef(null);

  // API call for suggestions
  const fetchUsers = async (query) => {
    try {
      setUserSearchLoading(true);
      let url = `${config.urlPlatform}/api/v2/unipile/linkedin/search`;
      const body = { category: 'people' };
      if (query.trim() !== '') {
        body.keywords = query;
      }
      const response = await fetchAPI.post(url, { withCredentials: true }, body);
      if (response?.data?.success) {
        if(Array.isArray(response?.data?.data?.items) && response?.data?.data?.items.length > 0) {
          return response?.data?.data?.items;
        }
        else{
          setCall(false);
          return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      console.log('Error fetching data');
      return [];
    } finally {
      setUserSearchLoading(false);
    }
  };

  // Change handler using caret position.
  // Suggestions will trigger whenever a valid '@' (preceded by whitespace or at start)
  // is found, regardless of whether the query contains spaces.
  const handleCommentChange = async (e) => {
    const value = e.target.value;
    if (value.length > MAX_COMMENT_LENGTH) return;
    setComment(value);

    if (!value.trim()) {
      setMentions([]);
    }

    const cursorPos = e.target.selectionStart;
    const textBeforeCursor = value.slice(0, cursorPos);
    const atIndex = textBeforeCursor.lastIndexOf('@');
    if (atIndex !== -1 && (atIndex === 0 || /\s/.test(textBeforeCursor[atIndex - 1]))) {
      // Store active trigger index if new.
      if (activeAtIndex === null || activeAtIndex !== atIndex) {
        setActiveAtIndex(atIndex);
      }
      // Extract full query (can include spaces)
      const query = textBeforeCursor.slice(atIndex + 1);
      // Only show suggestions if query is non-empty.
      if (query === '') {
        setShowSuggestions(false);
        return;
      }
      setShowSuggestions(true);
      setSelectedSuggestionIndex(0);
      // Debounce API call.
      if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
      debounceTimeout.current = setTimeout(async () => {
        if (call) {
          const users = await fetchUsers(query);
          if (users.length === 0) {
            setShowSuggestions(false);
          } else {
            setSuggestions(users);
          }
        }
      }, 300);
    } else {
      setShowSuggestions(false);
      setActiveAtIndex(null);
    }
  };

  // Suggestion click handler: inserts the full display name as a mention and clears active trigger.
  const handleSuggestionClick = (user) => {
    const textarea = textareaRef.current;
    const cursorPos = textarea.selectionStart;
    const textBeforeCursor = comment.slice(0, cursorPos);
    const atIndex = textBeforeCursor.lastIndexOf('@');
    if (atIndex !== -1) {
      const beforeAt = comment.slice(0, atIndex);
      const afterQuery = comment.slice(cursorPos);
      const displayName = user.name || user.username || 'User';
      // Insert mention token with a trailing space.
      const mentionToken = `@${displayName} `;
      const newValue = beforeAt + mentionToken + afterQuery;
      setComment(newValue);
      setShowSuggestions(false);
      setSelectedSuggestionIndex(-1);
      setActiveAtIndex(null);
      setCall(false);
      // Capture mention metadata (store token without trailing space)
      setMentions((prev) => [...prev, { ...user, token: `@${displayName}` }]);
    }
  };

  // Handle arrow keys, Enter selection, and backspace deletion of mention tokens.
  const handleKeyDown = (e) => {
    if (e.key === "@") {
      setCall(true);
    }

    if (showSuggestions && suggestions.length > 0) {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setSelectedSuggestionIndex((prev) => (prev + 1) % suggestions.length);
        return;
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setSelectedSuggestionIndex((prev) =>
          (prev - 1 + suggestions.length) % suggestions.length
        );
        return;
      } else if (e.key === 'Enter' && selectedSuggestionIndex !== -1) {
        e.preventDefault();
        handleSuggestionClick(suggestions[selectedSuggestionIndex]);
        return;
      }
    }

    if (e.key === 'Backspace') {
      const textarea = textareaRef.current;
      const cursorPos = textarea.selectionStart;
      const textBeforeCursor = comment.slice(0, cursorPos);
      // Match a mention token: starts with '@' and goes until the current caret.
      const mentionRegex = /(@[^\s].*?)(\s)?$/;
      const match = textBeforeCursor.match(mentionRegex);
      if (match) {
        e.preventDefault();
        const token = match[1];
        const tokenStart = cursorPos - match[0].length;
        const newValue = comment.slice(0, tokenStart) + comment.slice(cursorPos);
        setComment(newValue);
        setMentions((prev) => prev.filter((m) => m.token !== token));
        setActiveAtIndex(null);
        setTimeout(() => {
          textarea.setSelectionRange(tokenStart, tokenStart);
        }, 0);
      }
    }
  };

  // Sync scroll between textarea and highlighter.
  const handleScroll = (e) => {
    if (highlighterRef.current) {
      highlighterRef.current.scrollTop = e.target.scrollTop;
      highlighterRef.current.scrollLeft = e.target.scrollLeft;
    }
  };

  // Auto-resize textarea and highlighter based on content.
  useEffect(() => {
    if (textareaRef.current && highlighterRef.current) {
      textareaRef.current.style.height = `${MIN_TEXTAREA_HEIGHT}px`;
      highlighterRef.current.style.height = `${MIN_TEXTAREA_HEIGHT}px`;
      const newHeight = Math.min(textareaRef.current.scrollHeight, MAX_TEXTAREA_HEIGHT);
      textareaRef.current.style.height = `${newHeight}px`;
      highlighterRef.current.style.height = `${newHeight}px`;
    }
  }, [comment]);

  // Generate highlighted text with mention tokens wrapped in blue spans.
  const getHighlightedText = (text) => {
    let highlighted = text;
    mentions.forEach((m) => {
      const escapedToken = m.token.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(`(${escapedToken})(\\s?)`, 'g');
      highlighted = highlighted.replace(
        regex,
        (_, p1, p2) => `<span style="color: blue;">${p1}</span>${p2}`
      );
    });
    return highlighted.replace(/\n/g, '<br>');
  };

  // Extraction function:
  // Build a union regex from the mention tokens and replace each occurrence with an incrementing placeholder.
  

  return (
    <div style={{ margin: 'auto' }}>
      <div style={{ position: 'relative' }}>
        {/* Highlighter */}
        <div
          ref={highlighterRef}
          style={{
            ...sharedTextStyle,
            position: 'absolute',
            top: 0,
            left: 0,
            pointerEvents: 'none',
            color: 'black',
            overflow: 'hidden',
          }}
          dangerouslySetInnerHTML={{ __html: getHighlightedText(comment) }}
        />
        {/* Textarea */}
        <textarea
          ref={textareaRef}
          value={comment}
          onChange={handleCommentChange}
          onKeyDown={handleKeyDown}
          onScroll={handleScroll}
          placeholder="Add a comment"
          style={{
            ...sharedTextStyle,
            position: 'relative',
            background: 'transparent',
            color: 'transparent',
            caretColor: 'black',
            resize: 'none',
            overflow: 'auto',
          }}
        />
        {/* Suggestion Dropdown */}
        {showSuggestions && call && (
          <ul
            style={{
              position: 'absolute',
              background: '#fff',
              border: '1px solid #ccc',
              listStyle: 'none',
              padding: '0',
              margin: '0',
              width: '100%',
              zIndex: 1000,
              maxHeight: '185px',
              overflowY: 'auto',
            }}
          >
            {userSearchLoading ? (
              <li style={{ padding: '5px' }}>Loading...</li>
            ) : (
              suggestions.map((user, index) => (
                <li
                  key={user.id}
                  onClick={() => handleSuggestionClick(user)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '5px',
                    cursor: 'pointer',
                    background: index === selectedSuggestionIndex ? '#eee' : 'transparent',
                  }}
                >
                  <img
                    src={
                      user?.profile_picture_url ??
                      'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
                    }
                    alt={user?.name ?? 'User'}
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      marginRight: '10px',
                    }}
                  />
                  {user?.name ?? 'User'}
                </li>
              ))
            )}
          </ul>
        )}
      </div>
      {/* <button onClick={extractData} style={{ margin: '10px' }}>
        Extract Data
      </button> */}
    </div>
  );
};

export default MentionComment;
