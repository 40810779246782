import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components';
import PostHead from './PostHeader';
import PostS from './Post';
import { fetchAPI } from '../../../utils/fetch';
import { config } from '../../../utils/config';
import LikeFilledBlue from "../../../assets/likeFilledBlue.svg";
import Celebrate from "../../../assets/celebrate.svg";
import Funny from "../../../assets/funny.svg";
import Insightful from "../../../assets/insightful.svg";
import Love from "../../../assets/love.svg";
import Support from "../../../assets/support.svg";
import CommentPopover from './Ai/Popup';

const PostsMainPage = () => {

  const [posts, setPosts] = useState([]);
  const [postLoading, setPostLoading] = useState(false);
  const [currentCursor, setcurrentCursor] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const storage = localStorage.getItem("userData");
  const storageJson = JSON.parse(storage);

  const fetchUserProfile = useCallback(async () => {
    if (postLoading) return;
    if (!currentCursor) {
      setPosts([])
    }
    try {
      setPostLoading(true);

      let url = `${config.urlPlatform}/api/v2/unipile/linkedin/search`;
      url += `?limit=${20}`;
      if (currentCursor) {
        url += `&cursor=${currentCursor}`;
      }

      const body = {
        "category": "posts"
      }
      if (selectedList !== "") {
        body.keywords = selectedList;
      }
      if (selectedSort !== "") {
        body.sort_by = selectedSort;
      }
      if (selectedDate !== "") {
        body.date_posted = selectedDate;
      }
      if (selectedPeopleList !== "") {
        body.people_list_id = selectedPeopleList;
      }
      const response = await fetchAPI.post(
        url,
        { withCredentials: true },
        body
      );

      if (response?.data?.success) {
        if (currentCursor) {
          setPosts(prevPosts => [...prevPosts, ...response?.data?.data?.items]);
        }
        else {
          setPosts(response?.data?.data?.items);
        }
        setcurrentCursor(response?.data?.data?.cursor);
        setTotalCount(response?.data?.data?.paging?.total_count ?? 0);
      }
    } catch (error) {
      console.log("Error getting posts");
    } finally {
      setPostLoading(false);
    }
  }, [currentCursor, postLoading]);

  const [selectedList, setSelectedList] = useState("");
  const [selectedPeopleList, setSelectedPeopleList] = useState("");
  const [selectedSort, setSelectedSort] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    fetchUserProfile();
  }, [selectedList, selectedSort, selectedDate, selectedPeopleList])

  const loadMorePosts = useCallback((entries, observer) => {
    if (entries[0].isIntersecting && !postLoading && currentCursor && posts.length < totalCount) {
      fetchUserProfile();
    }
  }, [postLoading, currentCursor, posts.length, totalCount]);

  useEffect(() => {
    const observer = new IntersectionObserver(loadMorePosts, {
      rootMargin: '200px', // Trigger when 200px before the bottom
      threshold: 1.0, // When the entire element is in view
    });

    const target = document.querySelector('#infinite-scroll-target');
    if (target) observer.observe(target);

    return () => {
      if (target) observer.unobserve(target);
    };
  }, [loadMorePosts]);

  const reactions = [
    { name: "like", image: LikeFilledBlue },
    { name: "celebrate", image: Celebrate },
    { name: "support", image: Support },
    { name: "love", image: Love },
    { name: "insightful", image: Insightful },
    { name: "funny", image: Funny },
  ];
  const [openCommentPostId, setOpenCommentPostId] = useState(null);
  const [commentsLeft, setCommentsLeft] = useState(null);
  const [customPrompt, setCustomPrompt] = useState(false);
  const [userData, setUserData] = useState(null);

  const getRemainingComments = async () => {
    try {
      const userData = await fetchAPI.get(`${config.urlPlatform}/api/v2/auth/user?email=${storageJson.email}`, {
        withCredentials: true
      });
      setCommentsLeft(userData?.data?.commentsLeft);
      setCustomPrompt(userData?.data?.useCustomPrompt);
      setUserData(userData?.data);
    }
    catch (e) {
      console.log("Failed to get user details");
    }
  }

  useEffect(() => {
    if (openCommentPostId) {
      getRemainingComments();
    }
  }, [openCommentPostId])

  const [pasteButton, setPasteButton] = useState(null);

  return (
    <Container>
      <PostContainer>
        <PostHead selectedList={selectedList} setSelectedList={setSelectedList} selectedSort={selectedSort} setSelectedSort={setSelectedSort} selectedDate={selectedDate} setSelectedDate={setSelectedDate} setcurrentCursor={setcurrentCursor} selectedPeopleList={selectedPeopleList} setSelectedPeopleList={setSelectedPeopleList} />
        <div style={{ marginTop: "2rem", paddingBottom: "2rem" }}>
          {posts.length === 0 && !postLoading ? (
            <div style={{ textAlign: "center", padding: "20px", fontSize: "18px", color: "#777" }}>
              No posts found
            </div>
          ) : (
            posts.map((post, index) => (
              <React.Fragment key={post.id}> {/* Use React.Fragment instead of <> for keys */}
                <PostS
                  data={post}
                  reactions={reactions}
                  isCommentPopUpOpen={openCommentPostId === post.id}
                  setIsCommentPopUpOpen={() => setOpenCommentPostId(post.id)}
                  setOpenCommentPostId={setOpenCommentPostId}
                  commentsLeft={commentsLeft}
                  setCommentsLeft={setCommentsLeft}
                  openCommentPostId={openCommentPostId}
                  getRemainingComments={getRemainingComments}
                  customPrompt={customPrompt}
                  userData={userData}
                  pasteButton={pasteButton === post.id}
                  setPasteButton={setPasteButton}
                />
                {postLoading && index === posts.length - 1 && (
                  <div style={{ height: "150px" }}>
                    <PageLoader />
                  </div>
                )}
              </React.Fragment>
            ))
          )}
          {postLoading && posts.length === 0 && <PageLoader />}
          <div id="infinite-scroll-target" />
        </div>
      </PostContainer>
    </Container>
  );
}

export const PageLoader = styled.span`
    display: block;
    margin-left:auto;
    margin-right:auto;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
    &::before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        inset: 0px;
        border-radius: 50%;
        border: 4px solid #0a66c2;
        animation: prixClipFix 2s linear infinite ;
    }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: calc(100dvh);
  margin: 0 auto;
`;

const PostContainer = styled.div`
  margin-top: 2rem;
  max-width: 590px;
`;

export default PostsMainPage