import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { config, urlPaths } from '../../../../utils/config';
import styled from 'styled-components';
import { fetchAPI } from '../../../../utils/fetch';
import ListView from './ListView';
import ListModalForPeople from './ListModalForPeople';

const PeopleList = () => {

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [keywordloading, setkeywordLoading] = useState(false);
  const [lists, setLists] = useState([]);

  const fetchData = async () => {
    setkeywordLoading(true);
    try {
      const response = await fetchAPI.get(
        `${config.urlPlatform}/api/v2/unipile/linkedin/people/getAll`,
        { withCredentials: true }
      );

      if (response?.data?.success) {
        setLists(response?.data?.data);
      }
    } catch (error) {
      console.log("Error fetching filters", error);
    } finally {
      setkeywordLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container style={{ display: "flex", justifyContent: "center", marginTop: "2rem", fontFamily: '"Exo 2", serif' }}>
      <div style={{ width: "70%" }}>
        <div>
          <h1 style={{ marginBottom: "5px" }}>People lists</h1>
          <div>Create your custom engagement list</div>
        </div>
        <div style={{ width: "90%", marginLeft: "3%", marginTop: "1rem" }}>
          <div onClick={() => navigate(urlPaths.posts)} style={{ display: "inline-flex", alignItems: "center", color: "#0a66c2", marginTop: "1rem", cursor: "pointer" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-left w-4 h-4"><path d="m12 19-7-7 7-7"></path><path d="M19 12H5"></path></svg>
            Back to lists
          </div>
          <div style={{ marginTop: "1rem" }}>
            <div onClick={() => setShowModal(!showModal)} style={{ cursor: "pointer", display: "inline-flex", alignItems: "center", padding: "0.4rem", border: "1px solid #0a66c2", color: "#0a66c2", marginRight: "1rem" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-circle-plus mr-2 h-4 w-4"><circle cx="12" cy="12" r="10"></circle><path d="M8 12h8"></path><path d="M12 8v8"></path></svg>
              &nbsp;Create People List
            </div>
            <div onClick={() => navigate(urlPaths.listposts)} style={{ cursor: "pointer", display: "inline-flex", alignItems: "center", padding: "0.4rem", border: "1px solid #0a66c2", backgroundColor: "#0a66c2", color: "white" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-circle-plus mr-2 h-4 w-4"><circle cx="12" cy="12" r="10"></circle><path d="M8 12h8"></path><path d="M12 8v8"></path></svg>&nbsp;Add Keyword
            </div>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <h3>All People List</h3>
          </div>
          <div style={{ "height": "calc(100dvh - 430px)", "overflowY": "auto" }}>
            {
              keywordloading ? (
                <PageLoader />
              ) : (
                lists.length > 0 ? (
                  lists.map(list => (
                    <ListView key={list.id} data={list} setLists={setLists} />
                  ))
                ) : (
                  <div>No lists available</div>
                )
              )
            }
          </div>
        </div>
      </div>
      {showModal && <ListModalForPeople setShowModal={setShowModal} action="Add" setLists={setLists} />}
    </Container>
  )
}

export const PageLoader = styled.span`
    display: block;
    margin-left:auto;
    margin-right:auto;
    margin-top: 2rem;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
    &::before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        inset: 0px;
        border-radius: 50%;
        border: 4px solid #0a66c2;
        animation: prixClipFix 2s linear infinite ;
    }
`;

const Container = styled.div`
  height: calc(100dvh - 180px);
`;

export default PeopleList
